console.info('"functions loadet"'); // .These run everytime before jquery doc ready to be finished

/**
 * Funktion zur Setup-Seite
 */
function setupInit() {
    'use strict';

    app.name = 'Retail Data Storage - Setup';

    chooseLng();

    console.info(app.name +'\n');
    console.info('API:', api);

    var currentId = $('.tab-pane.active').attr('id');
    var steps = [];
    var dnsMax = 4;
    var drawTime = false;
    var action = false;
    var errors = 0;
    let dateRangePosition = "";

    sessionStorage.clear();
    sessionStorage.setItem('step', 1);

    // Gather all tabs/steps into array
    $('.tab-content .tab-pane').each(function () {
        steps.push($(this).attr('id'));
    });

    // Whats happening on the tabs
    $('#start-tab')
        .unbind().click(function () {
        $('#start').show();
        $('#contact').hide();
        $('#network').hide();
        $('#proxy').hide();
        $('#download').hide();
        $('#summary').hide();
        $('#reboot').hide();
    })
        .on('show.bs.tab', function () {
            sessionStorage.setItem('step', 1);
            currentId = $(this).attr('id').split('-')[0];
            $('#stepBack').prop('disabled', false);
            console.log(currentId, "tab shown");
        })
        .on('hide.bs.tab', function () {

        })
        .on('hidden.bs.tab', function () {
            $('#stepBack').prop('disabled', false);
        });

    $('#contact-tab')
        .unbind().click(function () {
        setupEval.val.checkStart();
    })
        .on('show.bs.tab', function () {
            sessionStorage.setItem('step', 2);
            currentId = $(this).attr('id').split('-')[0];
            $('#stepBack').prop('disabled', false);
            console.log(currentId, "tab shown");
        })
        .on('hide.bs.tab', function () {

        })
        .on('hidden.bs.tab', function () {
        });

    $('#network-tab')
        .unbind().click(function () {
        setupEval.val.checkContact();
    })
        .on('show.bs.tab', function () {
            sessionStorage.setItem('step', 3);
            currentId = $(this).attr('id').split('-')[0];
            $('#stepBack').prop('disabled', false);
            console.log(currentId, "tab shown");
        })
        .on('hide.bs.tab', function () {

        })
        .on('hidden.bs.tab', function () {
        });

    $('#proxy-tab')
        .unbind().click(function () {
        setupEval.val.checkNetwork();
    })
        .on('show.bs.tab', function () {
            sessionStorage.setItem('step', 4);
            currentId = $(this).attr('id').split('-')[0];
            $('#stepBack').prop('disabled', false);
            console.log(currentId, "tab shown");
        })
        .on('hide.bs.tab', function () {

        })
        .on('hidden.bs.tab', function () {
        });

    $('#download-tab')
        .unbind().click(function () {
        setupEval.val.checkProxy();
        setupEval.data.get.downloadSliderPosition();
    })
        .on('show.bs.tab', function () {
            sessionStorage.setItem('step', 5);
            currentId = $(this).attr('id').split('-')[0];
            $('#stepBack').prop('disabled', false);
            console.log(currentId, "tab shown");
        })
        .on('hide.bs.tab', function () {

        })
        .on('hidden.bs.tab', function () {
        });

    $('#summary-tab')
        .unbind().click(function () {
        setupEval.val.checkDownload();
    })
        .on('show.bs.tab', function () {
            sessionStorage.setItem('step', 6);
            $('#rdsw_d1').html(i18next.t('rdsw_d1l'));
            currentId = $(this).attr('id').split('-')[0];
            $('#stepBack').prop('disabled', false);
            setupEval.data.set.summaryElements();

            console.log(currentId, "tab shown");
        })
        .on('hide.bs.tab', function () {

        })
        .on('hidden.bs.tab', function () {
        });

    $('#reboot-tab')
        .unbind().click(function () {
        $('#start').hide();
        $('#contact').hide();
        $('#network').hide();
        $('#proxy').hide();
        $('#download').hide();
        $('#summary').hide();
        $('#reboot').show();
        $('#wid6').attr('class', 'fas fa-info-circle fa-2x d-block wCheck');
    })
        .on('show.bs.tab', function () {
            sessionStorage.setItem('step', 7);
            currentId = $(this).attr('id').split('-')[0];
            $('#stepBack').prop('disabled', false);

            // Send data via POST
            setup.Company.CompanyName = $('#company').val();
            setup.Company.CompanyNumber = $('#companyId').val();
            setup.Company.Address = $('#address').val();
            setup.Company.PostalCode = $('#zipcode').val();
            setup.Company.City = $('#place').val();
            setup.Company.CountryISO = $('#country').val();
            setup.Contact.Salutation = $('#salutation').val();
            setup.Contact.FirstName = $('#name').val();
            setup.Contact.LastName = $('#surname').val();
            setup.Contact.EMail = $('#email').val();
            setup.Contact.Phone = $('#phone').val();
            setup.Network.Hostname = $('#hostname').val();

            if ($('#dhcpSwitch').is(':checked')) {
                setup.Network.IP = '';
                setup.Network.NetMask = '';
                setup.Network.Gateway = '';
                setup.Network.DNS = '';
            } else {
                setup.Network.IP = $('#hostIp').val();
                setup.Network.NetMask = $('#subnet').val();
                setup.Network.Gateway = $('#gateway').val();

                setup.Network.AltDNS = [];
                $('.altDns').each(function () {
                    setup.Network.AltDNS.push($(this).val());
                });
                setup.Network.DNS = $('#dns').parent().parent().children()[1].childNodes[1].value;
                console.log(setup.Network.AltDNS);
                console.log(setup.Network.DNS);
            }
            setup.Proxy.Enable = $('#proxySwitch').prop('checked');
            if ($('#proxySwitch').is(':checked')) {
                setup.Proxy.IP = $('#proxyIp').val();
                setup.Proxy.Port = parseInt($('#port').val());

                setup.Proxy.AuthEnable = $('#authSwitch').prop('checked');
                if ($('#authSwitch').is(':checked')) {
                    setup.Proxy.Username = $('#username').val();
                    setup.Proxy.Password = $('#password').val();
                    console.log(setup.Proxy.Password);
                } else {
                    setup.Proxy.Username = '';
                    setup.Proxy.Password = '';
                }
            } else {
                setup.Proxy.IP = '';
                setup.Proxy.Port = 0;
                setup.Proxy.AuthEnable = false;
                setup.Proxy.Username = '';
                setup.Proxy.Password = '';
            }
            let tzIndex = document.getElementById('timezone').options.selectedIndex;
            let tzContent = document.getElementById('timezone').options[tzIndex].innerHTML;
            setup.Connection.TimeZone = tzContent.split(' ')[0];
            // setup.Connection.TimeZone = document.getElementById('timezone').options[document.getElementById('timezone').selectedIndex].innerHTML;
            // setup.Connection.TimeZone = $('#timezone option:selected').html();
            // setup.Connection.TransferRate = parseInt($('#datarate option:selected').val());
            setup.Connection.TransferRate = parseInt(newDateRateValue);
            // Gather download times
            let times = {};
            let day = $('#downloadTimes tbody tr').first().attr('id').substring(0, $('#downloadTimes tbody tr').first().attr('id').length - 5);
            let time = $('#downloadTimes tbody tr').find('.timeSet').first().attr('data-time');

            $('#downloadTimes tbody tr').each(function () {
                let range = [];
                let prev = 0;
                let chunks = [
                    []
                ];
                let stst = [];

                day = $(this).attr('id').substring(0, $(this).attr('id').length - 5);

                $(this).find('.timeSet').each(function () {
                    time = $(this).attr('data-time');
                    range.push(parseInt(time));
                });

                range.forEach((curr) => {
                    if (prev + 30 === curr) {
                        chunks[chunks.length - 1].push(curr);

                    } else {
                        chunks.push([curr]);
                    }

                    prev = curr;
                });

                chunks.forEach((curr) => {
                    if (curr.length) {
                        stst.push([timeFromMinutes(curr[0] - 30), timeFromMinutes(curr[curr.length - 1])]);
                    }
                });

                // Build object
                times[day] = stst;
            });

            setup.Connection.DownloadTimes = times;

            // Senden der Setup Daten an das Backend
            setupEval.data.post.setupData(setup);

            $('#stepForward').prop('disabled', true);

            console.log(currentId, "tab shown");
        })
        .on('hide.bs.tab', function () {

        })
        .on('hidden.bs.tab', function () {
            $('#stepForward').prop('disabled', false);
        });

    $('#stepBack').prop('disabled', false);


    // Navigation Einrichtung
    $('#stepForward')
        .unbind().click(function () {
        var pos = sessionStorage.getItem('step');
        setTimeout(function () {
            $('.wrapSteps .nav .active').parent().next('li').find('.nav-link')[0].click(function (e) {
                e.preventDefault();
            });
        }, 100);
        // $('.wrapSteps .nav .active').parent().next('li').find('.nav-link').trigger('click');
    });

    $('#stepBack')
        .unbind().click(function () {
            var pos = sessionStorage.getItem('step');
            if (pos==="1") {
                //	start/erste Seite der Einrichtung verbergen.
                $('#start').attr('class', 'tab-pane fade');
                // Status anzeigen
                setupEval.statusseiteSetup();
                $('#initSetup').attr('class', 'tab-pane fade');
                $('#initSetup').hide();
                $('#statusSetup').show();
                $('#statusSetup').attr('class', 'tab-pane fade show active');
                setupInit();
                updateContent();
            } else {
                setTimeout(function () {
                    $('.wrapSteps .nav .active').parent().prev('li').find('.nav-link')[0].click();
                }, 100);
            }
        // $('.wrapSteps .nav .active').parent().prev('li').find('.nav-link').trigger('click');
    });


    // Navigation Status / Startseite - ZetupWisard
    $('#startEinrichtung')
        .unbind().click(function () {
        document.getElementById('topbar').innerHTML = topPark;
        document.getElementById('bottombar').innerHTML = bottomPark;
        document.body.style.backgroundColor = 'white';
        //	Setup-Initialseite ausblenden
        $('#initSetup').attr('class', 'tab-pane fade');
        $('#initSetup').hide();
        $('#statusSetup').attr('class', 'tab-pane fade');
        $('#statusSetup').hide();
        //	start/erste Seite der Einrichtung anzeigen.
        $('#start').attr('class', 'tab-pane fade show active');
        setupInit();
        updateContent();
    });

    $('#showStatus')
        .unbind().click(function () {
        setupEval.statusseiteSetup();
        $('#initSetup').attr('class', 'tab-pane fade');
        $('#initSetup').hide();
        $('#statusSetup').show();
        $('#statusSetup').attr('class', 'tab-pane fade show active');
        setupInit();
        updateContent();
    });

    $('#hideStatus')
        .unbind().click(function () {
        setupEval.startseiteSetup();
        $('#statusSetup').attr('class', 'tab-pane fade');
        $('#statusSetup').hide();
        $('#initSetup').show();
        $('#initSetup').attr('class', 'tab-pane fade show active');
        setupInit();
        updateContent();
    });


    // Switches
    $('#privacyCheck').change(function () {
        console.log($('#privacyCheck').is(':checked'));
    });
    $('#dhcpSwitch').change(function () {
        if (this.checked) {
            $(this).parent().parent().parent().next('.row').hide();
            setup.Network.DHCP = true;
            console.log('DHCP on');
        } else {
            $(this).parent().parent().parent().next('.row').show();
            setup.Network.DHCP = false;
            console.log('DHCP off');
        }
    });
    $('#proxySwitch').change(function () {
        if (this.checked) {
            $(this).parent().parent().parent().next('.row').show();
            $(this).parent().parent().next('.col').show();
            setup.Proxy.Enable = true;
            console.log('Proxy on');
        } else {
            $(this).parent().parent().parent().next('.row').hide();
            $(this).parent().parent().next('.col').hide();
            setup.Proxy.Enable = false;
            console.log('Proxy off');
        }
    });
    $('#authSwitch').change(function () {
        if (this.checked) {
            $(this).parent().parent().parent().next().find('.JsAuthGroup').show();
            setup.Proxy.AuthEnable = true;
            console.log('Proxy authentication on');
        } else {
            $(this).parent().parent().parent().next().find('.JsAuthGroup').hide();
            setup.Proxy.AuthEnable = false;
            console.log('Proxy authentication off');
        }
    });

    if (dnsCount >= 4) {
        $('#addDns').css('display', 'none');
    }
    $('#addDns').unbind().click(function () {
        var $clone = $('#dnsCopy').clone(true).removeAttr('id').appendTo('#dnsList');
        $clone.find('input').attr('id', 'dns' + dnsCount).addClass('altDns');
        $clone.find('label').attr('for', 'dns' + dnsCount);
        $clone.find('label').html(i18next.t('rdsw_altDNS'));
        $clone.focus();
        dnsCount++;
        if (dnsCount >= 4) {
            $('#addDns').css('display', 'none');
        }
    });
    $('#delDns').unbind().click(function () {
        let k = 3;
        let l = $(this).parent().parent().parent().children().length;
        while (l >= k) {
            let c = $(this).parent().parent().parent().children()[k - 1].childNodes[1].childNodes[1];
            l--;
        }
        dnsCount--;
        $(this).parent().parent().remove();
        if (dnsCount < 4) {
            $('#addDns').css('display', '');
        }
        console.log('DNS input removed');
    });

    $('#rdsw_p4').unbind().click(function(){
        let language = getCookie("lng");
        if(language) {
            let filepath = "../xentry/art/Privacy_Policy_"+language+".pdf";
            $.ajax({
                url: filepath,
                type: 'HEAD',
                success: function () {
                    window.open(filepath, '_blank');
                },
                error: function () {
                    window.open("../xentry/art/Privacy_Policy_en.pdf", '_blank');
                }
            });
        } else {
            window.open("../xentry/art/Privacy_Policy_en.pdf", '_blank');
        }
    });

    // Auswahl erste Seite & Setzen der Setup Elemente
    setupEval.data.get.firstPage();

    getVersion();
    getSystemID();

    // TODO validate input fields -?-?-?- noch nötig?
    function checkInput(pos) {
        let status = false;
        switch (pos) {
            case 1:
                if ($('#privacyCheck').is(':checked')) {
                    status = true;
                    console.log($('#privacyCheck').is(':checked'));
                } else {
                    status = false;
                    console.log($('#privacyCheck').is(':checked'));
                }
                break;
            case 2:
                if ($('#email').val() && $('#country').val()) {
                    status = true;
                } else {
                    status = false;
                }
                console.log($('#country').val());
                break;
            case 3:
                // text
                break;
            case 4:
                // text
                break;
            default:
                // text
                break;
        }
        return status;
    }


}

/**
 * Funktion zur Aktivierungsseite
 */
function activationInit() {
    'use strict';
    // Page specific
    // -----------------------------------------------------------------------------
    app.name = 'Retail Data Storage - Activation';

    $(document).ready(function () {
    });

    console.info(app.name +'\n');
    console.info('API:', api);

    // rdsCheck();

    activation.backend.check.activationState();
    

    // Submit activation key
    //----------------------------------------------------------------------------
    $('#activationForm').submit(function (event) {
        event.preventDefault();
        let key = $('#key').val();
        let keyConfirm = $('#keyConfirm').val();
        if (key === keyConfirm) {
            activation.backend.check.keys(key);
            console.log('Keys are correct.');
        } else {
            popup.alert('activationWK');
            console.log('Keys do not match!');
            activation.backend.check.activationState();
        }
    });

    getVersion();
    getSystemID();
}

/**
 * Funktion zur XENTRY-Seite
 */
function xentryInit() {
    'use strict';
    // Page specific
    // -----------------------------------------------------------------------------
    $(document).ready(function(){});
    app.name = 'XENTRY Update Service Control Center';
    console.info(app.name +'\n');
    console.info('API:', api);

    buildStartpageForm();

    $('#home-tab')
        .on('show.bs.tab', function () {
            gloop.monitoring.stop();
            gloop.monitoringLight.stop();
            gloop.releases.stop();
            gloop.releaseLight.stop();
            gloop.flashware.stop();
            gloop.devices.stop();
            console.log("Overview tab shown");
        })
        .on('hidden.bs.tab', function () {
            // console.log("Overview tab hidden");
        });

    $('#monitoring-tab')
        .on('show.bs.tab', function () {
            gloop.monitoring.start();
            console.log("Monitoring tab shown");
        })
        .on('hidden.bs.tab', function () {

        });

    $('#devices-tab')
        .on('show.bs.tab', function () {
            $('#imgXPAD').attr('src', './art/xdpad.svg');
            gloop.devices.start();
            console.log("Devices tab shown");
        })
        .on('hide.bs.tab', function () {
            $('#imgXPAD').attr('src', './art/xdpad_white.svg');
        });

    $('#releases-tab')
        .on('show.bs.tab', function () {
            gloop.releases.start();
            console.log("Releases tab shown");
        })
        .on('hidden.bs.tab', function () {
        });

    $('#flashware-tab')
        .on('show.bs.tab', function () {
            gloop.flashware.start();
            console.log("Flashware tab shown");
        })
        .on('hide.bs.tab', function () {
            document.getElementById("login").style.display = "none";
            document.getElementById("setPassword").style.display = "none";
        });

    $('#settings-tab')
        .unbind().click(function () {

        })
        .on('show.bs.tab', function () {
            let token = window.sessionStorage.getItem("accessToken");
            if (!validateAccessToken(token)) {
                login(document.getElementById("xentry_monitoring_admin_tab"));
            }
            gloop.monitoring.stop();
            gloop.monitoringLight.stop();
            gloop.releases.stop();
            gloop.releaseLight.stop();
            gloop.flashware.stop();
            gloop.devices.stop();
            console.log("Settings tab shown");
        })
        .on('hidden.bs.tab', function () {
            document.getElementById("login").style.display = "none";
            document.getElementById("setPassword").style.display = "none";
        });
    $

    // Sorting for tables
    $('.table thead').find('.colSort').each(function () {
        const s = $(this).attr("data-sort");
        let d = "";
        $(this).click(function () {
            if ($(this).hasClass('active')) {
                $(this).toggleClass('reverse');
            } else {
                $(this).addClass('active');
                $(this).siblings().removeClass('active');
            }

            if ($(this).hasClass('reverse')) {
                d = "DESC";
            } else {
                d = "ASC";
            }
            sort = "?sort=" + s + ":" + d;
            var id = $(this).parent().parent().parent().attr('id');
            switch (id) {
                case "deviceTable":
                    // devices.start();
                    break;
                case "releaseTable":
                    // releases.start();
                    break;
                case "flashwareTable":
                    // flashware.start();
                    break;
                default:
            }
        });
    });

    // Search the tables
    $('#releaseSearchButton').on('click', function () {
    });
    $('#releaseSearch').on('keyup', function () {
    });

    $('#xuscc_b0').on('click', function () {
        devices.search();
        console.log("Searching...");
    });
    $('#xuscc_i0').on('keyup', function () {
        devices.search();
        console.log("Searching...");
    });

    // Define default states for elements
    $('.jsFlashPrev').parent().addClass('disabled');
    $('.jsFlashPrev').attr('tabindex', '-1');
    $('.jsFlashPrev').attr('aria-disabled', 'true');
    $('.jsFlashFirst').parent().addClass('disabled');
    $('.jsFlashFirst').attr('tabindex', '-1');
    $('.jsFlashFirst').attr('aria-disabled', 'true');

    ///	========================================================
    //	Get admin settings from API
    //
    /// ========================================================
    var settingsRequest = $.ajax({
        accepts: 'application/json; charset=UTF-8',
        dataType: 'json',
        context: $('#adminForm')[0],
        url: api.be8012 + '/xentry/api/v' + api.version + '/adminSettings',
    })
        .done(function (data) {
            // Set auto load devices
            if (data.ActivateDownload === null) {
                $('#auto-load-check-kit2').prop('checked', false);
            } else {
                if (data.ActivateDownload.KIT2) {
                    $('#auto-load-check-kit2').prop('checked', true);
                } else {
                    $('#auto-load-check-kit2').prop('checked', false);
                }
            }
            console.log(settingsRequest.responseJSON);
        })
        .fail(function () {
            console.log("ADMIN SETTINGS - No response from API!");
        });
    ///	========================================================
    //	Get admin settings from SESSION
    // 	if (sessionStorage.getItem('devmode'))
    /// ========================================================
    $('#devmodeSwitch').prop('checked', sessionStorage.getItem('devmode'));

    if ($('#devmodeSwitch').is(':checked')) {
        $('.devmodeElement').removeClass('magic');
        console.log('Devmode on.');
    } else {
        $('.devmodeElement').addClass('magic');
        console.log('Devmode off.');
    }
    $('#devmodeSwitch').change(function () {
        // Save data to sessionStorage
        sessionStorage.setItem('devmode', $('#devmodeSwitch').prop('checked'));
        if ($(this).is(':checked')) {
            $('.devmodeElement').removeClass('magic');
            // console.log('Devmode on.');
        } else {
            $('.devmodeElement').addClass('magic');
            // console.log('Devmode off.');
        }
        console.log('devmode', $('#devmodeSwitch').prop('checked'));
    });

    // Set admin settings
    $('#adminForm').submit(function (event) {
        event.preventDefault();
        let newKit2Check = {
            "ActivateDownload": {
                "KIT2": $('#auto-load-check-kit2').prop('checked')
            }
        };
        // Send the data using post
        let token = window.sessionStorage.getItem("accessToken");
        if (validateAccessToken(token)) {
            $.ajax({
                method: 'POST',
                contentType: 'application/json',
                // url: $(this).attr( "action" ),
                url: api.be8012 + '/xentry/api/v' + api.version + '/adminSettings',
                data: JSON.stringify(newKit2Check),
                beforeSend: function (xhr) {
                    xhr.setRequestHeader("Authorization", "Bearer " + token);
                }
            })
                .done(function (msg) {
                    console.log('Settings set.', msg);
                })
                .fail(function (jqXHR, textStatus) {
                    console.log('ADMIN SETTINGS - Request failed:', textStatus);
                });
        } else {
            login();
        }
    });

    // -----------------------------------------------------------------------------
    // -----------------------------------------------------------------------------

    getVersion();
    getSystemID();

    $('#theStar').click(function(){
        window.location.href = "../index.html";
    });
}

/**
 * Funktion zur Config-Seite
 */
function configInit() {
    
/// ====================
    {// ====== SCRIPT ======
     // ====================

        /// ======================================
        {// =========== Page specific ============
            // ======================================

            app.name = 'Retail Data Storage - Konfiguration';

            // Set href for special links
            $("#gtLink").attr("href", "http://" + location.hostname + ":8080");

            var errors = 0;
            var newDateRateValue = "";
            var currentDateRate;
        }

        /// ======================================
        {// ===== Get-Request systemSettings =====
            // ======================================

            var get_systemSettings = function(){
                let token = window.sessionStorage.getItem("accessToken");
                if (validateAccessToken(token)) {
                    $.ajax({
                        accepts: 'application/json; charset=UTF-8',
                        dataType: 'json',
                        context: document.body,
                        // url: 'http://localhost:8100/dummy/api/v1/systemSettings',
                        url: api.be8003 + '/config/api/v' + api.version + '/systemSettings',
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader("Authorization", "Bearer " + token);
                        },
                        success: function (data) {
                        },
                        error: function () {
                            $('#rdsc_b1').html('<i class="text-danger fas fa-exclamation-triangle fa-1x"></i> LOADING ERROR');
                            $('.systemId').html('<i class="text-danger fas fa-times-circle fa-lg"></i>');
                            $('.activationDate').html('<i class="text-danger fas fa-times-circle fa-lg"></i>');
                            $('.serialId').html('<i class="text-danger fas fa-times-circle fa-lg"></i>');
                            $('.macAddress').html('<i class="text-danger fas fa-times-circle fa-lg"></i>');
                            console.log("SYSTEM SETTINGS - API problem!");
                        },
                        complete: function (data) {
                            data = data.responseJSON;
                            let date = new Date(parseInt(data.Device.ActivationDate + '000'));
                            $('#rdsc_b1').removeAttr('disabled');
                            $('#rdsc_b1').html(i18next.t('rdsc_b11'));
                            $('.systemId').html(data.Device.SystemID);
                            $('.activationDate').html(date.toLocaleDateString(lng, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            }));
                            $('.serialId').html(data.Device.SerialNumber);
                            $('.macAddress').html(data.Device.MAC);
                            getVersion();
                            getSystemID();
                            setSetupElements(data);
                        }
                    });
                } else {
                    login(document.getElementsByClassName("container confCon")[0]);
                }
            }
            let wait = 0;
            let loadConfig = setInterval(function () {
                wait = wait + 100;
                if(trapLoaded){
                    get_systemSettings();
                    clearInterval(loadConfig);
                }
                else if(wait >= 3000){
                    get_systemSettings();
                    clearInterval(loadConfig);
                }
            }, 100);

            $('.dropdown-item').click(function(){
                get_systemSettings();
            });
        }
        // ======================================
        // ======================================

        /// ======================================
        {// ===== Range Input - Downloadrate =====
            // ======================================

            $('#dateRateRange').on("mousedown", function(){
                $('#dateRateRange').on("mousemove", function(){
                    let currentRangeSlider = document.getElementById('dateRateRange').value;
                    let newDateRate = "";
                    if(currentRangeSlider == "0"){newDateRate="48 "+i18next.t('rdsw_p15')+" (6 "+i18next.t('rdsw_p16')+")"; newDateRateValue=6144;}
                    else 	if(currentRangeSlider == "1"){newDateRate="64 "+i18next.t('rdsw_p15')+" (8 "+i18next.t('rdsw_p16')+")"; newDateRateValue=8192;}
                    else 	if(currentRangeSlider == "2"){newDateRate="80 "+i18next.t('rdsw_p15')+" (10 "+i18next.t('rdsw_p16')+")"; newDateRateValue=10240;}
                    else 	if(currentRangeSlider == "3"){newDateRate="96 "+i18next.t('rdsw_p15')+" (12 "+i18next.t('rdsw_p16')+")"; newDateRateValue=12288;}
                    else 	if(currentRangeSlider == "4"){newDateRate="112 "+i18next.t('rdsw_p15')+" (14 "+i18next.t('rdsw_p16')+")"; newDateRateValue=14336;}
                    else 	if(currentRangeSlider == "5"){newDateRate="128 "+i18next.t('rdsw_p15')+" (16 "+i18next.t('rdsw_p16')+")"; newDateRateValue=16384;}
                    else 	if(currentRangeSlider == "6"){newDateRate="144 "+i18next.t('rdsw_p15')+" (18 "+i18next.t('rdsw_p16')+")"; newDateRateValue=18432;}
                    else 	if(currentRangeSlider == "7"){newDateRate=i18next.t('rdsw_p14'); newDateRateValue=0;}
                    else{newDateRate=i18next.t('rdsw_p14'); newDateRateValue=0;}
                    document.getElementById('dateRateValue').innerHTML = newDateRate;
                    currentDateRate = newDateRateValue;
                });
            });
        }
        // ======================================

        /// ======================================
        {// ==== Time selection functionality ====
            // ======================================

            let o_0,x1,x2,xm,xM,y1,y2,ym,yM;

            // ---------------------------------------------------------------------------
            // ==== Touch & Mouse ====


            $('#downloadTimes td').on("mousedown tabhold", function(ev){
                ev.preventDefault();
                this.addEventListener("contextmenu", function(e){e.preventDefault();})
                x1 = $(this).attr('data-time')/30;
                y1 = parseInt($(this).parent().attr('data-y'));
                if($(this).hasClass('timeSet')){o_0=true;}
                else{o_0=false;}
            }).on("mouseup tab", function(){
                x2 = $(this).attr('data-time')/30;
                y2 = parseInt($(this).parent().attr('data-y'));
                bigTime(x1,x2,y1,y2);
                function bigTime(x1,x2,y1,y2){
                    if(x2 > x1){xm=x1;xM=x2;}
                    else 	if(x2 < x1){xm=x2;xM=x1;}
                    else  if(x2===x1){xm=x1;xM=x1;}
                    if(y2 > y1){ym=y1;yM=y2;}
                    else 	if(y2 < y1){ym=y2;yM=y1;}
                    else  if(y2===y1){ym=y1;yM=y1;}
                    drawSquare(o_0,xm,xM,ym,yM);
                }
                function drawSquare(o_0,xm,xM,ym,yM){
                    let yI = ym;
                    while(yI <= yM){
                        let xI = xm;
                        while(xI <= xM){
                            if(o_0){
                                $('#downloadTimes').children().children()[yI].cells[xI].className = '';
                            }
                            else{
                                $('#downloadTimes').children().children()[yI].cells[xI].className = 'timeSet';
                            }
                            xI++;
                        }
                        yI++;
                    }
                }
            });

        
        }
        // ======================================




        // ======================================



        function setSetupElements (data) {
            var times = data.Connection.DownloadTimes;
            var dateRangePosition = "";
            var cDRString = "";
            currentDateRate = data.Connection.TransferRate;
            Object.keys(times).forEach(function (range) {
                times[range].forEach(function (time) {
                    var s = time[0].split(":");
                    var start = parseInt(s[0]) * 60 + parseInt(s[1]) + 30;
                    var e = time[1].split(":");
                    var end = parseInt(e[0]) * 60 + parseInt(e[1]) + 30;
                    for (var i = start; i < end; i += 30) {
                        $('#'+ range +'Range').find('td[data-time='+ i +']').addClass('timeSet');
                    }
                });
            });
            if(currentDateRate == 6144){cDRString="48 "+i18next.t('rdsw_p15')+" (6 "+i18next.t('rdsw_p16')+")"; dateRangePosition="0"}
            else 	if(currentDateRate == 8192){cDRString="64 "+i18next.t('rdsw_p15')+" (8 "+i18next.t('rdsw_p16')+")"; dateRangePosition="1"}
            else 	if(currentDateRate == 10240){cDRString="80 "+i18next.t('rdsw_p15')+" (10 "+i18next.t('rdsw_p16')+")"; dateRangePosition="2"}
            else 	if(currentDateRate == 12288){cDRString="96 "+i18next.t('rdsw_p15')+" (12 "+i18next.t('rdsw_p16')+")"; dateRangePosition="3"}
            else 	if(currentDateRate == 14336){cDRString="112 "+i18next.t('rdsw_p15')+" (14 "+i18next.t('rdsw_p16')+")"; dateRangePosition="4"}
            else 	if(currentDateRate == 16384){cDRString="128 "+i18next.t('rdsw_p15')+" (16 "+i18next.t('rdsw_p16')+")"; dateRangePosition="5"}
            else 	if(currentDateRate == 18432){cDRString="144 "+i18next.t('rdsw_p15')+" (18 "+i18next.t('rdsw_p16')+")"; dateRangePosition="6"}
            else 	if(currentDateRate == 0){cDRString=i18next.t('rdsw_p14'); dateRangePosition="7"}
            else{cDRString=i18next.t('rdsw_p14'); dateRangePosition="7"}
            document.getElementById('dateRateValue').innerHTML = cDRString;
            document.getElementById('dateRateRange').value = dateRangePosition;
        }

        /// ======================================
        {// ==== Post-Request systemSettings =====
            // ======================================
            let newTimeTable = {
                "Connection": {
                    "DownloadTimes": {
                        "montag": [],
                        "dienstag": [],
                        "mittwoch": [],
                        "donnerstag": [],
                        "freitag": [],
                        "sonnabend": [],
                        "sonntag": []
                    },
                    "TransferRate": 0
                }
            };
            $('#rdsc_b1').click(function(){
                sendNewTimeTable();
            });

            let sendNewTimeTable = function(){
                let times = {};
                let day = $('#downloadTimes tbody tr').first().attr('id').substring(0, $('#downloadTimes tbody tr').first().attr('id').length - 5);
                let time = $('#downloadTimes tbody tr').find('.timeSet').first().attr('data-time');

                $('#downloadTimes tbody tr').each(function() {
                    let range = [];
                    let prev = 0;
                    let chunks = [
                        []
                    ];
                    let stst = [];

                    day = $(this).attr('id').substring(0, $(this).attr('id').length - 5);

                    $(this).find('.timeSet').each(function() {
                        time = $(this).attr('data-time');
                        range.push(parseInt(time));
                    });

                    range.forEach((curr) => {
                        if (prev + 30 == curr) {
                            chunks[chunks.length - 1].push(curr);

                        } else {
                            chunks.push([curr]);
                        }

                        prev = curr;
                    });

                    chunks.forEach((curr) => {
                        if (curr.length) {
                            stst.push([timeFromMinutes(curr[0] - 30), timeFromMinutes(curr[curr.length - 1])]);
                        }
                    });

                    // Build object
                    times[day] = stst;
                });

                newTimeTable.Connection.TransferRate = parseInt(currentDateRate);
                newTimeTable.Connection.DownloadTimes = times;

                console.log('newTimeTable ==>');
                console.log(newTimeTable);
                let token = window.sessionStorage.getItem("accessToken");
                if (validateAccessToken(token)) {
                    $.ajax({
                        //url: 'http://192.168.180.212/config/api/v'+ api.version +'/systemSettings',
                        url: api.be8003 + '/config/api/v' + api.version + '/systemSettings',
                        method: "POST",
                        contentType: 'application/json',
                        data: JSON.stringify(newTimeTable),
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader("Authorization", "Bearer " + token);
                        },
                        success: function () {
                            popup.alert("settingsSaved");
                            console.log('data: ');
                            console.log(JSON.stringify(newTimeTable));
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            console.log("sending new Timetable - API problem!");
                            console.log(jqXHR);
                            console.log(textStatus + ": ", errorThrown);
                        }
                    });
                } else {
                    login();
                }
            }
        }
        // ======================================

        /// ======================================
        {// ========= Rest des Dokuments =========
            // ======================================

            $('#rdsc_b0').unbind().click(function(){
                let language = getCookie("lng");
                if(language) {
                    let filepath = "../xentry/art/User Guide_RS100E8-PI2_"+language+".pdf";
                    $.ajax({
                        url: filepath,
                        type: 'HEAD',
                        success: function () {
                            window.open(filepath, '_blank');
                        },
                        error: function () {
                            window.open("../xentry/art/User Guide_RS100E8-PI2_en.pdf", '_blank');
                        }
                    });
                } else {
                    window.open("../xentry/art/User Guide_RS100E8-PI2_en.pdf", '_blank');
                }
            });

            $('#theStar').click(function(){
                window.location.href = "index.html";
            });

            $(document).ready(function () {
            });

            console.info(app.name +'\n');
            console.info('API:', api);

            // startInit();
        }
        // ======================================

    }
    // ====================
}

/**
 * Funktion für light
 */
function lightInit() {
    'use strict';

    // Page specific
    // -----------------------------------------------------------------------------
    app.name = 'XENTRY Update Service Control Center';

    $(document).ready(function () {
        $('a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
            // console.log(e.target, e.relatedTarget);
        });
    });


    $('#theStar').click(function(){
        window.location.href = "index.html";
    });

    console.info(app.name +'\n');
    console.info('API:', api);

    $.ajax({
        method: 'GET',
        accepts: 'application/json; charset=UTF-8',
        dataType: 'json',
          url: api.be8012 + '/xentry/api/v' + api.version + '/adminSettings',
        // url: 'http://localhost:8100/dummy/api/v1/adminSettings',
        success: function (data) {
            if (data.ShowFlashware ===false || data.ShowFlashware === 0)
            {
                document.getElementById('flashware-tab').style.display = "none";
            }

        },
        error: function () {
          console.log("API adminSettings error");
        }
        
    });

    // Build dynamic interface elements
    clearAllTimers();
    buildStartpageForm();
    // Add tab events
    // This is used as the major control flow for the app
    $('#home-tab')
        .on('show.bs.tab', function () {
            gloop.monitoringLight.stop();
            gloop.releaseLight.stop();
            gloop.flashware.stop();
            getVersion('light');
            console.log("Overview tab shown");
        })
        .on('hidden.bs.tab', function () {
        });

    $('#monitoring-tab')
        .on('show.bs.tab', function () {
            gloop.monitoringLight.start();
            upstreamStateCheck();
            setSpaceBars();
            getVersion('light');
            console.log("Monitoring tab shown");
        })
        .on('hidden.bs.tab', function () {
        });

    $('#releases-tab')
        .on('show.bs.tab', function () {
            gloop.releaseLight.start();
            getVersion('light');
            console.log("Releases tab shown");
        })
        .on('hidden.bs.tab', function () {
        });

    $('#flashware-tab')
        .on('show.bs.tab', function () {
            gloop.flashwareLight.start();
            getVersion('light');
            console.log("Flashware tab shown");
        })
        .on('hidden.bs.tab', function () {
        });

    setTimeout(function () {
        getVersion('light');
    }, 333);
}

/**
 * Funktion für Index-Seite
 */
function indexInit() {
    // Page specific
    // -----------------------------------------------------------------------------
    app.name = 'Retail Data Storage - Start';

    $('#theStar').click(function(){
        window.location.href = "index.html";
    });


    getVersion();
    getSystemID();

    $(document).ready(function () {
    });

    console.info(app.name +'\n');
    console.info('API:', api);

    // startInit();
}

/**
 * Clear all timers
 * noch nötig? da in XentryInit() die Loops je nach gewählten Tag gestartet bzw. wieder gestoppt werden.
 */
function clearAllTimers() {
    'use strict';

    for (var i = 0; i < app.timer.length; i++) {
        if (app.timer[i]) {
            clearTimeout(app.timer[i]);
            console.log("Cleared timer:", app.timer[i]);
        }
    }
}

/**
 * download files
 * @param {string[]} urls
 */
function downloadFiles(urls) {
    'use strict';

    var link = document.createElement('a');
    link.setAttribute('download', null);
    link.style.display = 'none';
    document.body.appendChild(link);
    for (var i = 0; i < urls.length; i++) {
        link.setAttribute('href', urls[i]);
        link.click();
    }
    document.body.removeChild(link);
}

/**
 * build startpage-form
 */
function buildStartpageForm() {
    'use strict';
    updateContent();
    $('.tab-pane').each(function (i, el) {
        let slug = $(el).attr('id');
        let title = $(el).find('h2').first().html();
        let $clone = $('#startpageForm').find('.form-check').first().clone();
        $clone.removeAttr('style');
        $clone.find('input').attr('id', slug + 'Radio');
        $clone.find('input').attr('value', slug);
        $clone.find('input').attr('disabled', 'disabled');
        $clone.find('label').html(title);
        $clone.find('label').attr('for', slug + 'Radio');
        $('#startpageForm').find('.col-10').first().append($clone);
    });
}

/**
 * format minutes to display HH:MM
 * @param {number} minutes
 * @returns {string}
 */
function timeFromMinutes(minutes) {
    'use strict';

    const seconds = minutes * 60;
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    // const s = seconds % 60;
    return [
        h > 9 ? h : (h ? '0' + h : '00'),
        m > 9 ? m : (h ? '0' + m : m || '00'),
        // s > 9 ? s : '0' + s,
    ].filter(a => a).join(':');
}

/**
 * format unix time to display ISO date
 * @param {string} time
 * @param {string} local
 * @returns {string}
 */
function dateFromUnixtime(time, local) {
    'use strict';

    local = lng;
    // var localDate = new Date(time * 1e3).toISOString();
    var localDate = new Date(time * 1e3).toLocaleString(local);
    return localDate;
}

/**
 * check UpstreamState
 */
function upstreamStateCheck() {
    'use strict';
    console.log('upstreamStateCheck');
        $.ajax({
            method: 'GET',
            accepts: 'application/json; charset=UTF-8',
            dataType: 'json',
            context: $('#monitoring')[0],
            url: api.be8012 + '/xentry/api/v' + api.version + '/upstreamState',
            // url: 'http://www.mocky.io/v2/5d1dc7a93000002a44d721cd',
            success: function (data) {
                console.log(data.UpstreamState);
                var rows = [];
                var j = -1;
                var vpnState = false;
                for (let [url, state] of Object.entries(data.UpstreamState)) {
                    if (url === '10.200.0.1:80' || url === '10.7.0.1:80') {
                        vpnState = vpnState || state;
                    } else {
                        rows[++j] = '<li class="list-group-item d-flex justify-content-between align-items-center">';
                        rows[++j] = '<span>' + url + '</span>';
                        rows[++j] = '<i class="';
                        if (state) {
                            rows[++j] = 'text-success fas fa-check-circle';
                        } else {
                            rows[++j] = 'text-danger fas fa-times-circle';
                        }
                        rows[++j] = ' fa-lg"></i>';
                        rows[++j] = '</li>';
                    }
                }
                $('#upstreamList').html(rows.join(''));

                if (typeof variante !== 'undefined') {
                    if (vpnState && variante === 'xentry') {
                        $('#upstreamList').append('<li class="list-group-item d-flex justify-content-between align-items-center"><span>Gerätesteuerung und -wartung</span><i class="text-success fas fa-check-circle fa-lg"></i></li>');
                        $('#xusccl_h6').html(i18next.t('xusccl_h6'));
                    } else if (variante === 'xentry') {
                        $('#upstreamList').append('<li class="list-group-item d-flex justify-content-between align-items-center"><span>Gerätesteuerung und -wartung</span><i class="text-success fas fa-check-circle fa-lg"></i></li>');
                        $('#xusccl_h6').html(i18next.t('xusccl_h6'));
                    } else if (variante === 'light') {
                        $('#xusccl_h6').html(i18next.t('xusccl_h6'));
                        $('.spinner-border').remove();
                        $('.spinner-pre-span').remove();
                    } else {
                        $('#xusccl_h6').html(i18next.t('xusccl_h6'));
                    }
                }
                console.log("API returned upstream state");
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $('#xusccl_h6').html(i18next.t('xusccl_h6'));
                $('.spinner-border').remove();
                document.getElementById('xusccl_h6').innerHTML += ' (Error)';
                console.log("API problem!\n", textStatus + " :", errorThrown);
            }
        });
 }

/**
 * SetSpaceBar
 * für Light monitoring seite> zeigt den Speicherplatz an
 */
function setSpaceBars() {
    'use strict';
    $.ajax({
        method: 'GET',
        accepts: 'application/json; charset=UTF-8',
        dataType: 'json',
        context: $('#monitoring')[0],
        url: api.be8012 + '/xentry/api/v' + api.version + '/monitoringDataLight',
        // url: 'http://localhost:8100/dummy/api/v1/monitoringDataLight',
        success: function (data) {
            let a = parseInt(data.FlashwareStorage);			// RS
            let b = parseInt(data.UsedFlashwareStorage);	// FW Flashware, die schon im RS (Ringspeicher) liegt
            let c = parseInt(data.FreeDiskSpace);					// FS	Freier Speicher des Systems
            let d = 5 * 1024 * 1024 * 1024;											// P 	Puffer
            if (a && a > 0) {
                if ((c - d) >= (a - b)) {/*just do nothing. all cool*/
                } else if ((c - d) < (a - b) && (c > d)) {
                    $('#xusccl_p15').html(i18next.t('xusccl_p14'));
                    $('#theSpaceBar').css('border', '2px solid rgba(255,220,25,0.77)');
                    $('.fa-exclamation-triangle').css('display', '');
                    $('.fa-exclamation-triangle').attr('class', 'text-warning fas fa-exclamation-triangle	fa-3x');
                } else if (c <= d) {
                    $('#xusccl_p15').html(i18next.t('xusccl_p15'));
                    $('#theSpaceBar').css('border', '2px solid rgba(255,25,25,0.99)');
                    $('.fa-exclamation-triangle').css('display', '');
                    $('.fa-exclamation-triangle').attr('class', 'text-danger fas fa-exclamation-triangle fa-3x');
                }

                let c0 = 100 * b / a;
                c0 = c0.toFixed(2);
                let d0 = 100 * (a - b) / a;
                d0 = d0.toFixed(2);
                let a0 = a / (1024 * 1024 * 1024);
                a0 = a0.toFixed(2);
                document.getElementById('freeDisk').innerHTML = a0 + 'GB';

                document.getElementById('b-Bar').style.width = c0 + '%';
                document.getElementById('d-Bar').style.width = d0 + '%';
                document.getElementById('xusccl_d11').innerHTML = ' (' + a0 + 'GB)';
                document.getElementById('xusccl_d01').innerHTML = ' (' + c0 + '%)';
            } else {
                document.getElementById('freeDisk').innerHTML = ' not known';

                document.getElementById('b-Bar').style.width = '0px';
                document.getElementById('d-Bar').style.width = '0px';
                document.getElementById('xusccl_d11').innerHTML = ' (not known)';
                document.getElementById('xusccl_d01').innerHTML = ' (not known)';
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            document.getElementById('freeDisk').innerHTML = ' (error)';
            document.getElementById('b-Bar').style.width = '0px';
            document.getElementById('xusccl_d11').innerHTML = ' (error)';
            document.getElementById('d-Bar').style.width = '0px';
            document.getElementById('xusccl_d01').innerHTML = ' (error)';
            // document.getElementById('e-Bar').style.width = '30%';
            // document.getElementById('info-eBar').innerHTML = 'frei Platte (30%)';
            console.log("MONITORING DATA LIGHT - API problem!\n", textStatus + " :", errorThrown);
        },
        complete: function () {

        }
    });
}

/**
 * setup
 */
function setupPrEval() {
    'use strict';
    /// ============================================
    // ===========  InitPage Specifics  ===========
    // ============================================
    topPark = document.getElementById('topbar').innerHTML;
    bottomPark = document.getElementById('bottombar').innerHTML;

    // ============================================


    /// ============================================
    // ===========  Setup Initialstart  ===========
    // ============================================
    setupEval.startseiteSetup();


    // ============================================


    ///	============================================
    //	===========  Status--Startseite  ===========
    //	============================================
    setupEval.statusseiteSetup();


    // ============================================


    /// ============================================
    // ===========  Checks current Tab  ===========
    // ============================================
    setupEval.checkTab();

    // ============================================


    /// ============================================
    // ===========  Download -- Regler  ===========
    // ============================================
    downloadSlider();

    function downloadSlider() {
        $('#dateRateRange').mousedown(function () {
            $('#dateRateRange').mousemove(function () {
                var currentRangeSlider = document.getElementById('dateRateRange').value;
                var newDateRate = "";
                /*if(currentRangeSlider === "0"){newDateRate="256 kByte/s"; newDateRateValue=256;}
                else if(currentRangeSlider === "1"){newDateRate="512 kByte/s"; newDateRateValue=512;}
                else if(currentRangeSlider === "2"){newDateRate="1 MByte/s"; newDateRateValue=1024;}
                else if(currentRangeSlider === "3"){newDateRate="2 MByte/s"; newDateRateValue=2048;}
                else if(currentRangeSlider === "4"){newDateRate="4 MByte/s"; newDateRateValue=4096;}
                else */
                if (currentRangeSlider === "0") {
                    newDateRate = "48 "+i18next.t('rdsw_p15')+" (6 "+i18next.t('rdsw_p16')+")";
                    newDateRateValue = 6144;
                } else if (currentRangeSlider === "1") {
                    newDateRate = "64 "+i18next.t('rdsw_p15')+" (8 "+i18next.t('rdsw_p16')+")";
                    newDateRateValue = 8192;
                } else if (currentRangeSlider === "2") {
                    newDateRate = "80 "+i18next.t('rdsw_p15')+" (10 "+i18next.t('rdsw_p16')+")";
                    newDateRateValue = 10240;
                } else if (currentRangeSlider === "3") {
                    newDateRate = "96 "+i18next.t('rdsw_p15')+" (12 "+i18next.t('rdsw_p16')+")";
                    newDateRateValue = 12288;
                } else if (currentRangeSlider === "4") {
                    newDateRate = "112 "+i18next.t('rdsw_p15')+" (14 "+i18next.t('rdsw_p16')+")";
                    newDateRateValue = 14336;
                } else if (currentRangeSlider === "5") {
                    newDateRate = "128 "+i18next.t('rdsw_p15')+" (16 "+i18next.t('rdsw_p16')+")";
                    newDateRateValue = 16384;
                } else if (currentRangeSlider === "6") {
                    newDateRate = "144 "+i18next.t('rdsw_p15')+" (18 "+i18next.t('rdsw_p16')+")";
                    newDateRateValue = 18432;
                } else if (currentRangeSlider === "7") {
                    newDateRate = i18next.t('rdsw_p14');
                    newDateRateValue = 0;
                }
                document.getElementById('dateRateValue').innerHTML = newDateRate;
            })
        })
    }


    // ============================================


    /// ============================================
    // ======= Time selection functionality =======
    // ============================================

    var o_0, x1, x2, xm, xM, y1, y2, ym, yM;

    // ---------------------------------------------------------------------------
    // ==== Touch & Mouse ====


    $('#downloadTimes td').on("mousedown tabhold", function (ev) {
        ev.preventDefault();
        this.addEventListener("contextmenu", function (e) {
            e.preventDefault();
        });
        x1 = $(this).attr('data-time') / 30;
        y1 = parseInt($(this).parent().attr('data-y'));
        if ($(this).hasClass('timeSet')) {
            o_0 = true;
        } else {
            o_0 = false;
        }
    }).on("mouseup tab", function () {
        x2 = $(this).attr('data-time') / 30;
        y2 = parseInt($(this).parent().attr('data-y'));
        bigTime(x1, x2, y1, y2);

        function bigTime(x1, x2, y1, y2) {
            if (x2 > x1) {
                xm = x1;
                xM = x2;
            } else if (x2 < x1) {
                xm = x2;
                xM = x1;
            } else if (x2 === x1) {
                xm = x1;
                xM = x1;
            }
            if (y2 > y1) {
                ym = y1;
                yM = y2;
            } else if (y2 < y1) {
                ym = y2;
                yM = y1;
            } else if (y2 === y1) {
                ym = y1;
                yM = y1;
            }
            drawSquare(o_0, xm, xM, ym, yM);
        }

        function drawSquare(o_0, xm, xM, ym, yM) {
            var yI = ym;
            while (yI <= yM) {
                var xI = xm;
                while (xI <= xM) {
                    if (o_0) {
                        $('#downloadTimes').children().children()[yI].cells[xI].className = '';
                    } else {
                        $('#downloadTimes').children().children()[yI].cells[xI].className = 'timeSet';
                    }
                    xI++;
                }
                yI++;
            }
        }
    });

}

/**
 * Login Formular
 * @param {HTMLElement} [content]
 */
function login(content = null) {
    let hideContent = false;
    /*
        check if button exists
     */
    if (content != null) {
        content.style.display = "none";
        hideContent = true;
    }

    /*
        change language of input placeholder
     */
    document.getElementById('auth_password').setAttribute('placeholder', i18next.t('rdsw_dt21'));
    document.getElementById('auth_password_confirm').setAttribute('placeholder', i18next.t('rdsw_dt21'));
    document.getElementById('input_auth_password').setAttribute('placeholder', i18next.t('rdsw_dt21'));

    /*
        show login or create password
     */
    if (passwordSet()) {
        document.getElementById('setPassword').style.display = "none";
        document.getElementById('login').style.display = "table";
    } else {
        document.getElementById('setPassword').style.display = "table";
        document.getElementById('login').style.display = "none";
    }

    $('#BTN-login-submit').unbind().click(function () {
        let passwd = document.getElementById('input_auth_password').value;
        $.ajax({
            /*
                create AccessToken
             */
            contentType: 'application/json',
            dataType: 'json',
            url: api.be8003 + '/config/api/v' + api.version + '/getAccessToken',
            // url: "http://192.168.180.212/config/api/v1/getAccessToken",
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Authorization", "Basic " + btoa("Admin:" + passwd));
            },
            success: function (data) {
                /*
                    AccessToken successfully created
                 */
                console.info("ACCESSTOKEN REQUEST SUCCESS");
                if (validateAccessToken(data.AccessToken)) {
                    /*
                    save accessToken in session
                    */
                    sessionStorage.setItem('accessToken', data.AccessToken);
                    if (hideContent) {
                        /*
                            show content
                         */
                        if(content === document.getElementsByClassName("container confCon")[0])
                            window.location.reload();
                        else
						    content.style.display = "block";
                    }
                    /*
                        hide login
                     */
                    document.getElementById('login').style.display = "none";
                }
            },
            error: function (jqXHR) {
                /*
                    AccessToken creation failed
                 */
                console.log("ACCESSTOKEN REQUEST FAIL");
                let statusCode = jqXHR.status;
                let data = jqXHR.responseJSON;
                if (statusCode === 400) {
                    /*
                        could not extract JSON from request
                     */
                    console.log(data.Error);
                } else if (statusCode === 401) {
                    if (data.Error === "Username or password are unknown." || data.Error === "Username or password was empty.") {
                        /*
                            password wrong
                        */
                        document.getElementById('ErrorCodeLogin').style.display = "block";
                        document.getElementById('ErrorMessageLogin').innerHTML = "Please check the password.";
                        console.log(data.Error)
                    }   else if (data.Error === "The password has expired. Use the AccessToken with setPassword to set a new password.") {
                        /*
                            password expired, create new password
                         */
                        sessionStorage.setItem('accessToken', data.AccessToken);
                        document.getElementById('login').style.display = "none";
                        document.getElementById("setPassword").style.display = "block";
                        document.getElementById('ErrorCode').style.display = "block";
                        document.getElementById('ErrorMessage').innerHTML = "Password expired, please set a new password.";
                        console.log(data.Error);
                    }
                } else if (statusCode === 500) {
                    /*
                        unexpected error
                     */
                    document.getElementById('ErrorCodeLogin').style.display = "block";
                    document.getElementById('ErrorMessageLogin').innerHTML = "Es ist ein interner Fehler aufgetreten.";
                    console.log(data.Error)
                }
            }
        });
    });

    $('#BTN-login-cancel').unbind().click(function () {
        'use strict';
        if (hideContent) {
            /*
                back to ./start
             */
            if(content === document.getElementsByClassName("container confCon")[0])
                window.history.back();
            else
                document.getElementById('login').style.display = "none";
        } else {
            /*
                hide login
             */
            document.getElementById('login').style.display = "none";
        }
    });

    $('#BTN-create-submit').unbind().click(function () {
        let passwd = document.getElementById('auth_password').value;
        let passwdConfirm = document.getElementById('auth_password_confirm').value;
        /*
            create password for user
         */
        let token = window.sessionStorage.getItem("accessToken");
        if (token != null) {
            if (createPassword(passwd, passwdConfirm, token)) {
                /*
                    hide create password, show login
                 */
                document.getElementById('setPassword').style.display = "none";
                document.getElementById('login').style.display = "table";
            }
        } else {
            if (createPassword(passwd, passwdConfirm)) {
                /*
                    hide create password, show login
                 */
                document.getElementById('setPassword').style.display = "none";
                document.getElementById('login').style.display = "table";
            }
        }
    });

    $('#BTN-create-cancel').unbind().click(function () {
        'use strict';
        if (hideContent) {
            /*
                back to ./start
             */
            if(content === document.getElementsByClassName("container confCon")[0])
                window.history.back();
            else
                document.getElementById('setPassword').style.display = "none";
        } else {
            /*
                hide create password
             */
            document.getElementById('setPassword').style.display = "none";
        }
    });

    // handle enter button pressed
    $('#input_auth_password').unbind().keydown(function(event) {
        if (event.keyCode === 13 || event.which === 13  || event.key === "Enter") {
            $('#BTN-login-submit').click();
        }
    });
    $('#auth_password').unbind().keydown(function(event) {
        if (event.keyCode === 13 || event.which === 13 || event.key === "Enter") {
            $('#BTN-create-submit').click();
        }
    });
    $('#auth_password_confirm').unbind().keydown(function(event) {
        if (event.keyCode === 13 || event.which === 13 || event.key === "Enter") {
            $('#BTN-create-submit').click();
        }
    });
}

/**
 * creates a password for the current user
 * @param {string} passwd
 * @param {string} passwdConfirm
 * @param {string} [token]
 * @returns {boolean}
 */
function createPassword(passwd, passwdConfirm, token) {
    let valid = false;
    if (comparePasswords(passwd, passwdConfirm)) {
        /*
            passwords match
         */
        if (checkPassword(passwd)) {
            /*
                password fulfills requirements
             */
            if (token != null) {
                /*
                    user has already a password
                 */
                $.ajax({
                    /*
                        set password
                     */
                    method: 'POST',
                    contentType: 'application/json',
                    async: false,
                    url: api.be8003 + '/config/api/v' + api.version + '/setPassword',
                    // url: "http://192.168.180.212/config/api/v1/setPassword",
                    data: JSON.stringify(({
                        "UserName": "Admin",
                        "NewPassword": passwd
                    })),
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader("Authorization", "Bearer " + token);
                    },
                    success: function () {
                        /*
                            password set
                         */
                        console.info("PASSWORD WITH TOKEN SET SUCCESS");
                        valid = true;
                    },
                    error: function (jqXHR) {
                        /*
                            set password failed
                         */
                        console.log("PASSWORD WITH TOKEN SET ERROR");
                        let statusCode = jqXHR.status;
                        let data = jqXHR.responseJSON;
                        if (statusCode === 400) {
                            if( data.Error === "This password was already used.") {
                                /*
                                    Passwort wurde bereits vor kurzem verwendet
                                 */
                                document.getElementById('ErrorCode').style.display = "block";
                                document.getElementById('ErrorMessage').innerHTML = "Password has been used recently.";
                                console.log(data.Error);
                            } else if (data.Error === "Username or password was empty.") {
                                /*
                                    Benutzername oder Passwort waren leer
                                 */
                                document.getElementById('ErrorCode').style.display = "block";
                                document.getElementById('ErrorMessage').innerHTML = "Password was empty.";
                                console.log(data.Error);
                            } else if (data.Error === "Could not extract JSON from request.") {
                                /*
                                    Die Anfrage konnte nicht gelesen werden
                                 */
                                console.log(data.Error);
                            }
                        } else if (statusCode === 401) {
                            /*
                                 Token ist ungültig
                             */
                            document.getElementById('ErrorCode').style.display = "block";
                            document.getElementById('ErrorMessage').innerHTML = "Token is not valid.";
                            console.log(data.Error);
                        } else if (statusCode === 403) {
                            if( data.Error === "AccessToken must not be empty.") {
                                /*
                                     Admin hat schon ein Passwort
                                 */
                                document.getElementById('ErrorCode').style.display = "block";
                                document.getElementById('ErrorMessage').innerHTML = "Admin already has a password.";
                                console.log(data.Error);
                            } else if (data.Error === "AccessToken is not valid.") {
                                /*
                                     AccessToken ist ungültig oder abgelaufen
                                 */
                                document.getElementById('ErrorCode').style.display = "block";
                                document.getElementById('ErrorMessage').innerHTML = "Token is not valid.";
                                console.log(data.Error);
                            }
                        } else if(statusCode === 500) {
                            /*
                                 unerwarteter Fehler
                             */
                            document.getElementById('ErrorCode').style.display = "block";
                            document.getElementById('ErrorMessage').innerHTML = "An unexpected error occurred.";
                            console.log(data.Error);
                        }
                    }
                });
            } else {
                /*
                    user has no password
                 */
                $.ajax({
                    /*
                        set password
                     */
                    method: 'POST',
                    contentType: 'application/json',
                    async: false,
                    url: api.be8003 + '/config/api/v' + api.version + '/setPassword',
                    // url: "http://192.168.180.212/config/api/v1/setPassword",
                    data: JSON.stringify(({
                        "UserName": "Admin",
                        "NewPassword": passwd
                    })),
                    success: function () {
                        /*
                            password set
                         */
                        console.info("PASSWORD WITHOUT TOKEN SET SUCCESS");
                        valid = true;
                    },
                    error: function (jqXHR) {
                        /*
                            set password failed
                         */
                        console.log("PASSWORD WITHOUT TOKEN SET ERROR");
                        let statusCode = jqXHR.status;
                        let data = jqXHR.responseJSON;
                        if (statusCode === 400) {
                            if( data.Error === "This password was already used.") {
                                /*
                                    Passwort wurde bereits vor kurzem verwendet
                                 */
                                document.getElementById('ErrorCode').style.display = "block";
                                document.getElementById('ErrorMessage').innerHTML = "Password has been used recently.";
                                console.log(data.Error);
                            } else if (data.Error === "Username or password was empty.") {
                                /*
                                    Benutzername oder Passwort waren leer
                                 */
                                document.getElementById('ErrorCode').style.display = "block";
                                document.getElementById('ErrorMessage').innerHTML = "Password was empty.";
                                console.log(data.Error);
                            } else if (data.Error === "Could not extract JSON from request.") {
                                /*
                                    Die Anfrage konnte nicht gelesen werden
                                 */
                                console.log(data.Error);
                            }
                        } else if (statusCode === 401) {
                            /*
                                 Token ist ungültig
                             */
                            document.getElementById('ErrorCode').style.display = "block";
                            document.getElementById('ErrorMessage').innerHTML = "Token is not valid.";
                            console.log(data.Error);
                        } else if (statusCode === 403) {
                            if( data.Error === "AccessToken must not be empty.") {
                                /*
                                     Admin hat schon ein Passwort
                                 */
                                document.getElementById('ErrorCode').style.display = "block";
                                document.getElementById('ErrorMessage').innerHTML = "Admin already has a password.";
                                console.log(data.Error);
                            } else if (data.Error === "AccessToken is not valid.") {
                                /*
                                     AccessToken ist ungültig oder abgelaufen
                                 */
                                document.getElementById('ErrorCode').style.display = "block";
                                document.getElementById('ErrorMessage').innerHTML = "Token is not valid.";
                                console.log(data.Error);
                            }
                        } else if(statusCode === 500) {
                            /*
                                 unerwarteter Fehler
                             */
                            document.getElementById('ErrorCode').style.display = "block";
                            document.getElementById('ErrorMessage').innerHTML = "An unexpected error occurred.";
                            console.log(data.Error);
                        }
                    }
                });
            }
        }
    } else {
        document.getElementById('ErrorCode').style.display = "block";
        document.getElementById('ErrorMessage').innerHTML = "Passwords do not match.";
        console.log("passwords do not match");
    }
    return valid;
}

/**
 * checks if a password is set for the current user
 * @returns {boolean}
 */
function passwordSet() {
    let password = false;
    $.ajax({
        /*
            check for password
         */
        url: api.be8003 + '/config/api/v' + api.version + '/hasPassword',
        // url: 'http://192.168.180.212/config/api/v1/hasPassword',
        async: false,
        data: 'username=Admin',
        success: function () {
            /*
                user has password
             */
            password = true;
            console.log("PASSWORD IS SET");
        },
        error: function () {
            /*
                user has no password
             */
            console.log("PASSWORD IS NOT SET");
        }
    });
    return password;
}

/**
 * changes password for the current user
 */
function changePassword() {
    document.getElementById('setPassword').style.display = "table";

    $('#BTN-create-submit').unbind().click(function () {
        let passwd = document.getElementById('auth_password').value;
        let passwdConfirm = document.getElementById('auth_password_confirm').value;
        /*
            change password for user
         */
        let token = window.sessionStorage.getItem("accessToken");
        if (!validateAccessToken(token)) {
            /*
                no valid accessToken, log in
             */
            login();
        } else {
            if (createPassword(passwd, passwdConfirm, token)) {
                /*
                    new password set, hide change password
                 */
                document.getElementById('setPassword').style.display = "none";
            }
        }
    });

    $('#BTN-create-cancel').unbind().click(function () {
        'use strict';
        /*
            hide change password
         */
        document.getElementById('setPassword').style.display = "none";
    });

}

/**
 * checks if the password matches all required rules
 * @param {string} passwd
 * @returns {boolean|boolean}
 */
function checkPassword(passwd) {
    let big = RegExp("[A-Z]+").test(passwd);							// 1+ uppercase letter
    let small = RegExp("[a-z]+").test(passwd);						// 1+ lowercase letter
    let number = RegExp("[0-9]+").test(passwd);						// 1+ number
    let symbol = RegExp("[-!\"#$%&'()*+,/;:=?_@>.]+").test(passwd);	// 1+ symbol
    let short = passwd.length >= 10;										// length >= 10
    let long = passwd.length <= 25;                                         // length <= 25
    let uid = !RegExp("Admin", "i").test(passwd);               // no part of username, case insensitive
    let umlaut = !RegExp("[ä|ö|ü]", "i").test(passwd);          // no umlaut, case insensitive
    if (big) {
        document.getElementById('big').style.display = "block";
        document.getElementById('notBig').style.display = "none";
    } else {
        document.getElementById('big').style.display = "none";
        document.getElementById('notBig').style.display = "block";
    }
    if (small) {
        document.getElementById('small').style.display = "block";
        document.getElementById('notSmall').style.display = "none";
    } else {
        document.getElementById('small').style.display = "none";
        document.getElementById('notSmall').style.display = "block";
    }
    if (number) {
        document.getElementById('number').style.display = "block";
        document.getElementById('notNumber').style.display = "none";
    } else {
        document.getElementById('number').style.display = "none";
        document.getElementById('notNumber').style.display = "block";
    }
    if (symbol) {
        document.getElementById('symbol').style.display = "block";
        document.getElementById('notSymbol').style.display = "none";
    } else {
        document.getElementById('symbol').style.display = "none";
        document.getElementById('notSymbol').style.display = "block";
    }
    if (short) {
        document.getElementById('short').style.display = "block";
        document.getElementById('notShort').style.display = "none";
    } else {
        document.getElementById('short').style.display = "none";
        document.getElementById('notShort').style.display = "block";
    }
    if (long) {
        document.getElementById('long').style.display = "block";
        document.getElementById('notLong').style.display = "none";
    } else {
        document.getElementById('long').style.display = "none";
        document.getElementById('notLong').style.display = "block";
    }
    if (uid) {
        document.getElementById('uid').style.display = "block";
        document.getElementById('notUid').style.display = "none";
    } else {
        document.getElementById('uid').style.display = "none";
        document.getElementById('notUid').style.display = "block";
    }
    if (umlaut) {
        document.getElementById('umlaut').style.display = "block";
        document.getElementById('notUmlaut').style.display = "none";
    } else {
        document.getElementById('umlaut').style.display = "none";
        document.getElementById('notUmlaut').style.display = "block";
    }
    return big && small && number && symbol && short && long && uid && umlaut;
}

/**
 * checks equality of both strings
 * @param {string} passwd
 * @param {string} passwdConfirm
 * @returns {boolean}
 */
function comparePasswords(passwd, passwdConfirm) {
    if (passwd === passwdConfirm) {
        document.getElementById('pw_match').style.display = 'none';
        return true;
    } else {
        document.getElementById('pw_match').style.display = 'block';
        return false;
    }
}

/**
 * checks the validity of the token
 * @param {string} token
 * @returns {boolean}
 */
function validateAccessToken(token) {
    let valid = false;
    $.ajax({
        /*
            validate AccessToken
         */
        contentType: 'application/json',
        async: false,
        url: api.be8003 + '/config/api/v' + api.version + '/isTokenValid',
        // url: "http://192.168.180.212/config/api/v1/isTokenValid",
        beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + token);
        },
        success: function () {
            /*
                AccessToken is valid
             */
            console.info("ACCESSTOKEN VALID");
            valid = true;
        },
        error: function (jqXHR) {
            /*
                AccessToken is not valid
             */
            console.log("ACCESSTOKEN INVALID");
            let statusCode = jqXHR.status;
            let data = jqXHR.responseJSON;
            if (statusCode === 400) {
                /*
                    Fehler bei der Anfrage
                 */
                console.log(data.Error);
            } else if (statusCode === 403) {
                /*
                    Token nicht gültig
                 */
                console.log(data.Error);
            } else if (statusCode === 500) {
                /*
                    internal error
                 */
                console.log(data.Error);
            }
        }
    });
    return valid;
}

/**
 * Wert des Cookies wird auf "set" gesetzt
 * @param {string} name - Name des Cookies der generiert werden soll
 */
function cookieMaker(name) {
    let value = "set";
    document.cookie = name + "=" + value;
    gloop.devices.start();
}

/**
 * Wert des Cookies wird auf "null" gesetzt
 * @param {string} name - Name des Cookies der geändert werden soll
 */
function cookieKiller(name) {
    let value = "null";
    document.cookie = name + "=" + value;
    gloop.devices.start();
}

/**
 * Gibt den Wert des Cookies zurück
 * @param {string} cname - Name des Cookies dessen Wert ermittelt werden soll
 * @returns {string|boolean}
 */
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false
}

/**
 * Gibt die komplette Länderliste mit allen Übersetzungen zurück
 * @returns {JSON}
 */
function getCountries() {
    let countryData;
    $.ajax({
        accepts: 'application/json; charset=UTF-8',
        dataType: 'json',
        url: 'data/country.json',
        async: false,
        success: function(data){
            'use strict';
            console.log('%c COUNTRIES ','background-color: rgb(50,220,70)','loading success');
            countryData = data;
        },
        error: function(){
            'use strict';
            console.log('%c COUNTRIES ','background-color: rgb(220,70,50)','loading error');
            console.log('%c COUNTRIES ','background-color: rgb(220,220,50)','set to default fallback countries --> EN/DE ');
            countryData = {
                "de": {
                    "translation": {
                        "AF": "Afghanistan",
                        "AX": "Åland",
                        "AL": "Albanien",
                        "DZ": "Algerien",
                        "AS": "Amerikanisch-Samoa",
                        "AD": "Andorra",
                        "AO": "Angola",
                        "AI": "Anguilla",
                        "AQ": "Antarktis",
                        "AG": "Antigua und Barbuda",
                        "AR": "Argentinien",
                        "AM": "Armenien",
                        "AW": "Aruba",
                        "AU": "Australien",
                        "AT": "Österreich",
                        "AZ": "Aserbaidschan",
                        "BS": "Bahamas",
                        "BH": "Bahrain",
                        "BD": "Bangladesh",
                        "BB": "Barbados",
                        "BY": "Weissrussland",
                        "BE": "Belgien",
                        "BZ": "Belize",
                        "BJ": "Benin",
                        "BM": "Bermuda",
                        "BT": "Bhutan",
                        "BO": "Bolivien",
                        "BA": "Bosnien und Herzegowina",
                        "BW": "Botsuana",
                        "BV": "Bouvetinsel",
                        "BR": "Brasilien",
                        "IO": "Britisches Territorium im Indischen Ozean",
                        "VG": "Britische Jungferninseln",
                        "BN": "Brunei Darussal",
                        "BG": "Bulgarien",
                        "BF": "Burkina Faso",
                        "BI": "Burundi",
                        "KH": "Kambodscha",
                        "CM": "Kamerun",
                        "CA": "Kanada",
                        "CV": "Kap Verde",
                        "KY": "Kaimaninseln",
                        "CF": "Zentralafrikanische Republik",
                        "TD": "Tschad",
                        "CL": "Chile",
                        "CN": "China",
                        "CX": "Weihnachtsinsel",
                        "CC": "Kokosinseln (Keelinginseln)",
                        "CO": "Kolumbien",
                        "KM": "Komoren",
                        "CG": "Republik Kongo",
                        "CD": "Kongo",
                        "CK": "Cookinseln",
                        "CR": "Costa Rica",
                        "HR": "Kroatien",
                        "CU": "Kuba",
                        "CY": "Zypern",
                        "CZ": "Tschechien",
                        "DK": "Dänemark",
                        "DJ": "Dschibuti",
                        "DM": "Dominica",
                        "DO": "Dominikanische Republik",
                        "TL": "Timor-Leste",
                        "EC": "Ecuador",
                        "EG": "Ägypten",
                        "SV": "El Salvador",
                        "GQ": "Äquatorialguinea",
                        "ER": "Eritrea",
                        "EE": "Estland",
                        "ET": "Äthiopien",
                        "FK": "Falklandinseln (Malwinen)",
                        "FO": "Färöer",
                        "FJ": "Fidschi",
                        "FI": "Finnland",
                        "FR": "Frankreich",
                        "GF": "Französisch-Guayana",
                        "PF": "Französisch-Polynesien",
                        "TF": "Französische Süd- und Antarktisgebiete",
                        "GA": "Gabun",
                        "GM": "Gambia",
                        "GE": "Georgien",
                        "DE": "Deutschland",
                        "GH": "Ghana",
                        "GI": "Gibraltar",
                        "GR": "Griechenland",
                        "GL": "Grönland",
                        "GD": "Grenada",
                        "GP": "Guadeloupe",
                        "GU": "Guam",
                        "GT": "Guatemala",
                        "GG": "Guernsey (Kanalinsel)",
                        "GN": "Guinea",
                        "GW": "Guinea-Bissau",
                        "GY": "Guyana",
                        "HT": "Haiti",
                        "HM": "Heard- und McDonald-Inseln",
                        "HN": "Honduras",
                        "HK": "Hongkong",
                        "HU": "Ungarn",
                        "IS": "Island",
                        "IN": "Indien",
                        "ID": "Indonesien",
                        "IR": "Iran",
                        "IQ": "Irak",
                        "IE": "Irland",
                        "IM": "Insel Man",
                        "IL": "Israel",
                        "IT": "Italien",
                        "CI": "Elfenbeinküste",
                        "JM": "Jamaika",
                        "JP": "Japan",
                        "JE": "Jersey (Kanalinsel)",
                        "JO": "Jordanien",
                        "KZ": "Kasachstan",
                        "KE": "Kenia",
                        "KI": "Kiribati",
                        "KW": "Kuwait",
                        "KG": "Kirgisistan",
                        "LA": "Laos",
                        "LV": "Lettland",
                        "LB": "Libanon",
                        "LS": "Lesotho",
                        "LR": "Liberia",
                        "LY": "Libyen",
                        "LI": "Liechtenstein",
                        "LT": "Litauen",
                        "LU": "Luxemburg",
                        "MO": "Macao",
                        "MK": "Mazedonien",
                        "MG": "Madagaskar",
                        "MW": "Malawi",
                        "MY": "Malaysia",
                        "MV": "Malediven",
                        "ML": "Mali",
                        "MT": "Malta",
                        "MH": "Marshallinseln",
                        "MQ": "Martinique",
                        "MR": "Mauretanien",
                        "MU": "Mauritius",
                        "YT": "Mayotte",
                        "MX": "Mexiko",
                        "FM": "Mikronesien",
                        "MD": "Moldawien",
                        "MC": "Monaco",
                        "MN": "Mongolei",
                        "MS": "Montserrat",
                        "MA": "Marokko",
                        "MZ": "Mosambik",
                        "MM": "Myanmar (Burma)",
                        "NA": "Namibia",
                        "NR": "Nauru",
                        "NP": "Nepal",
                        "NL": "Niederlande",
                        "AN": "Niederländische Antillen",
                        "NC": "Neukaledonien",
                        "NZ": "Neuseeland",
                        "NI": "Nicaragua",
                        "NE": "Niger",
                        "NG": "Nigeria",
                        "NU": "Niue",
                        "NF": "Norfolkinsel",
                        "MP": "Nördliche Marianen",
                        "KP": "Nordkorea",
                        "NO": "Norwegen",
                        "OM": "Oman",
                        "PK": "Pakistan",
                        "PW": "Palau",
                        "PS": "Palästina",
                        "PA": "Panama",
                        "PG": "Papua-Neuguinea",
                        "PY": "Paraguay",
                        "PE": "Peru",
                        "PH": "Philippinen",
                        "PN": "Pitcairninseln",
                        "PL": "Polen",
                        "PT": "Portugal",
                        "PR": "Puerto Rico",
                        "QA": "Katar",
                        "RE": "Réunion",
                        "RO": "Rumänien",
                        "RU": "Russische Foed.",
                        "RW": "Ruanda",
                        "SH": "St. Helena",
                        "KN": "St. Kitts und Nevis",
                        "LC": "St. Lucia",
                        "PM": "St. Pierre und Miquelon",
                        "VC": "St. Vincent und die Grenadinen",
                        "WS": "Samoa",
                        "SM": "San Marino",
                        "ST": "São Tomé und Príncipe",
                        "SA": "Saudi-Arabien",
                        "SN": "Senegal",
                        "RS": "Serbien",
                        "SC": "Seychellen",
                        "SL": "Sierra Leone",
                        "SG": "Singapur",
                        "SK": "Slowakei",
                        "SI": "Slowenien",
                        "SB": "Salomonen",
                        "SO": "Somalia",
                        "ZA": "Südafrika",
                        "GS": "Südgeorgien und die Südl. Sandwichinseln",
                        "KR": "Südkorea",
                        "ES": "Spanien",
                        "LK": "Sri Lanka",
                        "SD": "Sudan",
                        "SR": "Suriname",
                        "SJ": "Svalbard und Jan Mayen",
                        "SZ": "Swasiland",
                        "SE": "Schweden",
                        "CH": "Schweiz",
                        "SY": "Syrien",
                        "TW": "Taiwan, R.O.C.",
                        "TJ": "Tadschikistan",
                        "TZ": "Tansania",
                        "TH": "Thailand",
                        "TG": "Togo",
                        "TK": "Tokelau",
                        "TO": "Tonga",
                        "TT": "Trinidad,Tobago",
                        "TN": "Tunesien",
                        "TR": "Türkei",
                        "TM": "Turkmenistan",
                        "TC": "Turks- und Caicosinseln",
                        "TV": "Tuvalu",
                        "UG": "Uganda",
                        "UA": "Ukraine",
                        "AE": "Ver.Arab.Emir.",
                        "GB": "Vereinigtes Königreich",
                        "US": "Vereinigte Staaten von Amerika",
                        "GU__1": "Amerikanisch-Ozeanien",
                        "UY": "Uruguay",
                        "VI": "Amerikanische Jungferninseln",
                        "UZ": "Usbekistan",
                        "VU": "Vanuatu",
                        "VA": "Vatikanstadt",
                        "VE": "Venezuela",
                        "VN": "Vietnam",
                        "WF": "Wallis und Futuna",
                        "EH": "Westsahara",
                        "YE": "Jemen",
                        "ZM": "Sambia",
                        "ZW": "Simbabwe"
                    }
                },
                "en": {
                    "AF": "Afghanistan",
                    "AX": "Åland",
                    "AL": "Albania",
                    "DZ": "Algeria",
                    "AS": "American Samoa",
                    "AD": "Andorra",
                    "AO": "Angola",
                    "AI": "Anguilla",
                    "AQ": "Antarctica",
                    "AG": "Antigua and Barbuda",
                    "AR": "Argentina",
                    "AM": "Armenia",
                    "AW": "Aruba",
                    "AU": "Australia",
                    "AT": "Austria",
                    "AZ": "Azerbaijan",
                    "BS": "Bahamas",
                    "BH": "Bahrain",
                    "BD": "Bangladesh",
                    "BB": "Barbados",
                    "BY": "Belarus",
                    "BE": "Belgium",
                    "BZ": "Belize",
                    "BJ": "Benin",
                    "BM": "Bermuda",
                    "BT": "Bhutan",
                    "BO": "Bolivia",
                    "BA": "Bosnia and Herzegovina",
                    "BW": "Botswana",
                    "BV": "Bouvet Island",
                    "BR": "Brazil",
                    "IO": "British Indian Ocean Territory",
                    "VG": "British Virgin Islands",
                    "BN": "Brunei Darussalam",
                    "BG": "Bulgaria",
                    "BF": "Burkina Faso",
                    "BI": "Burundi",
                    "KH": "Cambodia",
                    "CM": "Cameroon",
                    "CA": "Canada",
                    "CV": "Cape Verde",
                    "KY": "Cayman Islands",
                    "CF": "Central African Republic",
                    "TD": "Chad",
                    "CL": "Chile",
                    "CN": "China",
                    "CX": "Christmas Island",
                    "CC": "Cocos Islands (Keeling Islands)",
                    "CO": "Colombia",
                    "KM": "Comoros",
                    "CG": "Republic of the Congo",
                    "CD": "Congo",
                    "CK": "Cook Islands",
                    "CR": "Costa Rica",
                    "HR": "Croatia",
                    "CU": "Cuba",
                    "CY": "Cyprus",
                    "CZ": "Czech Republic",
                    "DK": "Denmark",
                    "DJ": "Djibouti",
                    "DM": "Dominica",
                    "DO": "Dominican Republic",
                    "TL": "Timor-Leste",
                    "EC": "Ecuador",
                    "EG": "Egypt",
                    "SV": "El Salvador",
                    "GQ": "Equatorial Guinea",
                    "ER": "Eritrea",
                    "EE": "Estonia",
                    "ET": "Ethiopia",
                    "FK": "Falkland Islands (Malvinas)",
                    "FO": "Faroe Islands",
                    "FJ": "Fiji",
                    "FI": "Finland",
                    "FR": "France",
                    "GF": "French Guyana",
                    "PF": "French Polynesia",
                    "TF": "French Southern and Antarctic Lands",
                    "GA": "Gabon",
                    "GM": "Gambia",
                    "GE": "Georgia",
                    "DE": "Germany",
                    "GH": "Ghana",
                    "GI": "Gibraltar",
                    "GR": "Greece",
                    "GL": "Greenland",
                    "GD": "Grenada",
                    "GP": "Guadeloupe",
                    "GU": "Guam",
                    "GT": "Guatemala",
                    "GG": "Guernsey (Channel Island)",
                    "GN": "Guinea",
                    "GW": "Guinea-Bissau",
                    "GY": "Guyana",
                    "HT": "Haiti",
                    "HM": "Heard Island and McDonald Islands",
                    "HN": "Honduras",
                    "HK": "Hong Kong",
                    "HU": "Hungary",
                    "IS": "Iceland",
                    "IN": "India",
                    "ID": "Indonesia",
                    "IR": "Iran",
                    "IQ": "Iraq",
                    "IE": "Ireland",
                    "IM": "Isle of Man",
                    "IL": "Israel",
                    "IT": "Italy",
                    "CI": "Ivory Coast",
                    "JM": "Jamaica",
                    "JP": "Japan",
                    "JE": "Jersey (Channel Island)",
                    "JO": "Jordan",
                    "KZ": "Kazakhstan",
                    "KE": "Kenya",
                    "KI": "Kiribati",
                    "KW": "Kuwait",
                    "KG": "Kyrgyzstan",
                    "LA": "Laos",
                    "LV": "Latvia",
                    "LB": "Lebanon",
                    "LS": "Lesotho",
                    "LR": "Liberia",
                    "LY": "Libya",
                    "LI": "Liechtenstein",
                    "LT": "Lithuania",
                    "LU": "Luxembourg",
                    "MO": "Macao",
                    "MK": "Macedonia",
                    "MG": "Madagascar",
                    "MW": "Malawi",
                    "MY": "Malaysia",
                    "MV": "Maldives",
                    "ML": "Mali",
                    "MT": "Malta",
                    "MH": "Marshall Islands",
                    "MQ": "Martinique",
                    "MR": "Mauritania",
                    "MU": "Mauritius",
                    "YT": "Mayotte",
                    "MX": "Mexico",
                    "FM": "Micronesia",
                    "MD": "Moldavia",
                    "MC": "Monaco",
                    "MN": "Mongolia",
                    "MS": "Montserrat",
                    "MA": "Morocco",
                    "MZ": "Mozambique",
                    "MM": "Myanmar (Burma)",
                    "NA": "Namibia",
                    "NR": "Nauru",
                    "NP": "Nepal",
                    "NL": "The Netherlands",
                    "AN": "Netherlands Antilles",
                    "NC": "New Caledonia",
                    "NZ": "New Zealand",
                    "NI": "Nicaragua",
                    "NE": "Niger",
                    "NG": "Nigeria",
                    "NU": "Niue",
                    "NF": "Norfolk Island",
                    "MP": "Northern Mariana Islands",
                    "KP": "North Korea",
                    "NO": "Norway",
                    "OM": "Oman",
                    "PK": "Pakistan",
                    "PW": "Palau",
                    "PS": "Palestine",
                    "PA": "Panama",
                    "PG": "Papua New Guinea",
                    "PY": "Paraguay",
                    "PE": "Peru",
                    "PH": "Philippines",
                    "PN": "Pitcairn Islands",
                    "PL": "Poland",
                    "PT": "Portugal",
                    "PR": "Puerto Rico",
                    "QA": "Qatar",
                    "RE": "Réunion",
                    "RO": "Romania",
                    "RU": "Russian Federation",
                    "RW": "Rwanda",
                    "SH": "St. Helena",
                    "KN": "St. Kitts and Nevis",
                    "LC": "St. Lucia",
                    "PM": "St. Pierre and Miquelon",
                    "VC": "St. Vincent and the Grenadines",
                    "WS": "Samoa",
                    "SM": "San Marino",
                    "ST": "São Tomé and Príncipe",
                    "SA": "Saudi Arabia",
                    "SN": "Senegal",
                    "RS": "Serbia",
                    "SC": "Seychelles",
                    "SL": "Sierra Leone",
                    "SG": "Singapore",
                    "SK": "Slovakia",
                    "SI": "Slovenia",
                    "SB": "Solomon Islands",
                    "SO": "Somalia",
                    "ZA": "South Africa",
                    "GS": "South Georgia and the South Sandwich Islands",
                    "KR": "South Korea",
                    "ES": "Spain",
                    "LK": "Sri Lanka",
                    "SD": "Sudan",
                    "SR": "Suriname",
                    "SJ": "Svalbard and Jan Mayen",
                    "SZ": "Swaziland",
                    "SE": "Sweden",
                    "CH": "Switzerland",
                    "SY": "Syria",
                    "TW": "Taiwan, R.O.C.",
                    "TJ": "Tajikistan",
                    "TZ": "Tanzania",
                    "TH": "Thailand",
                    "TG": "Togo",
                    "TK": "Tokelau",
                    "TO": "Tonga",
                    "TT": "Trinidad and Tobago",
                    "TN": "Tunisia",
                    "TR": "Turkey",
                    "TM": "Turkmenistan",
                    "TC": "Turks and Caicos Islands",
                    "TV": "Tuvalu",
                    "UG": "Uganda",
                    "UA": "Ukraine",
                    "AE": "United Arab Emirates",
                    "GB": "United Kingdom",
                    "US": "United States of America",
                    "GU__1": "United States Oceania",
                    "UY": "Uruguay",
                    "VI": "United States Virgin Islands",
                    "UZ": "Uzbekistan",
                    "VU": "Vanuatu",
                    "VA": "Vatican City",
                    "VE": "Venezuela",
                    "VN": "Vietnam",
                    "WF": "Wallis and Futuna",
                    "EH": "Western Sahara",
                    "YE": "Yemen",
                    "ZM": "Zambia",
                    "ZW": "Zimbabwe"
                }
            };
        },
        complete: function(){
            'use strict';
            console.log('%c COUNTRIES ','background-color: rgb(83,101,254)','loading process completed');
        }
    });

    return countryData
}

/**
 * Sortiert die Länderliste alphabetisch
 */
function sortCountries() {
    var select = $('#country');
    select.html(select.find('option').sort(function(x, y) {
        return $(x).text() > $(y).text() ? 1 : -1;
    }));
    select.get(0).selectedIndex = 0;
}