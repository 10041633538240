//// =========>  Internationalisierung  <=========
//// ===========>                     <===========
//// =============>        T        <=============
//// ===============>      R      <===============
//// =================>    A    <=================
//// ===================>  P  <===================
//// =====================> <=====================
//// =============================================
	//
	//
	//
	// ...---==== TR-anslation AP-pendix ====---...
	// ...---=========> T-R-A-P <============---...

	let trap;
	$.ajax({
		accepts: 'application/json; charset=UTF-8',
		dataType: 'json',
		url: 'data/trap',
		async: false,
		success: function (data) {
			'use strict';
			console.log('%c TRAP ', 'background-color: rgb(50,220,70)', 'loading success');
			trap = data;
		},
		error: function () {
			'use strict';
			console.log('%c TRAP ', 'background-color: rgb(220,70,50)', 'loading error');
			console.log('%c TRAP ', 'background-color: rgb(220,220,50)', 'set to default fallback trap --> EN/DE ');
			trap = {/*> the trap		°=========---...
						 */
				de: {//> the trap		Level -1	°===	german trap
					translation: {//>	German Translation Appendix
						"navbarVersion": "Version: <span class='xentryVersion dynData'></span>",
						"navbarSystemID": "System-ID: <span class='systemId dynData'></span>",
						"navbarDropdown": "Sprache: DE",
						"xuscc_h0": "Übersicht",
						"xuscc_h1": "Monitoring",
						"xuscc_p0": "Status XENTRY Update Service und Retail Data Storage",
						"xuscc_h2": "Gekoppelte Geräte",
						"xuscc_p1": "Übersicht der mit dem Retail Data Storage Verbundenen Geräte",
						"xuscc_h3": "Release-Übersicht",
						"xuscc_p2": "Anzeige der verfügbaren Diagnose-Update-Software",
						"xuscc_h4": "Steuergeräte-Software",
						"xuscc_p3": "Suchen und herunterladen von Steuergeräte- Software-Updates",
						"xuscc_h5": "Administration",
						"xuscc_p4": "Einstellungen des Control Centers",
						"xuscc_h6": "Monitoring",
						"xuscc_p5": "Übersicht des aktuellen  Zustands des XENTRY  Update Service und der  Gekoppelten Geräte.",
						"xuscc_h7": "Systeminformationen  (System-ID: <span  class='systemId'>... lädt  Daten</span>)",
						"xuscc_h8": "Geräte",
						"xuscc_p6": "Seriennummer: <span  class='serialID'>... lädt  Daten</span>",
						"xuscc_p7": "Aktivierungsdatum: <span  class='actiDate'>... lädt  Daten</span>",
						"xuscc_p8": "Garantie Ende: <span  class='warrantyEnd'> … lädt Daten</span>",
						"xuscc_p9": "neuestes Release: <span  class='latestRelease'> … lädt Daten</span>",
						"xuscc_p10": "letzte Aktualisierung:  <span class='lastUpdateIBC'>... lädt  Daten</span>",
						"xuscc_p11": "freier Speicher: <span  class='freeSpace'>... lädt  Daten</span>",
						"xuscc_h9": "Typ",
						"xuscc_h10": "Hardware-ID",
						"xuscc_h11": "Verbindung",
						"xuscc_h12": "Verbindungszustand",
						"xuscc_h13": "Downloads",
						"xuscc_h14": "Domain",
						"xuscc_h15": "Port",
						"xuscc_h16": "Status",
						"xuscc_s0": "Gerätesteuerung und -wartung",
						"xuscc_h17": "Datei",
						"xuscc_h18": "Fortschritt",
						"xuscc_h19": "Gekoppelte Geräte",
						"xuscc_p12": "Dies ist eine Übersicht aller, mit dem Retail Data Storage, gekoppelten Geräte.",
						"xuscc_l0": "Suche",
						"xuscc_i0": "Suchbegriff",
						"xuscc_b0": "Suchen",
						"xuscc_t0": "Produkt",
						"xuscc_t1": "Version",
						"xuscc_t2": "Systemnummer",
						"xuscc_t3": "Status",
						"xuscc_t4": "Zuletzt verbunden",
						"xuscc_t5": "Keine Geräte vorhanden",
						"xuscc_t6": "Bitte koppeln sie ein Gerät um Zugang zu Flashware-Daten zu erhalten",
						"xuscc_h20": "Release-Übersicht",
						"xuscc_p13": "Diese Liste stellt alle  verfügbaren Releases für  Ihre gekoppelten Geräte  dar. Gekoppelte Geräte  können diese Releases  automatisch beziehen, Sie  können ein Release  allerdings auch direkt  herunterladen um ein Gerät  ohne Netzwerkzugriff  Direkt zu versorgen.",
						"xuscc_t7": "Version",
						"xuscc_t8": "Produkt",
						"xuscc_t9": "Fortschritt",
						"xuscc_t10": "Status",
						"xuscc_t11": "Update-Datei",
						"xuscc_t12": "Meta-Datei",
						"xuscc_a0": "Exportieren",
						"xuscc_a1": "Exportieren",
						"xuscc_h21": "Steuergeräte-Software",
						"xuscc_p14": "Dies ist eine Liste aller  vorhandenen  Flashware-Datensätze. <br>Sie ist durchsuchbar  und zeigt den Status jedes  einzelnen Datensatzes an.  Bei Bedarf können  Datensätze hier auch  direkt heruntergeladen  Werden.",
						"xuscc_b1": "Suchen",
						"xuscc_s2": "Einträge insgesamt: ",
						"xuscc_i1": "Suchbegriff (autoSearch ab 3 Zeichen)",
						"xuscc_l1": "Einträge pro Seite",
						"xuscc_li0": "<a class='page-link jsFlashFirst' href='#'>Start</a>",
						"xuscc_s1": "max",
						"xuscc_li1": "<a class='page-link jsFlashLast' href='#'>Ende</a>",
						"xuscc_t13": "Dateiname",
						"xuscc_t14": "Fortschritt",
						"xuscc_t15": "Status",
						"xuscc_t16": "Anfordern",
						"xuscc_t17": "Exportieren",
						"xuscc_t18": "Entfernen",
						"xuscc_a2": "Exportieren",
						"xuscc_h22": "Administration",
						"xuscc_p15": "Sie können das Control  Center hier an Ihre  Bedürfnisse anpassen und  dauerhafte Einstellungen  Setzen.",
						"xuscc_h23": "Release-Download",
						"xuscc_p16": "Steuern Sie das Verhalten  beim Herunterladen von  Releases.<br>Stellen Sie  ein ob aktuelle Releases  geladen werden, obwohl  kein zugehöriges Gerät  gekoppelt ist. Damit sind  Releases beim ersten  Koppeln eines  entsprechenden Gerätes  direkt verfügbar. Alternativ  werden Releases erst  heruntergeladen, sobald  das zugehörige Gerät  Gekoppelt wurde.",
						"xuscc_p17": "Releases für folgende  Geräte automatisch  Vorhalten:",
						"xuscc_b2": "Speichern",
						"xuscc_h24": "Entwickler-Funktionen",
						"xuscc_p18": "Besondere Funktionen für  die Bereiche 'Releases'  Und 'Flashware' aktivieren.",
						"xuscc_l2": "Entwickler-Modus",
						"xuscc_h25": "Startseite",
						"xuscc_p19": "Passen Sie das Control  Center an Ihre  Bedürfnisse an.",
						"xuscc_p20": "Folgenden Reiter beim  Start anzeigen:",
						"xusccl_h0": "Übersicht",
						"xusccl_h1": "Monitoring",
						"xusccl_p0": "Status XENTRY Update  Service",
						"xusccl_h2": "Release-Übersicht",
						"xusccl_p1": "Anzeige der verfügbaren  Diagnose-Update-Software",
						"xusccl_h3": "Steuergeräte-Software",
						"xusccl_p2": "Übersicht der  Steuergeräte-Software- Updates",
						"xusccl_h4": "Monitoring",
						"xusccl_p3": "Übersicht des aktuellen  Zustands des XENTRY  Update Service.",
						"xusccl_h5": "Systeminformationen",
						"xusccl_p4": "Seriennummer: <span  id='light_SN'>... lädt  Daten</span>",
						"xusccl_p41": "Systemnummer: <span  id='light_SyNr'>... lädt  Daten</span>",
						"xusccl_p5": "Flashware Storage: <span  id='freeDisk'>... lädt  Daten</span>",
						"xusccl_d0": "Belegt",
						"xusccl_d1": "Flashware Storage",
						"xusccl_h6": "Verbindungszustand <div class='spinner-border text-info -sm' role='status' style='width: 1.5rem; height: 1.5rem; -webkit-animation: spinner-border 1.77s linear infinite; animation: spinner-border 1.77s linear infinite;'></div>",
						"xusccl_h20": "Release-Übersicht",
						"xusccl_p13": "Diese Liste stellt alle  verfügbaren Releases für  Ihre gekoppelten Geräte  dar. Gekoppelte Geräte  können diese Releases  automatisch beziehen, Sie  können ein Release  allerdings auch direkt  herunterladen um ein Gerät  ohne Netzwerkzugriff  Direkt zu versorgen.",
						"xusccl_t7": "Version",
						"xusccl_t8": "Produkt",
						"xusccl_t9": "Fortschritt",
						"xusccl_t10": "Status",
						"xusccl_t11": "Update-Datei",
						"xusccl_t12": "Meta-Datei",
						"xusccl_a0": "Exportieren",
						"xusccl_h21": "Steuergeräte-Software",
						"xusccl_p140": "Dies ist eine Liste aller  vorhandenen  Flashware-Datensätze. <br>Sie ist durchsuchbar  und zeigt den Status jedes  einzelnen Datensatzes an.  Bei Bedarf können  Datensätze hier auch  direkt heruntergeladen  Werden.",
						"xusccl_p14": "Der verfügbare Speicherplatz nähert sich einem kritischen Wert. Um die Datenversorgung zu gewährleisten, stellen Sie sicher, dass genügend Speicherplatz vorhanden ist.",
						"xusccl_p15": "Nicht genügend Speicherplatz verfügbar. Der Download wird solange gestoppt, bis wieder genügend Speicher verfügbar ist.",
						"xusccl_i1": "Suchbegriff (autoSearch  Ab 3 Zeichen)",
						"xusccl_b1": "Suchen",
						"xusccl_s2": "Einträge insgesamt: ",
						"xusccl_l1": "Einträge pro Seite",
						"xusccl_li0": "<a class='page-link  jsFlashFirst' href='#'> Start</a>",
						"xusccl_s1": "<span id='flashPageMax'>max</span>",
						"xusccl_li1": "<a class='page-link  jsFlashLast' href='#'> Ende</a>",
						"xusccl_t13": "Dateiname",
						"xusccl_t14": "Fortschritt",
						"xusccl_t15": "Status",
						"xusccl_t16": "Anfordern",
						"xusccl_t17": "Exportieren",
						"xusccl_t18": "Entfernen",
						"xusccl_a1": "Anfordern",
						"xusccl_a2": "Exportieren",
						"rdsc_h0": "Über das Gerät",
						"rdsc_p0": "Hier finden Sie Informationen und Einstellmöglichkeiten des Retail Data Storage.",
						"rdsc_h1": "Systeminformationen",
						"rdsc_p1": "System-ID: <span class='systemId'></span>",
						"rdsc_p2": "Aktivierungsdatum: <span class='activationDate'></span>",
						"rdsc_p3": "Seriennummer: <span class='serialId'></span>",
						"rdsc_p4": "MAC-Adresse: <span class='macAddress'></span>",
						"rdsc_h2": "Handbuch und Unterstützung",
						"rdsc_p5": "Detaillierte Informationen zum Retail Data Storage finden Sie im Handbuch. Bei Fragen oder Problemen kontaktieren Sie bitte Ihren User-Help-Desk.",
						"rdsc_b0": "Handbuch öffnen",
						"rdsc_p6": "Sie finden diese Informationen auch im XENTRY Portal unter der Adresse: <a href='https://xentry.daimler.com' target='_blank'>xentry.daimler.com</a>",
						"rdsc_h3": "Downloadverhalten",
						"rdsc_p7": "Legen Sie die Datenrate und Verbindungszeiten des Retail Data Storage fest.",
						"rdsc_l0": "Datenübertragungsrate (<p id='dateRateValue' style='display: inline-block'></p>)",
						"rdsc_p8": "Inaktiv <span class='bg-light markerTime'></span>",
						"rdsc_p9": "Aktiv <span class='bg-primary markerTime'></span>",
						"rdsc_p10": "Um den Betrieb tagsüber nicht zu beeinflussen, kann die Datenübertragung des Retail Data Storage in diesen Zeiten pausiert werden.",
						"rdsc_t0": "Montag",
						"rdsc_t1": "Dienstag",
						"rdsc_t2": "Mittwoch",
						"rdsc_t3": "Donnerstag",
						"rdsc_t4": "Freitag",
						"rdsc_t5": "Samstag",
						"rdsc_t6": "Sonntag",
						"rdsc_b1": "Tabelle lädt - Bitte warten Sie einen Moment.",
						"rdsc_b11": "Einstellungen übernehmen",
						"rdss_h0": "Global Training",
						"rdss_p0": "Entdecken Sie das Global Training mit passenden Kurs- und Trainingsangeboten, individuell auf Ihre Bedürfnisse zugeschnitten und von überall abrufbar.",
						"rdss_a0": "Weiter",
						"rdss_h1": "XENTRY Update Service Control Center",
						"rdss_p1": "Das Control Center ist die zentrale Anlaufstelle zur Anpassung von Konfigurationsparametern, Anzeige von verfügbarer Diagnose-Software und zum Monitoring der Verbindungen.",
						"rdss_a1": "Weiter",
						"rdss_h2": "Über das Gerät",
						"rdss_p2": "Ändern Sie grundlegende Einstellungen des Gerätes oder prüfen Sie den Status.",
						"rdss_a2": "Weiter",
						"rdsw_h0": "Status",
						"rdsw_p0": "Hier werden alle relevanten Werte Ihres Gerätes angezeigt.",
						"rdsw_p1": "Zum Ändern der Daten starten Sie die Einrichtung erneut.",
						"rdsw_h1": "System",
						"rdsw_s0": "Status",
						"rdsw_s1": "System-ID",
						"rdsw_s2": "MAC-Adresse",
						"rdsw_s3": "Hostname",
						"rdsw_s4": "IP-Adresse",
						"rdsw_s5": "Gateway",
						"rdsw_s6": "DNS",
						"rdsw_h2": "Verbindung",
						"rdsw_s7": "VPN",
						"rdsw_s8": "XENTRY",
						"rdsw_s9": "XENTRY INT",
						"rdsw_s10": "CDN",
						"rdsw_h3": "Proxyserver",
						"rdsw_s11": "Proxy",
						"rdsw_s12": "IP-Adresse",
						"rdsw_s13": "Port",
						"rdsw_s14": "Authentifizierung",
						"rdsw_s15": "Benutzername",
						"rdsw_s16": "Passwort",
						"rdsw_h4": "Einrichtungsassistent",
						"rdsw_p2": "Der Einrichtungsassistent unterstützt Sie bei der Einrichtung und Aktivierung Ihres Retail Data Storage.<br>Folgende Informationen sind dafür notwendig:",
						"rdsw_li0": "Kontaktdaten",
						"rdsw_li1": "Angaben zum Netzwerk",
						"rdsw_li2": "Angaben zum Proxyserver",
						"rdsw_li3": "Einstellungen zur Datenübertragung",
						"rdsw_h5": "Datenschutz",
						"rdsw_p3": "Die erfolgreiche Aktivierung Ihres RDS wird über die angegebene E-Mailadresse bestätigt.<br>Die weiteren Kontaktdaten werden benötigt, um bei auftretenden Störungen mit Ihnen in Kontakt zu treten.",
						"rdsw_p4": "Bitte bestätigen Sie hierfür die <a href='/art/Privacy_Policy_de.pdf' target='_blank'>Datenschutzerklärung der Mercedes-Benz AG</a>.",
						"rdsw_l0": "Ich stimme der Datenschutzerklärung zu",
						"rdsw_h6": "Kontakt",
						"rdsw_p5": "Bitte geben Sie Ihre Kontaktdaten an.",
						"rdsw_l1": "Firma",
						"rdsw_l2": "Betriebsnummer",
						"rdsw_l3": "Straße",
						"rdsw_l4": "PLZ",
						"rdsw_l5": "Ort",
						"rdsw_l6": "Land",
						"rdsw_o0": "Deutschland",
						"rdsw_o1": "USA",
						"rdsw_o2": "China",
						"rdsw_sm3": "Das Land ist eine Pflichtangabe",
						"rdsw_l7": "Anrede",
						"rdsw_o3": "Herr",
						"rdsw_o4": "Frau",
						"rdsw_o5": "Divers",
						"rdsw_l8": "Vorname",
						"rdsw_l9": "Name",
						"rdsw_l10": "E-Mail",
						"rdsw_sm0": "Die E-Mail Adresse ist eine Pflichtangabe",
						"rdsw_l11": "Telefon",
						"rdsw_p6": "Es müssen mindestens eine gültige E-Mail Adresse und das Land angegeben werden.",
						"rdsw_h7": "Netzwerk",
						"rdsw_p7": "Legen Sie nun die Konfiguration für Ihr Netzwerk fest. Bitte achten Sie darauf, korrekte Werte anzugeben.",
						"rdsw_l12": "Hostname",
						"rdsw_sm1": "Der Hostname ist eine Pflichtangabe",
						"rdsw_l27": "DHCP",
						"rdsw_l13": "DHCP",
						"rdsw_l14": "IP-Adresse",
						"rdsw_l15": "Netzmaske",
						"rdsw_l16": "Gateway",
						"rdsw_le0": "DNS Einträge",
						"rdsw_l17": "Haupt DNS:",
						"rdsw_h8": "Proxyserver",
						"rdsw_p8": "Falls ein Proxy Server genutzt wird, geben Sie hier bitte alle notwendigen Daten an.",
						"rdsw_l18": "Proxy",
						"rdsw_l19": "Proxy",
						"rdsw_l20": "Authentifizierung",
						"rdsw_l21": "Authentifizierung",
						"rdsw_l22": "IP-Adresse/FQDN",
						"rdsw_l23": "Port",
						"rdsw_l24": "Benutzername",
						"rdsw_l25": "Password",
						"rdsw_sm2": "Bitte nur alphanumerische Zeichen verwenden!",
						"rdsw_l26": "Wiederholung",
						"rdsw_h9": "Neustart",
						"rdsw_d0": "Zurück",
						"rdsw_d1": "Datenschutz",
						"Start-tab": "Kontakt",
						"contact-tab": "Netzwerk",
						"network-tab": "Proxyserver",
						"proxy-tab": "Downloadverhalten",
						"download-tab": "Zusammenfassung",
						"summary-tab": "Neustart",
						"reboot-tab": "Weiter",
						"rdsw_h10": "Downloadverhalten",
						"rdsw_p9": "Legen Sie die Datenrate und Verbindungszeiten des Retail Data Storage fest.",
						"rdsw_l28": "Zeitzone",
						"rdsw_l29": "Datenübertragungsrate: <p id='dateRateValue' style='display: inline-block'></p>",
						"rdsw_p10": "Inaktiv",
						"rdsw_p11": "Aktiv",
						"rdsw_t0": "Montag",
						"rdsw_t1": "Dienstag",
						"rdsw_t2": "Mittwoch",
						"rdsw_t3": "Donnerstag",
						"rdsw_t4": "Freitag",
						"rdsw_t5": "Samstag",
						"rdsw_t6": "Sonntag",
						"rdsw_p12": "Um den Betrieb tagsüber nicht zu beeinflussen, kann die Datenübertragung des Retail Data Storage in diesen Zeiten pausiert werden.",
						"rdsw_h11": "Zusammenfassung",
						"rdsw_p13": "Prüfen Sie nun Ihre Angaben und wenden Sie dann die Konfiguration an.",
						"rdsw_h12": "Kontakt",
						"rdsw_h13": "Netzwerk",
						"rdsw_h14": "Proxyserver",
						"rdsw_h15": "Downloadverhalten",
						"rdsw_dt0": "Firma",
						"rdsw_dt1": "Betriebsnr.",
						"rdsw_dt2": "Straße",
						"rdsw_dt3": "PLZ",
						"rdsw_dt4": "Ort",
						"rdsw_dt5": "Land",
						"rdsw_dt6": "Anrede",
						"rdsw_dt7": "Person",
						"rdsw_dt8": "E-Mail",
						"rdsw_dt9": "Telefon",
						"rdsw_dt10": "Hostname",
						"rdsw_dt11": "DHCP",
						"rdsw_dt12": "IP-Adresse",
						"rdsw_dt13": "Netzmaske",
						"rdsw_dt14": "Gateway",
						"rdsw_dt15": "DNS",
						"rdsw_dt16": "Proxy",
						"rdsw_dt17": "IP-Adresse",
						"rdsw_dt18": "Port",
						"rdsw_dt19": "Authentifizierung",
						"rdsw_dt20": "Benutzername",
						"rdsw_dt21": "Passwort",
						"rdsw_dt22": "Zeitzone",
						"rdsw_dt23": "Datenübertragungsrate",
						"rdsa_h0": "Aktivierungsassistent",
						"rdsa_h1": "Abschluss der Einrichtung",
						"rdsa_p0": "Der Retail Data Storage ist erfolgreich für die Verwendung in Ihrem Netzwerk konfiguriert und kann nun aktiviert werden. Eine Aktivierung kann bis zu einem Werktag dauern. Sobald die Aktivierung abgeschlossen ist, werden Sie über die angegebene E-Mail-Adresse benachrichtigt.",
						"rdsa_p1": "Bitte lassen Sie den Retail Data Storage eingeschaltet bis Sie über die erfolgreiche Aktivierung informiert wurden.",
						"rdsa_h2": "Bitte geben Sie Ihren Aktivierungsschlüssel ein.",
						"rdsa_l0": "Schlüssel",
						"rdsa_l1": "Wiederholung",
						"rdsa_b0": "Absenden",
						"rdsa_p3": "Fehler bei der Aktivierung!",
						"rdsa_h3": "Netzwerkfehler",
						"rdsa_p4": "Der Retail Data Storage ist zwar bereits konfiguriert, es besteht jedoch keine Verbindung zum Server.<br>Die Aktivierung kann nicht durchgeführt werden.",
						"rdsa_bold": "Bitte prüfen Sie die Netzwerkkonfiguration, führen Sie den Einrichtungsassistenten erneut aus oder wenden Sie sich an <a href='mailto:xentry.diagnosis.support@daimler.com'>xentry.diagnosis.support@daimler.com</a> bzw. an Ihre Landesvertretung.",
						"rdsa_h4": "Die Einrichtung ist abgeschlossen!",
						"rdsa_p5": "Ihr Aktivierungsschlüssel wurde erfolgreich übermittelt.<br>Der Retail Data Storage wird nun innerhalb des nächsten Werktages aktiviert und ist dann betriebsbereit.",
						"rdsa_p6": "Der Retail Data Storage befindet sich zur Zeit im Aktivierungsprozess. Eine Aktivierung kann bis zu einem Werktag dauern. Sobald die Aktivierung abgeschlossen ist, werden Sie über die angegebene E-Mail-Adresse informiert.",
						"rdsa_bolder": "Der Aktivierungsschlüssel wurde bereits eingegeben und akzeptiert.<br/>Bitte lassen Sie den Retail Data Storage eingeschaltet bis Sie über die erfolgreiche Aktivierung informiert wurden.",
						"rdsa_p7": "Der Aktivierungsschlüssel wurde bereits eingegeben und akzeptiert. Die Einrichtung wurde vor mehr als 48 Stunden durchgeführt und eine Aktivierung ist bereits überfällig. Bitte wenden Sie sich an <a href='mailto:xentry.diagnosis.support@daimler.com'>xentry.diagnosis.support@daimler.com</a> bzw. an Ihre Landesvertretung.",
						"rdsa_h5": "Die Aktivierung des Retail Data Storage ist überfällig.",
						"rdsa_p2": "Falls eine Aktivierung nicht möglich ist oder Sie Hilfe benötigen, wenden Sie sich bitte an <a href='mailto:xentry.diagnosis.support@daimler.com'>xentry.diagnosis.support@daimler.com</a> oder an Ihre Landesvertretung."
					}
					/*.........\\_______|>--END_OF_THIS_TRAP--<|_______________//.............
					*/
				},
				en: {//> the trap		Level -1	°===	english trap
					translation: {//>	English Translation Appendix
						"navbarVersion": "Version: <span class='xentryVersion dynData'></span>",
						"navbarSystemID": "System-ID: <span class='systemId dynData'></span>",
						"navbarDropdown": "Language: EN",
						"xuscc_h0": "Overview",
						"xuscc_h1": "Monitoring",
						"xuscc_p0": "Status XENTRY Update Service and Retail Data Storage",
						"xuscc_h2": "linked up devices",
						"xuscc_p1": "Overview of the devices connected to the Retail Data Storage",
						"xuscc_h3": "Releases",
						"xuscc_p2": "List of available diagnosis Update software",
						"xuscc_h4": "Control-Unit-Software",
						"xuscc_p3": "Search and download of Control-unit-software",
						"xuscc_h5": "Administration",
						"xuscc_p4": "Settings of the Control Center",
						"xuscc_h6": "Monitoring",
						"xuscc_p5": "Overview over the status of The XENTRY Update Service und over the Connected devices",
						"xuscc_h7": "System information  (System-ID: <span  class='systemId'>...  Loading data</span>)",
						"xuscc_h8": "Devices",
						"xuscc_p6": "Serial number: <span  class='serialID'>... loading  data</span>",
						"xuscc_p7": "Activation date: <span  class='actiDate'>... loading  data</span>",
						"xuscc_p8": "Warranty end: <span  class='warrantyEnd'> … loading data</span>",
						"xuscc_p9": "Latest release: <span  class='latestRelease'> … loading data</span>",
						"xuscc_p10": "Latest update:  <span class='lastUpdateIBC'>... loading  data</span>",
						"xuscc_p11": "Free storage: <span  class='freeSpace'>...  Loading data</span>",
						"xuscc_h9": "Type",
						"xuscc_h10": "Hardware-ID",
						"xuscc_h11": "Connection",
						"xuscc_h12": "Connection state",
						"xuscc_h13": "Downloads",
						"xuscc_h14": "Domain",
						"xuscc_h15": "Port",
						"xuscc_h16": "Status",
						"xuscc_s0": "Device control and maintenance",
						"xuscc_h17": "File",
						"xuscc_h18": "Progress",
						"xuscc_h19": "linked up devices",
						"xuscc_p12": "This is an overview over all Devices that are linked up to the Retail Data Storage",
						"xuscc_l0": "Searching",
						"xuscc_i0": "Searchterm",
						"xuscc_b0": "Search",
						"xuscc_t0": "Product",
						"xuscc_t1": "Version",
						"xuscc_t2": "System number",
						"xuscc_t3": "Status",
						"xuscc_t4": "Last time connected",
						"xuscc_t5": "No existing devices",
						"xuscc_t6": "Please pair a device to get access to Flashware data",
						"xuscc_h20": "Releases",
						"xuscc_p13": "Here all releases for your linked up devices are  listed. Every linked up  device can download releases automatically.  You can also download releases manually to provide them for a device without network connection.",
						"xuscc_t7": "Version",
						"xuscc_t8": "Product",
						"xuscc_t9": "Progress",
						"xuscc_t10": "Status",
						"xuscc_t11": "Update-file",
						"xuscc_t12": "Meta-file",
						"xuscc_a0": "Export",
						"xuscc_a1": "Export",
						"xuscc_h21": "Control-Unit-Software",
						"xuscc_p14": "Here all flashwares are  listed.<br>You can search the flashware database And if you want to, you can download needed flashware here directly.",
						"xuscc_b1": "Search",
						"xuscc_s2": "Total entries: ",
						"xuscc_i1": "Searchterm (autoSearch from 3 characters)",
						"xuscc_l1": "Entries per page",
						"xuscc_li0": "<a class='page-link jsFlashFirst' href='#'>Start</a>",
						"xuscc_s1": "max",
						"xuscc_li1": "<a class='page-link jsFlashLast' href='#'>End</a>",
						"xuscc_t13": "File name",
						"xuscc_t14": "Progress",
						"xuscc_t15": "Status",
						"xuscc_t16": "Request",
						"xuscc_t17": "Export",
						"xuscc_t18": "Delete",
						"xuscc_a2": "Export",
						"xuscc_h22": "Administration",
						"xuscc_p15": "Here you can customize the Control Center to your needs and set permanent settings.",
						"xuscc_h23": "Release-Download",
						"xuscc_p16": "Control the behavior when downloading releases. Set whether current releases are loaded even though no associated device is linked up. This releases are directly available when you first link up a corresponding device. Alternatively, releases will not be downloaded until the associated device has been linked up.",
						"xuscc_p17": "Maintain releases for the following devices automatically. ",
						"xuscc_b2": "Save",
						"xuscc_h24": "Developer-Tool",
						"xuscc_p18": "Enable special functions for the 'Releases' and 'Flashware' sections.",
						"xuscc_l2": "Developer-Mode",
						"xuscc_h25": "Start page",
						"xuscc_p19": "Customize the Control Center to your needs.",
						"xuscc_p20": "Show the following tab at start:",
						"xusccl_h0": "Overview",
						"xusccl_h1": "Monitoring",
						"xusccl_p0": "Status XENTRY Update  Service and Retail Data  Storage",
						"xusccl_h2": "Releases",
						"xusccl_p1": "List of available diagnosis  Update software",
						"xusccl_h3": "Control-Unit-Software",
						"xusccl_p2": "Search and download of Control-unit-software",
						"xusccl_h4": "Monitoring",
						"xusccl_p3": "Overview over the status of The XENTRY Update  Service",
						"xusccl_h5": "Systeminformation",
						"xusccl_p4": "Serial number: <span  id='light_SN'>... lädt  Daten</span>",
						"xusccl_p41": "Serial number: <span  id='light_SyNr'>... loading  data</span>",
						"xusccl_p5": "Flashware Storage: <span  id='freeDisk'>... lädt  Daten</span>",
						"xusccl_d0": "Used",
						"xusccl_d1": "Flashware Storage",
						"xusccl_h6": "Connection state <div class='spinner-border text-info -sm' role='status' style='width: 1.5rem; height: 1.5rem; -webkit-animation: spinner-border 1.77s linear infinite; animation: spinner-border 1.77s linear infinite;'></div>",
						"xusccl_h20": "Releases",
						"xusccl_p13": "Here all releases for your linked up devices are  listed. Every linked up  device can download releases automatically.  You can also download releases manually to provide them for a device without network connection.",
						"xusccl_t7": "Version",
						"xusccl_t8": "Product",
						"xusccl_t9": "Progress",
						"xusccl_t10": "Status",
						"xusccl_t11": "Update-file",
						"xusccl_t12": "Meta-file",
						"xusccl_a0": "Export",
						"xusccl_h21": "Control-Unit-Software",
						"xusccl_p140": "Here all flashwares are  listed.<br>You can search the flashware database And if you want to, you can download needed flashware here directly.",
						"xusccl_p14": "The available space is approaching a critical value. To ensure the data supply, make sure that enough space is available.",
						"xusccl_p15": "The space reserved for X has been undershot. This can lead to the required data such as releases or Flashware can not be downloaded. Please free up space.",
						"xusccl_i1": "Searchterm (autoSearch  From 3 characters)",
						"xusccl_b1": "Search",
						"xusccl_s2": "Total entries: ",
						"xusccl_l1": "Entries per page",
						"xusccl_li0": "<a class='page-link  jsFlashFirst' href='#'> Start</a>",
						"xusccl_s1": "<span id='flashPageMax'>max</span>",
						"xusccl_li1": "<a class='page-link  jsFlashLast' href='#'> End</a>",
						"xusccl_t13": "File name",
						"xusccl_t14": "Progress",
						"xusccl_t15": "Status",
						"xusccl_t16": "Request",
						"xusccl_t17": "Export",
						"xusccl_t18": "Delete",
						"xusccl_a1": "Request",
						"xusccl_a2": "Export",
						"rdsc_h0": "About the device",
						"rdsc_p0": "Here you find information and setting options for the Retail Data Storage.",
						"rdsc_h1": "System information",
						"rdsc_p1": "System-ID: <span class='systemId'></span>",
						"rdsc_p2": "Activation date: <span class='activationDate'></span>",
						"rdsc_p3": "Serial number: <span class='serialId'></span>",
						"rdsc_p4": "MAC adress: <span class='macAddress'></span>",
						"rdsc_h2": "Manual and support",
						"rdsc_p5": "Detailed information on the retail data storage can be found in the manual. If you have questions or problems, please contact your User-Help-Desk.",
						"rdsc_b0": "Open the manual",
						"rdsc_p6": "You can also find this information on the XENTRY portal via: <a href='https://xentry.daimler.com' target='_blank'>xentry.daimler.com</a>",
						"rdsc_h3": "Download behavior",
						"rdsc_p7": "Set the data rate and connection times of the Retail Data Storage.",
						"rdsc_l0": "Date rate (<p id='dateRateValue' style='display: inline-block'></p>)",
						"rdsc_p8": "Inaktive <span class='bg-light markerTime'></span>",
						"rdsc_p9": "Aktive <span class='bg-primary markerTime'></span>",
						"rdsc_p10": "In order to not influence the workshop operation during the day, the date transfer of the Retail Data Storage can be paused during these times.",
						"rdsc_t0": "Monday",
						"rdsc_t1": "Tuesday",
						"rdsc_t2": "Wednesday",
						"rdsc_t3": "Thursday",
						"rdsc_t4": "Friday",
						"rdsc_t5": "Saturday",
						"rdsc_t6": "Sunday",
						"rdsc_b1": "Table is loading - please wait a moment.",
						"rdsc_b11": "Use settings",
						"rdss_h0": "Global Training",
						"rdss_p0": "Discover the Global Training with appropriate course and training offers, individually tailored to your needs and accessible from anywhere.",
						"rdss_a0": "Continue",
						"rdss_h1": "XENTRY Update Service Control Center",
						"rdss_p1": "The Control Center is the central page for adjusting configuration parameters, displaying available diagnostics software, and monitoring connections.",
						"rdss_a1": "Continue",
						"rdss_h2": "About the device",
						"rdss_p2": "Change the basic settings of the device or check the status.",
						"rdss_a2": "Continue",
						"rdsw_h0": "Status",
						"rdsw_p0": "Here all relevant values of your device are displayed.",
						"rdsw_p1": "To change the data, start the setup again.",
						"rdsw_h1": "System",
						"rdsw_s0": "Status",
						"rdsw_s1": "System-ID",
						"rdsw_s2": "MAC adress",
						"rdsw_s3": "Host",
						"rdsw_s4": "IP adress",
						"rdsw_s5": "Gateway",
						"rdsw_s6": "DNS",
						"rdsw_h2": "Connection",
						"rdsw_s7": "VPN",
						"rdsw_s8": "XENTRY",
						"rdsw_s9": "XENTRY INT",
						"rdsw_s10": "CDN",
						"rdsw_h3": "Proxyserver",
						"rdsw_s11": "Proxy",
						"rdsw_s12": "IP adress",
						"rdsw_s13": "Port",
						"rdsw_s14": "Authentication",
						"rdsw_s15": "Username",
						"rdsw_s16": "Password",
						"rdsw_h4": "Setup Wizard",
						"rdsw_p2": "The setup wizard helps you to set up and activate your retail data storage. The following information are necessary for this:",
						"rdsw_li0": "contact details",
						"rdsw_li1": "Details about the network",
						"rdsw_li2": "Details about the proxy server",
						"rdsw_li3": "Settings for data transmission",
						"rdsw_h5": "data protection",
						"rdsw_p3": "The successful activation of your RDS will be confirmed via the specified e-mail address.<br> Further contact details are required in order to contact you in the event of disruptions.",
						"rdsw_p4": "Please confirm the Daimler privacy policy",
						"rdsw_l0": "I agree to the privacy policy",
						"rdsw_h6": "Contact",
						"rdsw_p5": "Please enter your contact details.",
						"rdsw_l1": "Company",
						"rdsw_l2": "Operation number",
						"rdsw_l3": "Street",
						"rdsw_l4": "Postcode",
						"rdsw_l5": "Place",
						"rdsw_l6": "Country",
						"rdsw_o0": "Germany",
						"rdsw_o1": "United States of America",
						"rdsw_o2": "PRC",
						"rdsw_sm3": "The country is mandatory",
						"rdsw_l7": "salutation",
						"rdsw_o3": "Mr.",
						"rdsw_o4": "Mrs.",
						"rdsw_o5": "Divers",
						"rdsw_l8": "First name",
						"rdsw_l9": "Last Name",
						"rdsw_l10": "E-Mail",
						"rdsw_sm0": "The E-Mail is mandatory",
						"rdsw_l11": "Phone",
						"rdsw_p6": "At least one valid e-mail address and the country must be indicated.",
						"rdsw_h7": "Network",
						"rdsw_p7": "Set the configuration for your network. Please be sure to specify correct values.",
						"rdsw_l12": "Host",
						"rdsw_sm1": "The Host is mandatory",
						"rdsw_l27": "DHCP",
						"rdsw_l13": "DHCP",
						"rdsw_l14": "IP adress",
						"rdsw_l15": "Netmask",
						"rdsw_l16": "Gateway",
						"rdsw_le0": "DNS entries",
						"rdsw_l17": "Main DNS:",
						"rdsw_h8": "Proxy server",
						"rdsw_p8": "If a proxy server is used, please enter all necessary data here.",
						"rdsw_l18": "Proxy",
						"rdsw_l19": "Proxy",
						"rdsw_l20": "Authentication",
						"rdsw_l21": "Authentication",
						"rdsw_l22": "IP adress/FQDN",
						"rdsw_l23": "Port",
						"rdsw_l24": "Username",
						"rdsw_l25": "Password",
						"rdsw_sm2": "Please only use alphanumeric characters!",
						"rdsw_l26": "Repeat",
						"rdsw_h9": "Restart",
						"rdsw_d0": "Back",
						"rdsw_d1": "Next",
						"Start-tab": "data protection",
						"contact-tab": "Contact",
						"network-tab": "Network",
						"proxy-tab": "Proxy server",
						"download-tab": "Download behavior",
						"summary-tab": "Summary",
						"reboot-tab": "Restart",
						"rdsw_h10": "Download behavior",
						"rdsw_p9": "Set the data rate and connection times for the Retail Data Storage.",
						"rdsw_l28": "Timezone",
						"rdsw_l29": "date rate: <p id='dateRateValue' style='display: inline-block'></p>",
						"rdsw_p10": "Inactive",
						"rdsw_p11": "Active",
						"rdsw_t0": "Monday",
						"rdsw_t1": "Tuesday",
						"rdsw_t2": "Wednesday",
						"rdsw_t3": "Thursday",
						"rdsw_t4": "Friday",
						"rdsw_t5": "Saturday",
						"rdsw_t6": "Sunday",
						"rdsw_p12": "In order to not influence the workshop operation during the day, the date transfer of the Retail Data Storage can be paused during these times.",
						"rdsw_h11": "Summary",
						"rdsw_p13": "Please check your details and then apply the configuration.",
						"rdsw_h12": "Contact",
						"rdsw_h13": "Network",
						"rdsw_h14": "Proxy server",
						"rdsw_h15": "Download behavior",
						"rdsw_dt0": "Company",
						"rdsw_dt1": "Operation number",
						"rdsw_dt2": "Street",
						"rdsw_dt3": "Postcode",
						"rdsw_dt4": "Place",
						"rdsw_dt5": "Country",
						"rdsw_dt6": "salutation",
						"rdsw_dt7": "Person",
						"rdsw_dt8": "E-Mail",
						"rdsw_dt9": "Phone",
						"rdsw_dt10": "Host",
						"rdsw_dt11": "DHCP",
						"rdsw_dt12": "IP adress",
						"rdsw_dt13": "Netmask",
						"rdsw_dt14": "Gateway",
						"rdsw_dt15": "DNS",
						"rdsw_dt16": "Proxy",
						"rdsw_dt17": "IP adress",
						"rdsw_dt18": "Port",
						"rdsw_dt19": "Authentication",
						"rdsw_dt20": "Username",
						"rdsw_dt21": "Password",
						"rdsw_dt22": "Timezone",
						"rdsw_dt23": "date rate",
						"rdsa_h0": "Activation Wizard",
						"rdsa_h1": "Completion of the setup",
						"rdsa_p0": "The Retail Data Storage has been successfully configured for use in your network and can now be activated. Activation can take up to one working day. When activation is completed, you will be notified via the specified e-mail address.",
						"rdsa_p1": "Please leave the Retail Data Storage switched on until you have been notified of the successful activation.",
						"rdsa_h2": "Please enter your activation code.",
						"rdsa_l0": "Code",
						"rdsa_l1": "Repeat",
						"rdsa_b0": "Send",
						"rdsa_p3": "Error during activation!",
						"rdsa_h3": "Network error",
						"rdsa_p4": "Although the Retail Data Storage is already configured, there is no connection to the server.<br>Activation cannot be performed.",
						"rdsa_bold": "Please check your network configuration, run the Setup Wizard again or contact <a href='mailto:xentry.diagnosis.support@daimler.com'>xentry.diagnosis.support@daimler.com</a> or your national representative.",
						"rdsa_h4": "Setup is complete!",
						"rdsa_p5": "Your activation code has been sent successfully.<br>The Retail Data Storage unit will now be activated within the next working day and will then be operational.",
						"rdsa_p6": "The Retail Data Storage is currently in the activation process. Activation can take up to one working day. When activation is completed, you will be informed via the specified e-mail address.",
						"rdsa_bolder": "The activation code has already been entered and accepted.<br/>Please leave the Retail Data Storage switched on until you have been notified of the successful activation.",
						"rdsa_p7": "The activation code has already been entered and accepted. Setup was carried out more than 48 hours ago, and activation is already overdue. Please contact <a href='mailto:xentry.diagnosis.support@daimler.com'>xentry.diagnosis.support@daimler.com</a> or your national representative.",
						"rdsa_h5": "Activation of the Retail Data Storage is overdue.",
						"rdsa_p2": "If activation is not possible, or if you need assistance, please contact <a href='mailto:xentry.diagnosis.support@daimler.com'>xentry.diagnosis.support@daimler.com</a> or your national representative."
					}
					/*.........\\_______|>--END_OF_THIS_TRAP--<|_______________//.............
						*/
				}
			};
		},
		complete: function () {
			'use strict';
			console.log('%c TRAP ', 'background-color: rgb(83,101,254)', 'loading process completed');
			var help = {};
			var countryData = getCountries();
			$.extend(true, help, trap, countryData);
			trap = help;
			console.log(trap);
			i18nBrain();
			trapLoaded = true;
		}
	});
	//
	//
	// ...---==== Zu der Trap über Click ====---...
	// ===============>         <==================
	chooseLng();
	function chooseLng() {
		'use strict';
		$('#lang_DE').unbind().click(function(){lng='de';laberKeks_setzen();changeLng('de');$('#navbarDropdown').html('Sprache: DE');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_EN').unbind().click(function(){lng='en';laberKeks_setzen();changeLng('en');$('#navbarDropdown').html('Language: EN');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_RU').unbind().click(function(){lng='ru';laberKeks_setzen();changeLng('ru');$('#navbarDropdown').html('Язык: PУС');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_FR').unbind().click(function(){lng='fr';laberKeks_setzen();changeLng('fr');$('#navbarDropdown').html('Langue: FR');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_JP').unbind().click(function(){lng='jp';laberKeks_setzen();changeLng('jp');$('#navbarDropdown').html('言語: JP');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_CN').unbind().click(function(){lng='cn';laberKeks_setzen();changeLng('cn');$('#navbarDropdown').html('语言: 中文');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_BG').unbind().click(function(){lng='bg';laberKeks_setzen();changeLng('bg');$('#navbarDropdown').html('Език: BGR');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_CZ').unbind().click(function(){lng='cz';laberKeks_setzen();changeLng('cz');$('#navbarDropdown').html('Jazyk: CSY');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_DK').unbind().click(function(){lng='dk';laberKeks_setzen();changeLng('dk');$('#navbarDropdown').html('Sprog: DAN');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_GR').unbind().click(function(){lng='gr';laberKeks_setzen();changeLng('gr');$('#navbarDropdown').html('Γλώσσα: ELL');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_ES').unbind().click(function(){lng='es';laberKeks_setzen();changeLng('es');$('#navbarDropdown').html('Idioma: ESP');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_FI').unbind().click(function(){lng='fi';laberKeks_setzen();changeLng('fi');$('#navbarDropdown').html('Kieli: FIN');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_HU').unbind().click(function(){lng='hu';laberKeks_setzen();changeLng('hu');$('#navbarDropdown').html('Nyelv: HUN');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_IT').unbind().click(function(){lng='it';laberKeks_setzen();changeLng('it');$('#navbarDropdown').html('Lingua: ITA');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_KR').unbind().click(function(){lng='kr';laberKeks_setzen();changeLng('kr');$('#navbarDropdown').html('언어: KOR');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_NL').unbind().click(function(){lng='nl';laberKeks_setzen();changeLng('nl');$('#navbarDropdown').html('Taal: NLD');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_NO').unbind().click(function(){lng='no';laberKeks_setzen();changeLng('no');$('#navbarDropdown').html('Språk: NOR');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_PL').unbind().click(function(){lng='pl';laberKeks_setzen();changeLng('pl');$('#navbarDropdown').html('Język: PLK');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_PT').unbind().click(function(){lng='pt';laberKeks_setzen();changeLng('pt');$('#navbarDropdown').html('Idioma: PTB');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_RO').unbind().click(function(){lng='ro';laberKeks_setzen();changeLng('ro');$('#navbarDropdown').html('Limbă: ROM');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_SI').unbind().click(function(){lng='si';laberKeks_setzen();changeLng('si');$('#navbarDropdown').html('Jezik: SLV');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_BA').unbind().click(function(){lng='ba';laberKeks_setzen();changeLng('ba');$('#navbarDropdown').html('Jezik: SRL');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_SE').unbind().click(function(){lng='se';laberKeks_setzen();changeLng('se');$('#navbarDropdown').html('Språk: SVE');getVersion();getSystemID();updateContent();sortCountries();});
		$('#lang_TR').unbind().click(function(){lng='tr';laberKeks_setzen();changeLng('tr');$('#navbarDropdown').html('Dil: TRK');getVersion();getSystemID();updateContent();sortCountries();});
	}
	//
	//
	// ...---==== Trap nutzen für Inhalt ====---...
	// ===============>         <==================
	function updateContent() {//	Übersetzt alle Texte der Seite
		'use strict';
		let i18nElements = document.querySelectorAll("[data='i18n']");
		let k = 0;
		let i18nodeName;
		let i18nodeID;
		let i18nodeClass;
		while (k < i18nElements.length) {
			i18nodeName = i18nElements[k].nodeName;
			i18nodeID = i18nElements[k].id;
			i18nodeClass = i18nElements[k].className;
			//	Ab hier die Wahl der Elemente mit ID
			if (i18nodeName === "H6") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "B") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "H5") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "H4") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "H3") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "H2") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "H1") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "P") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "A") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "TD") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "TH") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "DT") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "LI") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "DIV") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "LABEL") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "LEGEND") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "SMALL") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "BUTTON") {
				i18nUpdateI(i18nodeID);
			} else if (i18nodeName === "INPUT") {
				i18nUpdateII(i18nodeID);
			} else if (i18nodeName === "SPAN" && i18nodeID !== "") {
				i18nUpdateI(i18nodeID);
			}
			//	Ab hier die Wahl der Elemente mit Klasse
			else if (i18nodeName === "SPAN" && i18nodeID === "") {
				i18nUpdateIV(i18nodeClass);
			} else if (i18nodeName === "OPTION") {
				i18nUpdateIII(i18nodeClass);
			}
			k++;
		}

		// Übersetzungsmethode für Elemente mit ID
		function i18nUpdateI(i18nodeID) {
			$('#' + i18nodeID).html(i18next.t(i18nodeID));
		}

		function i18nUpdateII(i18nodeID) {
			document.getElementById(i18nodeID).placeholder = i18next.t(i18nodeID);
		}

		// Übersetzungsmethode für Elemente mit Klasse
		function i18nUpdateIII(i18nodeClass) {
			document.getElementsByClassName(i18nodeClass)[0].innerHTML = i18next.t(i18nodeClass);
		}

		function i18nUpdateIV(i18nodeClass) {
			$('.' + i18nodeClass).each(function () {
				this.innerHTML = i18next.t(i18nodeClass);
			});
		}
    // function i18nUpdateIII(){}
		////ENDE////
		//========//
	}
	//
	//
	// ...---====   The Brain of  TRAP   ====---...
	// ===============>   i18n   <=================
	function i18nBrain() {
   'use strict';
		if(laberKeks_melden('lng')){
			lng = laberKeks_melden('lng');
			console.log('keks: '+lng);
		}else {
			lng = navigator.language || navigator.userLanguage;
			lng = lng.slice(0,2);
			laberKeks_setzen();
		}
		i18next.use(i18nextXHRBackend).init(
      {
        lng:lng,
        fallbackLng: 'en',
        debug: true,
        resources: trap
      },function() {
        updateContent();
      }
    );
		i18next.on('languageChanged', () => {updateContent();});
	}
	function changeLng(lng) {
    'use strict';
    i18next.changeLanguage(lng);
  }
	//
	//
	// ...---====  The Cookies for TRAP  ====---...
	// ===============>   i18n   <=================
	function laberKeks_setzen() {
		'use strict'
		let dcoo 	= document.cookie;
		let dt 		= new Date(1888915134000); // Auto-expire im November 2019 (circa 10Jahre)
				dt.toUTCString();

		document.cookie = "lng="+lng+"; expires="+dt+"; path=/";

		console.log('%c KEKS ','background-color: rgb(220,220,50)','Keks set');
	}
	function laberKeks_melden( name ) {
		'use strict'
		var dc,
        prefix,
        begin,
        end;

		    dc 			= document.cookie;
		    prefix 	= name + "=";
		    begin 	= dc.indexOf("; " + prefix);
		    end 		= dc.length; // default to end of the string

    // found, and not in first position
    if (begin !== -1) {
        // exclude the "; "
        begin += 2;
    } else {
        //see if cookie is in first position
        begin = dc.indexOf(prefix);
        // not found at all or found as a portion of another cookie name
        if (begin === -1 || begin !== 0 ) return null;
    }

    // if we find a ";" somewhere after the prefix position then "end" is that position,
    // otherwise it defaults to the end of the string
    if (dc.indexOf(";", begin) !== -1) {
        end = dc.indexOf(";", begin);
    }

		console.log('%c KEKS ','background-color: rgb(50,220,70)','Keks loaded');
    return decodeURI(dc.substring(begin + prefix.length, end) ).replace(/\"/g, '');
	}
	//
	//
	//
//// =============================================
//// =====================> <=====================
//// ===================>  P  <===================
//// =================>    A    <=================
//// ===============>      R      <===============
//// =============>        T        <=============
//// ===========>                     <===========
//// =========>  Internationalisierung  <=========
