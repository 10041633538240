console.info('"Globals loaded"');

/**
 * Version abfragen und anzeigen
 * URL der API-Abfrage hängt von der Seite (Port) ab, auf der man sich befindet
 * @param {VersionType} x
 */
function getVersion(x){
	'use strict';
	let port, url;
	if (typeof x !== 'undefined') {
		if (x === 'p-8001') {
			port = 1;
		}	//für das Setup
		else if (x === 'p-8002') {
			port = 2;
		}	//für die Activation
		else if (x === 'p-8003') {
			port = 3;
		}	//für das Config
		else if (x === 'light') {
			port = 12
		}	//für Light
		else {
			port = 12;
		}
	} else {
		port = 12;
	}

	url = function () {
		if (port === 1) {
			return api.be8001 + '/setup/api/v' + api.version + '/version';
		} else if (port === 2) {
			return api.be8002 + '/activation/api/v' + api.version + '/version';
		} else if (port === 3) {
			return api.be8003 + '/config/api/v' + api.version + '/version';
		} else if (port === 12) {
			return api.be8012 + '/xentry/api/v' + api.version + '/version';
		} else {
			console.info('GET VERSION - CAN\'T BUILD UNIQUE URL - TRY DEFAULT -> xentry/api');
			return api.be8012 + '/xentry/api/v' + api.version + '/version';
		}
	};

	$.ajax({
		accepts: 'application/json; charset=UTF-8',
		dataType: 'json',
		success: function (data) {
			if (x === 'light') {
				let ver = data.XusLightVersion;
				$('.xentryVersion').html(ver);

				if (data.IsXusLightUSB) {
					$('#usbMode').css('display', 'block');
					$('#rssMode').css('display', 'none');
				} else {
					$('#usbMode').css('display', 'none');
					$('#rssMode').css('display', 'block');
				}
			} else {
				let ver = data.DebianPackageVersion;
				if (ver.length >= 10) {
					ver = ver.substring(0, ver.indexOf('+'));
				}
				$('.xentryVersion').html(ver);

			}
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("VERSION - API problem!\n", jqXHR + " ==> ", textStatus + " : ", errorThrown);
			console.log(jqXHR, textStatus, errorThrown);
			$('.xentryVersion').html('unknown type');
		},
		complete: function () {
			gloop.monitoringLight.par.startKeyChecked = false;
		},
		url: url()
	});
}

/**
 * System-ID abfragen und anzeigen
 */
function getSystemID() {
	'use strict';
	$.ajax({
		accepts: 'application/json; charset=UTF-8',
		dataType: 'json',
		url: api.be8012 + '/xentry/api/v' + api.version + '/monitoringData',
		success: function (data) {
			let sid = data.SystemID;
			$('#navbarSystemID').find('.systemId').html(sid);
		},
		error: function (jqXHR, textStatus, errorThrown) {
			console.log("MONITORING DATA - API problem!\n", jqXHR + " ==> ", textStatus + " : ", errorThrown);
		},
		complete: function () {
		}
	});
}

/**
 * DOM ready
 */
$( document ).ready(function () {
	'use strict';

	logPerf('DOM ready.');
});

/**
 * Scroll
 */
$( document ).scroll(function () {
	'use strict';

});

/**
 * RegEx's für Wizard Validierung
 */
{
	var pattMail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	var valMail = function (value) {
		'use strict';
		if (pattMail.test(value)) {
			return true;
		} else {
			return false;
		}
	};

	var pattIP = new RegExp(/^(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)$/i);
	var valIP = function (value) {
		'use strict';
		if (pattIP.test(value)) {
			return true;
		} else {
			return false;
		}
	};

	var pattNetmask = new RegExp(/^(254|252|248|240|224|192|128)\.0\.0\.0|255\.(254|252|248|240|224|192|128|0)\.0\.0|255\.255\.(254|252|248|240|224|192|128|0)\.0|255\.255\.255\.(254|252|248|240|224|192|128|0)$/i);
	var valNetmask = function (value) {
		'use strict';
		if (pattNetmask.test(value)) {
			return true;
		} else {
			return false;
		}
	};

	var pattHost = new RegExp(/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/i);
	var valHost = function (value) {
		'use strict';
		if (pattHost.test(value)) {
			return true;
		} else {
			return false;
		}
	};

	var pattPort = new RegExp(/^0*(?:6553[0-5]|655[0-2][0-9]|65[0-4][0-9]{2}|6[0-4][0-9]{3}|[1-5][0-9]{4}|[1-9][0-9]{1,3}|[0-9])$/i);
	var valPort = function (value) {
		'use strict';
		if (pattPort.test(value)) {
			return true;
		} else {
			return false;
		}
	};
}

/**
 * GLOOP <==> Global Loop Object Operator
 * This global Object was introduced to handle the used
 * loops (polling data) better.
 * All loops are operating on the global scope and must be
 * started and stopped separately from within the currently
 * used local scope (local functions).
 * It is meant to use this Global Loop Object Operator for the
 * Light-Version as well.
 */
{
	var looping = "";
	var gloop = {
		/**
		 * Monitoring
		 * Verbindung zur API und Einbinden aller Elemente für RDS-Monitoring
		 */
		monitoring: {
			start: function () {
				'use strict';
				gloop.monitoringLight.stop();
				gloop.releases.stop();
				gloop.releaseLight.stop();
				gloop.flashware.stop();
				gloop.devices.stop();
				$.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					context: gloop.monitoring.context,
					url: gloop.monitoring.url,
					success: gloop.monitoring.success,
					error: gloop.monitoring.error,
					complete: gloop.monitoring.complete
				});
			},
			stop: function () {
				'use strict';
				clearTimeout(looping);
			},
			// ========================================================
			// Function >versucht verbindung zur API zu bauen und holt dann Daten und bindet sie ein
			// ========================================================
			success: function (data) {
				'use strict';
				{
					// ========================================================
					//	Systeminformationen
					// ========================================================
					{
						$('.systemId').html(data.SystemID);
						$(this).find('.serialID').html(data.SerialNumber);
						$(this).find('.freeSpace').html((data.Diskspace / 1020 / 1024 / 1024).toFixed(2) + ' GB');
						if (data.Devices.NewestReleases !== null) {
							$(this).find('.latestRelease').html(Object.entries(data.Devices.NewestReleases)[0][1]);
						} else {
							$(this).find('.latestRelease').html('not known');
						}
						if (data.ActivationDate !== null) {
							let d0 = new Date(parseInt(data.ActivationDate + '000'));
							$(this).find('.actiDate').html(d0);
						} else {
							$(this).find('.actiDate').html('not known');
						}
						if (data.WarrantyEnd !== null) {
							let d1 = new Date(parseInt(data.WarrantyEnd + '000'));
							$(this).find('.warrantyEnd').html(d1);
						} else {
							$(this).find('.warrantyEnd').html('not known');
						}
						if (data.Files.CatalogStates !== null){
							let d2 = new Date(Date.parse(Object.values(data.Files.CatalogStates)[0]));
							$(this).find('.lastUpdateIBC').html(d2.toLocaleDateString(lng, {
								year: 'numeric',
								month: 'short',
								day: 'numeric'
							}));
						} else {
							$(this).find('.lastUpdateIBC').html('not known');
						}
					}
					// ========================================================
					//	Devices Tabelle der Aktuellen Downloads
					// ========================================================
					document.getElementById('monitoringDownloads').innerHTML = '';
					if (data.Files.ActiveDownLoad !== null) {
						for (let [file, progress] of Object.entries(data.Files.ActiveDownLoad)) {
							let md = document.getElementById('monitoringDownloads');
							md.innerHTML += '<div class="col-12">' +
								'<div class="row">' +
								'<div class="col-1"><span></span></div>' +
								'<div class="col-4"><span>' + file + '</span></div>' +
								'<div class="col-6">' +
								'<div class="row">' +
								'<div class="col-4">' +
								'<span>' + progress + ' % </span>' +
								'</div>' +
								'<div class="col-8">' +
								'<div style="display: inline-block; background-color: rgb(63,152,200);height:10px;width:' + progress + '%;"></div>' +
								'</div>' +
								'</div>' +
								'</div>' +
								'</div>' +
								'</div>';
						}
					}
					// ========================================================
					//	Devices Anzeige (wie viele Verbunden)
					// ========================================================

					document.getElementById("monitoringDevices").innerHTML = '<p id="rdsw_p11" data="i18n" >' + data.Devices.DeviceStates.Connected + "/ " + data.Devices.DeviceStates.AllDevices + '  Aktiv</p>';
					// ========================================================
					//	UpstreamStateCheck
					// ========================================================
					if (gloop.monitoring.par.upstreamChecked === false && trapLoaded) {
						console.log('Monitoring Data -->', data);
						gloop.monitoring.par.upstreamChecked = true;
						$.ajax({
							accepts: 'application/json; charset=UTF-8',
							dataType: 'json',
							context: gloop.monitoring.context,
							url: api.be8012 + '/xentry/api/v' + api.version + '/upstreamState',
							success: function (data) {
								if (data.UpstreamState === "Error: Could not connect to watcher service.Failed to read HTTP status line") {
									$('#Verbindungszustand').html('Verbindungszustand --> ERROR - Not connected to Watcher Service <i class="fas fa-exclamation-triangle fa-1x" style="color: rgba(255,55,55,0.75)"></i>');
									document.getElementById('upstreamCheck-xus').innerHTML = '<i class="fas fa-exclamation-triangle fa-1x" style="color: rgba(255,55,55,0.75)"></i> Xentry Update Services';
									document.getElementById('upstreamCheck-guw').innerHTML = '<i class="fas fa-exclamation-triangle fa-1x" style="color: rgba(255,55,55,0.75)"></i> <span id="xuscc_s0" data="i18n">Gerätesteuerung und -wartung</span>';
									document.getElementById('status0').innerHTML = '<i class="text-danger fas fa-times-circle fa-lg"></i>';
									document.getElementById('status1').innerHTML = '<i class="text-danger fas fa-times-circle fa-lg"></i>';
									document.getElementById('status2').innerHTML = '<i class="text-danger fas fa-times-circle fa-lg"></i>';
									document.getElementById('status3').innerHTML = '<i class="text-danger fas fa-times-circle fa-lg"></i>';
								} else {
									var j = 0;
									var k = 0;
									var allOK = 'yes';
									var guwOK = 'yes';
									var vpnState = false;
									for (let [url, state] of Object.entries(data.UpstreamState)) {
										let divID = 'status' + k;
										if (url === '10.200.0.1:80' || url === '10.7.0.1:80') {
											vpnState = vpnState || state;
											divID = 'server_' + url;
											if (state) {
												state = '';
											} else if (!state) {
												state = '';
												if (guwOK === 'yes') {
													guwOK = 'partially';
												} else if (guwOK === 'partially') {
													guwOK = 'no';
													document.getElementById('upstreamCheck-guw').innerHTML = '<i class="fas fa-exclamation-triangle text-danger fa-1x"></i> <span id="xuscc_s0" data="i18n">Gerätesteuerung und -wartung</span>';
												}
											}
										} else if (state) {
											document.getElementById(divID).innerHTML = '<i class="text-success fas fa-check-circle fa-lg"></i>';
											k++;
										} else if (!state) {
											document.getElementById(divID).innerHTML = '<i class="text-danger fas fa-times-circle fa-lg"></i>';
											if (allOK === 'partially') {
												allOK = 'no';
												document.getElementById('upstreamCheck-xus').innerHTML = '<i class="fas fa-exclamation-triangle fa-1x" style="color: rgba(220,30,10,1);"></i> Xentry Update Services';
											} else {
												allOK = 'partially';
												document.getElementById('upstreamCheck-xus').innerHTML = '<i class="fas fa-exclamation-triangle fa-1x" style="color: rgba(230,210,20,1);"></i> Xentry Update Services';
											}
											k++;
										}
										j++;
									}

									if (allOK === 'yes') {
										document.getElementById('upstreamCheck-xus').innerHTML = '<i class="fas fa-check-circle fa-lg" style="color: rgb(65, 209, 83);"></i> Xentry Update Services';
									}
									if (guwOK === 'yes' || guwOK === 'partially') {
										document.getElementById('upstreamCheck-guw').innerHTML = '<i class="fas fa-check-circle text-success fa-lg"></i> <span id="xuscc_s0" data="i18n">Gerätesteuerung und -wartung</span>';
									}

								}
							},
							error: function (jqXHR, textStatus, errorThrown) {
								document.getElementById('upstreamCheck-xus').innerHTML = '<i class="fas fa-exclamation-triangle fa-1x" style="color: rgba(255,55,55,0.75)"></i> Xentry Update Services';
								document.getElementById('upstreamCheck-guw').innerHTML = '<i class="fas fa-exclamation-triangle fa-1x" style="color: rgba(255,55,55,0.75)"></i> <span id="xuscc_s0" data="i18n">Gerätesteuerung und -wartung</span>';
								console.log("UPSTREAM STATE - API problem!\n", textStatus + " :", errorThrown);
								gloop.monitoring.par.upstreamChecked = false;
							},
							complete: function () {
								$('#xuscc_s0').html(i18next.t('xuscc_s0'));
							}
						});
					}
					// ========================================================
					//	Prüfung ob Verbindung zur API steht
					// ========================================================
					if (gloop.monitoring.par.successLogged === false) {
						console.log("API returned monitoring");
						gloop.monitoring.par.successLogged = true;
					}
					gloop.monitoring.interval = 1000;
				}
			},
			error: function (jqXHR, textStatus, errorThrown) {
				'use strict';
				console.info("MONITORING DATA - API problem!\n", textStatus + " :", errorThrown);
				console.info("MONITORING DATA - automated retry in 300 seconds (5min)");
				gloop.monitoring.interval = 300000;
			},
			complete: function () {
				'use strict';
				looping = setTimeout(function () {
					gloop.monitoring.start();
				}, gloop.monitoring.interval);
			},
			//  url: 'http://localhost:8100/dummy/api/v1/monitoringData',
			url: api.be8012 + '/xentry/api/v' + api.version + '/monitoringData',
			par: {
				upstreamChecked: false,
				successLogged: false
			},
			context: $('#monitoring')[0],
			interval: 1000
		},
		/**
		 * MonitoringLight
		 * Verbindung zur API und Einbinden aller Elemente für Light-Monitoring
		 */
		monitoringLight: {
			start: function () {
				'use strict';
				gloop.monitoring.stop();
				gloop.releases.stop();
				gloop.releaseLight.stop();
				gloop.flashware.stop();
				gloop.devices.stop();
				$.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					context: gloop.monitoringLight.context,
					url: gloop.monitoringLight.url,
					success: gloop.monitoringLight.success,
					error: gloop.monitoringLight.error,
					complete: gloop.monitoringLight.complete
				});
			},
			stop: function () {
				'use strict';
				clearTimeout(looping);
			},
			success: function (data) {
				'use strict';
				if(data.SerialNumber){
					$('#light_SN').html(data.SerialNumber);
				}
				else{
					$('#light_SN').html('not known');
				}
				if(data.SystemNumber){
					$('#light_SyNr').html(data.SystemNumber);
				}
				else{
					$('#light_SyNr').html('not known');
				}
				if(!gloop.monitoringLight.par.startKeyChecked){
					if(data.StartKeyValid === true){
						$('#light_SKey').html('valid');
					}
					else if(data.StartKeyValid === false){
						$('#light_SKey').html('not valid');
						popup.alert('StartKeyValid');
					}
					else{
						$('#light_SKey').html('not known');
					}
					gloop.monitoringLight.par.startKeyChecked = true;
				}
				setSpaceBars();
				if(gloop.monitoringLight.par.upstreamChecked === false){
					gloop.monitoringLight.par.upstreamChecked = true;
					upstreamStateCheck();
				}else {
					$('.spinner-border').remove();
				}
				gloop.monitoringLight.interval = 4000;
				console.log("API returned monitoringLight");
			},
			error: function (jqXHR, textStatus, errorThrown) {
				'use strict';
				console.info("MONITORING DATA LIGHT - API problem!\n", textStatus + " :", errorThrown);
				console.info("MONITORING DATA LIGHT - automated retry in 30 seconds");
				gloop.monitoringLight.interval = 30000;
			},
			complete: function () {
				'use strict';
				looping = setTimeout(function () {
					gloop.monitoringLight.start();
				}, gloop.monitoringLight.interval);
			},
			url: api.be8012 + '/xentry/api/v' + api.version + '/monitoringDataLight',
			par: {
				upstreamChecked: false,
				startKeyChecked: false
			},
			context: $('#monitoring')[0],
			interval: 4000
		},
		/**
		 * Releases
		 * Verbindung zur Api und Einbinden aller Elemente für RDS-Releases
		 */
		releases: {
			start: function () {
				'use strict';
				gloop.monitoring.stop();
				gloop.monitoringLight.stop();
				gloop.releaseLight.stop();
				gloop.flashware.stop();
				gloop.flashwareLight.stop();
				gloop.devices.stop();
				$.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					context: gloop.releases.context,
					url: gloop.releases.url(),
					success: gloop.releases.success,
					error: gloop.releases.error,
					complete: gloop.releases.complete
				});
			},
			stop: function () {
				'use strict';
				clearTimeout(looping);
			},
			success: function (data) {
				'use strict';
				var rows = [];
				var j = -1;
				for (var i = 0; i < data.Releases.length; i++) {
					rows[++j] = '<tr><td class="colVer">';
					rows[++j] = data.Releases[i].Version;
					rows[++j] = '</td><td class="colName">';
					rows[++j] = data.Releases[i].ProductName;
					rows[++j] = '</td><td class="colProgress"><div class="progress"><div class="progress-bar" role="progressbar" style="width:';
					rows[++j] = data.Releases[i].Progress;
					rows[++j] = '%" aria-valuenow="';
					rows[++j] = data.Releases[i].Progress;
					rows[++j] = '" aria-valuemin="0" aria-valuemax="100"></div></div>';
					rows[++j] = '</td><td class="colState">';
					switch (data.Releases[i].State) {
						case "Error":
							rows[++j] = i18next.t('xuscc_td8');
							break;
						case "Waiting":
							rows[++j] = i18next.t('xuscc_td5');
							break;
						case "Progress":
							rows[++j] = i18next.t('xuscc_td4');
							break;
						case "Complete":
							rows[++j] = i18next.t('xuscc_td3');
							break;
						default:
							rows[++j] = data.Releases[i].State;
					}
					rows[++j] = '</td><td class="colFile"><a class="btn btn-secondary xuscc_a0" href="';
					rows[++j] = data.Releases[i].ReleaseFile;
					rows[++j] = '" role="button">Exportieren</a></td><td class="colFile"><a class="btn btn-secondary xuscc_a0" href="';
					rows[++j] = data.Releases[i].MetaFileUrl;
					rows[++j] = '" role="button" download>Exportieren</a></td></tr>';
				}
				$('#releaseTable tbody').html(rows.join(''));
				for (let meh = 0; meh < document.getElementsByClassName('xuscc_a0').length; meh++) {
					document.getElementsByClassName('xuscc_a0')[meh].innerHTML = i18next.t('xuscc_a0');
				}
				gloop.releases.interval = 1000;

				//	Sortieren der Tabelle
				//	----------------------------------------------------------------------
				$('.table thead').find('.colSort').each(function () {
					const s = $(this).attr("data-sort");
					let d = "";
					$(this).unbind().click(function () {
						if ($(this).hasClass('active')) {
							$(this).toggleClass('reverse');
						} else {
							$(this).addClass('active');
							$(this).siblings().removeClass('active');
						}

						if ($(this).hasClass('reverse')) {
							d = "DESC";
						} else {
							d = "ASC";
						}
						gloop.releases.par.sort = s + ":" + d;
						var id = $(this).parent().parent().parent().attr('id');
						gloop.releases.stop();
						gloop.releases.start();
					});
				});
			},
			error: function (jqXHR, textStatus, errorThrown) {
				'use strict';
				console.info("RELEASE DATA - API problem!\n", textStatus + " :", errorThrown);
				console.info("RELEASE DATA - automated retry in 30 seconds");
				gloop.releases.interval = 30000;
			},
			complete: function () {
				'use strict';
				looping = setTimeout(function () {
					gloop.releases.start();
				}, gloop.releases.interval);
			},
			url: function () {
				'use strict';
				let sort = gloop.releases.par.sort;
				return api.be8012 + '/xentry/api/v' + api.version + '/releaseData?sort=' + sort;
			},
			par: {
				sort: ''
			},
			context: $('#releases')[0],
			interval: 1000
		},
		/**
		 * ReleaseLastLight
		 */
		releaseLight: {
			start: function(){
				'use strict';
				gloop.monitoringLight.stop();
				gloop.flashwareLight.stop();
				$.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					context: gloop.releaseLight.context,
					url: gloop.releaseLight.url,
					success: gloop.releaseLight.success,
					error: gloop.releaseLight.error,
					complete: gloop.releaseLight.complete
				});
			},
			stop: function(){
				'use strict';
				clearTimeout(looping);
			},
			success: function(data){
				'use strict';
				var rows = [];
				var prog;
				var j = -1;
				for(var i = 0; i < data.Releases.length; i++) {
					prog = data.Releases[i].Progress;
					// version
					rows[++j] = '<tr><td class="colVer">';
					rows[++j] = data.Releases[i].Version;
					// productname
					rows[++j] = '</td><td class="colName">';
					rows[++j] = data.Releases[i].ProductName;
					// pausebutton
					rows[++j] = '<td><a id="ss-btn" class="btn btn-secondary ';
					if (gloop.releaseLight.par.paused)
						rows[++j] = 'play';
					else
						rows[++j] = 'pause';
					rows[++j] = 'Download" style="visibility: ';
					if (prog<100)
						rows[++j] = 'visible';
					else
						rows[++j] = 'hidden';
					rows[++j] = '"><i id="issbtn" class="text-white fas fa-';
					if (gloop.releaseLight.par.paused)
						rows[++j] = 'play';
					else
						rows[++j] = 'pause';
					rows[++j] = ' controlDownload"></i></a></td>';
					// progressbar
					rows[++j] = '</td><td class="colProgress"><div class="progress" title="' + prog + ' %"><div class="progress-bar" id="progBar' + i + '" title="' + prog + ' %" role="progressbar" style="width:';
					rows[++j] = prog;
					rows[++j] = '%; opacity: ';
					if (gloop.releaseLight.par.paused)
						rows[++j] = '0.55';
					else
						rows[++j] = '1';
					rows[++j] = '" aria-valuenow="';
					rows[++j] = prog;
					rows[++j] = '" aria-valuemin="0" aria-valuemax="100"></div></div>';
					// progressstate
					rows[++j] = '</td><td class="colState" id="cost">';
					if (gloop.releaseLight.par.paused) {
						rows[++j] = i18next.t('xuscc_td6');
					} else {
						switch (data.Releases[i].State) {
							case "Error":
								rows[++j] = i18next.t('xuscc_td8');
								break;
							case "Waiting":
								rows[++j] = i18next.t('xuscc_td5');
								break;
							case "Progress":
								rows[++j] = i18next.t('xuscc_td4');
								break;
							case "Complete":
								rows[++j] = i18next.t('xuscc_td3');
								break;
							default:
								rows[++j] = data.Releases[i].State;
						}
					}
					// release file
					rows[++j] = '</td><td class="colFile"><a class="btn btn-secondary xusccl_a0';
					if (data.Releases[i].State === 'Complete') {
						rows[++j] = ' "href="';
					} else {
						rows[++j] = ' disabled" aria-disabled="true" href="';
					}
					rows[++j] = data.Releases[i].ReleaseFile;
					rows[++j] = '" role="button">Exportieren</a>';
					// meta file
					rows[++j] = '</td><td class="colFile"><a class="btn btn-secondary xusccl_a0';
					if (data.Releases[i].State === 'Complete') {
						rows[++j] = ' "href="';
					} else {
						rows[++j] = ' disabled" aria-disabled="true" href="';
					}
					rows[++j] = data.Releases[i].MetaFileUrl;
					rows[++j] = '" role="button" download>Exportieren</a></td>';
					if (prog < 100) {
						$('.controlDownload').ready(function () {
							let k = i - 1;
							$('.pauseDownload').unbind().click(function () {
								gloop.releaseLight.par.paused = true;
								toggleReleaseDownload('pause', k);
							});
							$('.playDownload').unbind().click(function () {
								gloop.releaseLight.par.paused = false;
								toggleReleaseDownload('play', k);
							});
						});
					}
				}
				//	Elemente der Tabelle übersetzen
				//------------------------------------------------------------------------
				$('#releaseTable tbody').html(rows.join(''));
				for(let meh = 0; meh < document.getElementsByClassName('xusccl_a0').length;meh++){
					document.getElementsByClassName('xusccl_a0')[meh].innerHTML = i18next.t('xusccl_a0');
				}
				//	Pausieren/Wiederaufnehmen des Release Download
				//------------------------------------------------------------------------
				function toggleReleaseDownload(state, row) {
					let pause;
					if (state === 'pause') {
						pause = true
					} else {
						if (state === 'play') {
							pause = false
						}
					}
					$.ajax({
						method: 'POST',
						contentType: 'application/json',
						url: api.be8012 +'/xentry/api/v'+ api.version +'/PauseReleaseDownload',
						data: JSON.stringify({
							"PauseAllReleaseDownloads": pause
						}),
						success:function(){
							console.log('success');
							if (pause) {
								$('#issbtn').attr('class', 'text-white fas fa-play controlDownload');
								$('#ss-btn').attr('class', 'btn btn-secondary playDownload');
								$('#progBar' + row).css('opacity', 0.55);
								$('#cost').html('paused');
							} else if (!pause) {
								$('#issbtn').attr('class', 'text-white fas fa-pause controlDownload');
								$('#ss-btn').attr('class', 'btn btn-secondary pauseDownload');
								$('#progBar' + row).css('opacity', 1);
							}
						},
						error:function(jqXHR, textStatus){
							console.log('Error:');
							console.log(jqXHR);
							console.log('Request failed: '+ textStatus);
						},
						complete:function(){
							// 	Diese Complete-Funktion kann wieder entfernt werden, wenn die Funktionalität
							//	im Zusammenspiel mit dem BE funktioniert. Was die HUI betrifft, zeigt diese
							//	Complete-Funktion das	gleiche Verhalten wie im Fall der Success-Funktion.
							console.log('complete');
						}
					})
				}
	
				gloop.releaseLight.interval = 1000;
			},
			error: function(jqXHR, textStatus, errorThrown){
				'use strict';
				console.info("RELEASE LATEST - API problem!\n", textStatus+" :", errorThrown);
				console.info("RELEASE LATEST - automated retry in 30 seconds");
				gloop.releaseLight.interval = 30000;
			},
			complete: function(){
				'use strict';
				looping = setTimeout(function () {
					gloop.releaseLight.start();
				}, gloop.releaseLight.interval);
			},
			url: api.be8012 +'/xentry/api/v'+ api.version +'/releaseLatest',
			// url: 'http://localhost:8100/dummy/api/v1/releaseLatest',
			par: {
				paused: false
			},
			context: $('#releases')[0],
			interval: 1000
		},
		/**
		 * Flashware
		 */
		flashware: {
			start: function () {
				'use strict';
				gloop.monitoring.stop();
				gloop.monitoringLight.stop();
				gloop.releases.stop();
				gloop.releaseLight.stop();
				gloop.devices.stop();
				$.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					context: gloop.flashware.context,
					url: gloop.flashware.url(),
					success: gloop.flashware.success,
					error: gloop.flashware.error,
					complete: gloop.flashware.complete
				});
			},
			stop: function () {
				'use strict';
				clearTimeout(looping);
			},
			success: function (data) {
				'use strict';
				// 	füllt Tabelle mit Werten
				//	----------------------------------------------------------------------
				var max = data.FilteredCount;
				if (max < gloop.flashware.par.limit) {
					gloop.flashware.par.limit = max;
				}

				var rows = [];
				var j = -1;
				if (data.Flashwares.length) {
					for (var i = 0; i < data.Flashwares.length && data.Flashwares[i]; i++) {
						rows[++j] = '<tr><td class="colName">';
						rows[++j] = data.Flashwares[i].FileName;
						rows[++j] = '</td><td class="colProgress"><div class="progress"><div class="progress-bar" role="progressbar" style="width:';
						rows[++j] = data.Flashwares[i].Progress;
						rows[++j] = '%" aria-valuenow="';
						rows[++j] = data.Flashwares[i].Progress;
						rows[++j] = '" aria-valuemin="0" aria-valuemax="100"></div></div>';
						rows[++j] = '</td><td class="colState">';
						switch (data.Flashwares[i].State) {
							case "Error":
								rows[++j] = i18next.t('xuscc_td8');
								break;
							case "Waiting":
								rows[++j] = i18next.t('xuscc_td5');
								break;
							case "Progress":
								rows[++j] = i18next.t('xuscc_td4');
								break;
							case "Complete":
								rows[++j] = i18next.t('xuscc_td3');
								break;
							default:
								rows[++j] = data.Flashwares[i].State;
						}
						rows[++j] = '</td><td class="colDemand"><button class="btn btn-secondary jsFlashwareDemand xuscc_a1" data-cs="';
						rows[++j] = data.Flashwares[i].Checksum;
						rows[++j] = '" data-fn="';
						rows[++j] = data.Flashwares[i].FileName;
						if (data.Flashwares[i].State === 'Complete') {
							rows[++j] = '" disabled>Anfordern</button>';
						} else {
							rows[++j] = '">Anfordern</button>';
						}
						// rows[++j] = demandFlashButton(data.Flashwares[i].Checksum, data.Flashwares[i].FileName, data.Flashwares[i].State);
						rows[++j] = '</td><td class="colFile"><a href="';
						rows[++j] = data.Flashwares[i].FlashwareFile;
						if (data.Flashwares[i].State === 'Complete') {
							rows[++j] = '" class="btn btn-secondary xuscc_a2" role="button">Exportieren</a>';
						} else {
							rows[++j] = '" class="btn btn-secondary xuscc_a2 disabled" role="button" aria-disabled="true">Exportieren</a>';
						}
						if ($('#devmodeSwitch').is(':checked')) {
							rows[++j] = '</td><td class="colDev"><button class="btn btn-secondary jsFlashwareRemove" data-cs="';
							rows[++j] = data.Flashwares[i].Checksum;
							rows[++j] = '" data-fn="';
							rows[++j] = data.Flashwares[i].FileName;
							rows[++j] = '"><i class="fas fa-trash"></i></button>';
						}
						rows[++j] = '</td></tr>';
					}
				}
				$('#flashwareTable tbody').html(rows.join(''));
				for (let meh = 0; meh < document.getElementsByClassName('xuscc_a1').length; meh++) {
					document.getElementsByClassName('xuscc_a1')[meh].innerHTML = i18next.t('xuscc_a1');
				}
				for (let meh = 0; meh < document.getElementsByClassName('xuscc_a2').length; meh++) {
					document.getElementsByClassName('xuscc_a2')[meh].innerHTML = i18next.t('xuscc_a2');
				}
				$('.jsFlashTotal').html(max);

				//	Flashware anfordern / löschen
				//	----------------------------------------------------------------------
				$('.jsFlashwareDemand').click(function () {
					var cs = $(this).attr('data-cs');
					var fn = $(this).attr('data-fn');
					$.ajax({
						method: 'POST',
						contentType: 'application/json',
						url: api.be8012 + '/xentry/api/v' + api.version + '/flashwareDemand',
						data: JSON.stringify({
							"OnDemand": [
								{
									"Checksum": cs,
									"FileName": fn
								}
							]
						})
					})
						.done(function (msg) {
							console.log(msg);
							console.log('Request flashware');
						})
						.fail(function (jqXHR, textStatus) {
							console.log('Request failed: ' + textStatus);
							console.log(cs, fn);
						});
				});
				$('.jsFlashwareRemove').click(function () {
					var cs = $(this).attr('data-cs');
					var fn = $(this).attr('data-fn');
					let token = window.sessionStorage.getItem("accessToken");
					if (validateAccessToken(token)) {
						$.ajax({
							method: 'POST',
							contentType: 'application/json',
							url: api.be8012 + '/xentry/api/v' + api.version + '/flashwareDelete',
							data: JSON.stringify({
								"Delete": [
									{
										"Checksum": cs,
										"FileName": fn
									}
								]
							}),
							beforeSend: function (xhr) {
								xhr.setRequestHeader("Authorization", "Bearer " + token);
							}
						})
							.done(function (msg) {
								console.log(msg);
								console.log('Deleted flashware');
							})
							.fail(function (jqXHR, textStatus) {
								console.log('Delete failed: ' + textStatus);
								console.log(cs, fn);
							});
					} else {
						login();
					}
				});

				//	Flashware Suche
				//	----------------------------------------------------------------------
				var countSE = 0; 								// countSE ... count Search Entries

				$('#xuscc_i1').keyup(function (event) {
					let countSE = document.getElementById('xuscc_i1').value.length;
					let searchTerm = document.getElementById('xuscc_i1').value;
					if (countSE >= 3 && searchTerm !== '') {
						gloop.flashware.par.search = searchTerm;
						gloop.flashware.par.limit = 10;
						gloop.flashware.par.offset = 0;
					}
					if (countSE < 3 && event.which === 13) {
						gloop.flashware.par.search = searchTerm;
						gloop.flashware.par.limit = 10;
						gloop.flashware.par.offset = 0;
					}
				});
				$('#xuscc_b1').click(function () {
					let searchTerm = document.getElementById('xuscc_i1').value;
					if (searchTerm !== '') {
						gloop.flashware.par.search = searchTerm;
						gloop.flashware.par.limit = 10;
						gloop.flashware.par.offset = 0;
					}
				});
				$('#xuscc_i1').blur(function () {
					let countSE = document.getElementById('xuscc_i1').value.length;
					if (countSE === 0) {
						gloop.flashware.par.search = '';
						gloop.flashware.par.limit = 10;
						gloop.flashware.par.offset = 0;
					}
				});

				//	Sortieren der Tabelle
				//	----------------------------------------------------------------------
				$('.table thead').find('.colSort').each(function () {
					const s = $(this).attr("data-sort");
					let d = "";
					$(this).unbind().click(function () {
						if ($(this).hasClass('active')) {
							$(this).toggleClass('reverse');
						} else {
							$(this).addClass('active');
							$(this).siblings().removeClass('active');
						}

						if ($(this).hasClass('reverse')) {
							d = "DESC";
						} else {
							d = "ASC";
						}
						gloop.flashware.par.sort = s + ":" + d;
						var id = $(this).parent().parent().parent().attr('id');
						gloop.flashware.stop();
						gloop.flashware.start();
					});
				});

				//	Funktionalität Grenzfälle Flshware Seitenauswahl
				//	----------------------------------------------------------------------
				if (gloop.flashware.par.offset === 0) {
					// console.log('First page.');
					$('.jsFlashPrev').parent().addClass('disabled');
					$('.jsFlashPrev').attr({
						'tabindex': '-1',
						'aria-disabled': 'true'
					});
					$('.jsFlashFirst').parent().addClass('disabled');
					$('.jsFlashFirst').attr({
						'tabindex': '-1',
						'aria-disabled': 'true'
					});
					$('.jsFlashNext').parent().removeClass('disabled');
					$('.jsFlashNext').removeAttr("tabindex aria-disabled");
					$('.jsFlashLast').parent().removeClass('disabled');
					$('.jsFlashLast').removeAttr("tabindex aria-disabled");
				} else if (gloop.flashware.par.offset >= (max - gloop.flashware.par.limit)) {
					// console.log('Last page.');
					$('.jsFlashNext').parent().addClass('disabled');
					$('.jsFlashNext').attr({
						'tabindex': '-1',
						'aria-disabled': 'true'
					});
					$('.jsFlashLast').parent().addClass('disabled');
					$('.jsFlashLast').attr({
						'tabindex': '-1',
						'aria-disabled': 'true'
					});
					$('.jsFlashPrev').parent().removeClass('disabled');
					$('.jsFlashPrev').removeAttr("tabindex aria-disabled");
					$('.jsFlashFirst').parent().removeClass('disabled');
					$('.jsFlashFirst').removeAttr("tabindex aria-disabled");
				} else {
					// console.log('In between.');
					$('.jsFlashPrev').parent().removeClass('disabled');
					$('.jsFlashPrev').removeAttr("tabindex aria-disabled");
					$('.jsFlashFirst').parent().removeClass('disabled');
					$('.jsFlashFirst').removeAttr("tabindex aria-disabled");
					$('.jsFlashNext').parent().removeClass('disabled');
					$('.jsFlashNext').removeAttr("tabindex aria-disabled");
					$('.jsFlashLast').parent().removeClass('disabled');
					$('.jsFlashLast').removeAttr("tabindex aria-disabled");
				}
				if (max % gloop.flashware.par.limit === 0) {
					$('#xuscc_s1').html((max / gloop.flashware.par.limit));
				} else {
					$('#xuscc_s1').html(Math.floor(max / gloop.flashware.par.limit) + 1);
				}

				//	Seitenauswahl
				//	----------------------------------------------------------------------
				$('.jsFlashMax').val(parseInt(gloop.flashware.par.limit));
				$('.jsFlashMax').change(function () {
					gloop.flashware.par.offset = 0;
					gloop.flashware.par.limit = document.getElementsByClassName('jsFlashMax')[0].value;
				});
				$('.jsFlashNext').unbind().click(function () {
					if ((gloop.flashware.par.offset + 2 * gloop.flashware.par.limit) < max) {
						gloop.flashware.par.offset += parseInt(gloop.flashware.par.limit);
						gloop.flashware.stop();
						gloop.flashware.start();
					} else if ((gloop.flashware.par.offset + 2 * gloop.flashware.par.limit) >= max) {
						gloop.flashware.par.offset = max - gloop.flashware.par.limit;
						gloop.flashware.stop();
						gloop.flashware.start();
					}
				});
				$('.jsFlashPrev').unbind().click(function () {
					if ((gloop.flashware.par.offset - gloop.flashware.par.limit) < 0) {
						gloop.flashware.par.offset = 0;
						gloop.flashware.stop();
						gloop.flashware.start();
					} else if ((gloop.flashware.par.offset - gloop.flashware.par.limit) >= 0) {
						gloop.flashware.par.offset -= parseInt(gloop.flashware.par.limit);
						gloop.flashware.stop();
						gloop.flashware.start();
					}
				});
				$('.jsFlashLast').unbind().click(function () {
					gloop.flashware.par.offset = max - gloop.flashware.par.limit;
					gloop.flashware.stop();
					gloop.flashware.start();
				});
				$('.jsFlashFirst').unbind().click(function () {
					gloop.flashware.par.offset = 0;
					gloop.flashware.stop();
					gloop.flashware.start();
				});

				// 	"Lese ich ein Magazin, nehme ich mein Focusyn!" - Bart Simpson
				//	----------------------------------------------------------------------

				$('#flashCurrent').unbind().focusin(function () {
					gloop.flashware.stop();
					$('#flashCurrent').keyup(function (event) {
						if (event.which === 13) {
							$('#flashCurrent').trigger("focusout");
						}
					});

					$('#flashCurrent').focusout(function () {
						let that = parseInt(document.getElementById('xuscc_s1').innerHTML);
						if (this.value <= that && this.value >= 1) {
							gloop.flashware.par.offset = gloop.flashware.par.limit * (this.value - 1);
							if (parseInt(gloop.flashware.par.offset) + parseInt(gloop.flashware.par.limit) > max) {
								gloop.flashware.par.offset = max - parseInt(gloop.flashware.par.limit);
							}
						} else {
							popup.alert('pageNotFound');
						}
						gloop.flashware.start();
						gloop.flashware.stop();
					});
				});

				$('#flashCurrent').keyup(function () {
					gloop.flashware.stop();
					if (event.which === 13) {
						console.log('!!! key up - k13!!!');
						$('#flashCurrent').focusout(function () {
							let that = parseInt(document.getElementById('xuscc_s1').innerHTML);
							if (this.value <= that && this.value >= 1) {
								gloop.flashware.par.offset = gloop.flashware.par.limit * (this.value - 1);
								if (parseInt(gloop.flashware.par.offset) + parseInt(gloop.flashware.par.limit) > max) {
									gloop.flashware.par.offset = max - parseInt(gloop.flashware.par.limit);
								}
							} else {
								popup.alert('pageNotFound');
							}
							gloop.flashware.start();
							gloop.flashware.stop();
						});
						let that = parseInt(document.getElementById('xuscc_s1').innerHTML);
						if (this.value <= that && this.value >= 1) {
							gloop.flashware.par.offset = gloop.flashware.par.limit * (this.value - 1);
							if (parseInt(gloop.flashware.par.offset) + parseInt(gloop.flashware.par.limit) > max) {
								gloop.flashware.par.offset = max - parseInt(gloop.flashware.par.limit);
							}
						} else {
							popup.alert('pageNotFound');
						}
						gloop.flashware.start();
						gloop.flashware.stop();
					}
				});
				$('#flashCurrent').val(
					Math.ceil(gloop.flashware.par.offset / gloop.flashware.par.limit) + 1
				);

				gloop.flashware.interval = 1000;
			},
			error: function (jqXHR, textStatus, errorThrown) {
				'use strict';
				console.info("FLASHWARE DATA - API problem!\n", textStatus + " :", errorThrown);
				console.info("FLASHWARE DATA - automated retry in 30 seconds");
				gloop.flashware.interval = 30000;
			},
			complete: function () {
				'use strict';
				looping = setTimeout(function () {
					gloop.flashware.start();
				}, gloop.flashware.interval);
			},
			url: function () {
				'use strict';
				let offset = gloop.flashware.par.offset;
				let limit = gloop.flashware.par.limit;
				let search = gloop.flashware.par.search;
				let sort = gloop.flashware.par.sort;
				return api.be8012 + '/xentry/api/v' + api.version + '/flashwareData?offset=' + offset + '&limit=' + limit + '&search=' + search + '&sort=' + sort;
			},
			par: {
				offset: 0,
				limit: 10,
				search: '',
				sort: ''
			},
			context: $('#flashware')[0],
			interval: 1000
		},
		/**
		 * FlashwareLight
		 */
		flashwareLight: {
			start: function () {
				'use strict';
				gloop.monitoring.stop();
				gloop.monitoringLight.stop();
				gloop.releases.stop();
				gloop.releaseLight.stop();
				gloop.devices.stop();
				$.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					context: gloop.flashwareLight.context,
					url: gloop.flashwareLight.url(),
					success: gloop.flashwareLight.success,
					error: gloop.flashwareLight.error,
					complete: gloop.flashwareLight.complete
				});
			},
			stop: function () {
				'use strict';
				clearTimeout(looping);
			},
			success: function (data) {
				'use strict';
				// 	füllt Tabelle mit Werten
				//	----------------------------------------------------------------------
				var max = data.FilteredCount;
				if (max < gloop.flashwareLight.par.limit) {
					gloop.flashwareLight.par.limit = max;
				}

				var rows = [];
				var j = -1;
				if (data.Flashwares.length) {
					for (var i = 0; i < gloop.flashwareLight.par.limit && data.Flashwares[i]; i++) {
						rows[++j] = '<tr><td class="colName">';
						rows[++j] = data.Flashwares[i].FileName;
						rows[++j] = '</td><td class="colProgress"><div class="progress"><div class="progress-bar" role="progressbar" style="width:';
						rows[++j] = data.Flashwares[i].Progress;
						rows[++j] = '%" aria-valuenow="';
						rows[++j] = data.Flashwares[i].Progress;
						rows[++j] = '" aria-valuemin="0" aria-valuemax="100"></div></div>';
						rows[++j] = '</td><td class="colState">';
						switch (data.Flashwares[i].State) {
							case "Error":
								rows[++j] = i18next.t('xuscc_td8');
								break;
							case "Waiting":
								rows[++j] = i18next.t('xuscc_td5');
								break;
							case "Progress":
								rows[++j] = i18next.t('xuscc_td4');
								break;
							case "Complete":
								rows[++j] = i18next.t('xuscc_td3');
								break;
							default:
								rows[++j] = data.Flashwares[i].State;
						}
						rows[++j] = '</td><td class="colDemand"><button class="btn btn-secondary jsFlashwareDemand xusccl_a1" data-cs="';
						rows[++j] = data.Flashwares[i].Checksum;
						rows[++j] = '" data-fn="';
						rows[++j] = data.Flashwares[i].FileName;
						if (data.Flashwares[i].State === 'Complete') {
							rows[++j] = '" disabled>Anfordern</button>';
						} else {
							rows[++j] = '">Anfordern</button>';
						}
						// rows[++j] = demandFlashButton(data.Flashwares[i].Checksum, data.Flashwares[i].FileName, data.Flashwares[i].State);
						rows[++j] = '</td>';
						if (variante !== 'light') {
							rows[++j] = '<td class="colFile"><a href="';
							rows[++j] = data.Flashwares[i].FlashwareFile;
							if (data.Flashwares[i].State === 'Complete') {
								rows[++j] = '" class="btn btn-secondary xuscc_a2" role="button">Exportieren</a>';
							} else {
								rows[++j] = '" class="btn btn-secondary xuscc_a2 disabled" role="button" aria-disabled="true">Exportieren</a>';
							}
						}
						if ($('#devmodeSwitch').is(':checked')) {
							rows[++j] = '<td class="colDev"><button class="btn btn-secondary jsFlashwareRemove" data-cs="';
							rows[++j] = data.Flashwares[i].Checksum;
							rows[++j] = '" data-fn="';
							rows[++j] = data.Flashwares[i].FileName;
							rows[++j] = '"><i class="fas fa-trash"></i></button></td>';
						}
						rows[++j] = '</tr>';
					}
				}
				$('#flashwareTable tbody').html(rows.join(''));
				for (let meh = 0; meh < document.getElementsByClassName('xusccl_a1').length; meh++) {
					document.getElementsByClassName('xusccl_a1')[meh].innerHTML = i18next.t('xusccl_a1');
				}
				$('.jsFlashTotal').html(max);

				//	Flashware anfordern / löschen
				//	----------------------------------------------------------------------
				$('.jsFlashwareDemand').click(function () {
					var cs = $(this).attr('data-cs');
					var fn = $(this).attr('data-fn');
					$.ajax({
						method: 'POST',
						contentType: 'application/json',
						url: api.be8012 + '/xentry/api/v' + api.version + '/flashwareDemand',
						data: JSON.stringify({
							"OnDemand": [
								{
									"Checksum": cs,
									"FileName": fn
								}
							]
						})
					})
						.done(function (msg) {
							console.log(msg);
							console.log('Request flashware');
						})
						.fail(function (jqXHR, textStatus) {
							console.log('Request failed: ' + textStatus);
							console.log(cs, fn);
						});
				});
				$('.jsFlashwareRemove').click(function () {
					var cs = $(this).attr('data-cs');
					var fn = $(this).attr('data-fn');
					let token = window.sessionStorage.getItem("accessToken");
					if (validateAccessToken(token)) {
						$.ajax({
							method: 'POST',
							contentType: 'application/json',
							url: api.be8012 + '/xentry/api/v' + api.version + '/flashwareDelete',
							data: JSON.stringify({
								"Delete": [
									{
										"Checksum": cs,
										"FileName": fn
									}
								]
							}),
							beforeSend: function (xhr) {
								xhr.setRequestHeader("Authorization", "Bearer " + token);
							}
						})
							.done(function (msg) {
								console.log(msg);
								console.log('Deleted flashware');
							})
							.fail(function (jqXHR, textStatus) {
								console.log('Delete failed: ' + textStatus);
								console.log(cs, fn);
							});
					} else {
						login();
					}
				});

				//	Flashware Suche
				//	----------------------------------------------------------------------
				var countSE = 0; 								// countSE ... count Search Entries

				$('#xusccl_i1').keyup(function (event) {
					let countSE = document.getElementById('xusccl_i1').value.length;
					let searchTerm = document.getElementById('xusccl_i1').value;
					if (countSE >= 3 && searchTerm !== '') {
						gloop.flashwareLight.par.search = searchTerm;
						gloop.flashwareLight.par.limit = 10;
						gloop.flashwareLight.par.offset = 0;
					}
					if (countSE < 3 && event.which === 13) {
						gloop.flashwareLight.par.search = searchTerm;
						gloop.flashwareLight.par.limit = 10;
						gloop.flashwareLight.par.offset = 0;
					}
				});
				$('#xusccl_b1').click(function () {
					let searchTerm = document.getElementById('xusccl_i1').value;
					if (searchTerm !== '') {
						gloop.flashwareLight.par.search = searchTerm;
						gloop.flashwareLight.par.limit = 10;
						gloop.flashwareLight.par.offset = 0;
					}
				});
				$('#xusccl_i1').blur(function () {
					let countSE = document.getElementById('xusccl_i1').value.length;
					if (countSE === 0) {
						gloop.flashwareLight.par.search = '';
						gloop.flashwareLight.par.limit = 10;
						gloop.flashwareLight.par.offset = 0;
					}
				});

				//	Sortieren der Tabelle
				//	----------------------------------------------------------------------
				$('.table thead').find('.colSort').each(function () {
					const s = $(this).attr("data-sort");
					let d = "";
					$(this).unbind().click(function () {
						if ($(this).hasClass('active')) {
							$(this).toggleClass('reverse');
						} else {
							$(this).addClass('active');
							$(this).siblings().removeClass('active');
						}

						if ($(this).hasClass('reverse')) {
							d = "DESC";
						} else {
							d = "ASC";
						}
						gloop.flashwareLight.par.sort = s + ":" + d;
						var id = $(this).parent().parent().parent().attr('id');
						gloop.flashwareLight.stop();
						gloop.flashwareLight.start();
					});
				});

				//	Funktionalität Grenzfälle Flshware Seitenauswahl
				//	----------------------------------------------------------------------
				if (gloop.flashwareLight.par.offset === 0) {
					// console.log('First page.');
					$('.jsFlashPrev').parent().addClass('disabled');
					$('.jsFlashPrev').attr({
						'tabindex': '-1',
						'aria-disabled': 'true'
					});
					$('.jsFlashFirst').parent().addClass('disabled');
					$('.jsFlashFirst').attr({
						'tabindex': '-1',
						'aria-disabled': 'true'
					});
					$('.jsFlashNext').parent().removeClass('disabled');
					$('.jsFlashNext').removeAttr("tabindex aria-disabled");
					$('.jsFlashLast').parent().removeClass('disabled');
					$('.jsFlashLast').removeAttr("tabindex aria-disabled");
				} else if (gloop.flashwareLight.par.offset >= (max - gloop.flashwareLight.par.limit)) {
					// console.log('Last page.');
					$('.jsFlashNext').parent().addClass('disabled');
					$('.jsFlashNext').attr({
						'tabindex': '-1',
						'aria-disabled': 'true'
					});
					$('.jsFlashLast').parent().addClass('disabled');
					$('.jsFlashLast').attr({
						'tabindex': '-1',
						'aria-disabled': 'true'
					});
					$('.jsFlashPrev').parent().removeClass('disabled');
					$('.jsFlashPrev').removeAttr("tabindex aria-disabled");
					$('.jsFlashFirst').parent().removeClass('disabled');
					$('.jsFlashFirst').removeAttr("tabindex aria-disabled");
				} else {
					// console.log('In between.');
					$('.jsFlashPrev').parent().removeClass('disabled');
					$('.jsFlashPrev').removeAttr("tabindex aria-disabled");
					$('.jsFlashFirst').parent().removeClass('disabled');
					$('.jsFlashFirst').removeAttr("tabindex aria-disabled");
					$('.jsFlashNext').parent().removeClass('disabled');
					$('.jsFlashNext').removeAttr("tabindex aria-disabled");
					$('.jsFlashLast').parent().removeClass('disabled');
					$('.jsFlashLast').removeAttr("tabindex aria-disabled");
				}

				if (max % gloop.flashwareLight.par.limit === 0) {
					$('#xusccl_s1').html((max / gloop.flashwareLight.par.limit));
				} else {
					$('#xusccl_s1').html(Math.floor(max / gloop.flashwareLight.par.limit) + 1);
				}

				//	Seitenauswahl
				//	----------------------------------------------------------------------
				$('.jsFlashMax').val(parseInt(gloop.flashwareLight.par.limit));
				$('.jsFlashMax').change(function () {
					gloop.flashwareLight.par.offset = 0;
					gloop.flashwareLight.par.limit = document.getElementsByClassName('jsFlashMax')[0].value;
				});
				$('.jsFlashNext').unbind().click(function () {
					if ((gloop.flashwareLight.par.offset + 2 * gloop.flashwareLight.par.limit) < max) {
						gloop.flashwareLight.par.offset += parseInt(gloop.flashwareLight.par.limit);
						gloop.flashwareLight.stop();
						gloop.flashwareLight.start();
					} else if ((gloop.flashwareLight.par.offset + 2 * gloop.flashwareLight.par.limit) >= max) {
						gloop.flashwareLight.par.offset = max - gloop.flashwareLight.par.limit;
						gloop.flashwareLight.stop();
						gloop.flashwareLight.start();
					}
				});
				$('.jsFlashPrev').unbind().click(function () {
					if ((gloop.flashwareLight.par.offset - gloop.flashwareLight.par.limit) < 0) {
						gloop.flashwareLight.par.offset = 0;
						gloop.flashwareLight.stop();
						gloop.flashwareLight.start();
					} else if ((gloop.flashwareLight.par.offset - gloop.flashwareLight.par.limit) >= 0) {
						gloop.flashwareLight.par.offset -= parseInt(gloop.flashwareLight.par.limit);
						gloop.flashwareLight.stop();
						gloop.flashwareLight.start();
					}
				});
				$('.jsFlashLast').unbind().click(function () {
					gloop.flashwareLight.par.offset = max - gloop.flashwareLight.par.limit;
					gloop.flashwareLight.stop();
					gloop.flashwareLight.start();
				});
				$('.jsFlashFirst').unbind().click(function () {
					gloop.flashwareLight.par.offset = 0;
					gloop.flashwareLight.stop();
					gloop.flashwareLight.start();
				});

				// 	"Lese ich ein Magazin, nehme ich mein Focusyn!" - Bart Simpson
				//	----------------------------------------------------------------------

				$('#flashCurrent').unbind().focusin(function () {
					gloop.flashwareLight.stop();
					$('#flashCurrent').keyup(function (event) {
						if (event.which === 13) {
							$('#flashCurrent').trigger("focusout");
						}
					});

					$('#flashCurrent').focusout(function () {
						let that = parseInt(document.getElementById('xusccl_s1').innerHTML);
						if (this.value <= that && this.value >= 1) {
							gloop.flashwareLight.par.offset = gloop.flashwareLight.par.limit * (this.value - 1);
							if (parseInt(gloop.flashwareLight.par.offset) + parseInt(gloop.flashwareLight.par.limit) > max) {
								gloop.flashwareLight.par.offset = max - parseInt(gloop.flashwareLight.par.limit);
							}
						} else {
							popup.alert('pageNotFound');
						}
						gloop.flashwareLight.stop();
						gloop.flashwareLight.start();
					});
				});

				$('#flashCurrent').keyup(function () {
					gloop.flashwareLight.stop();
					if (event.which === 13) {
						console.log('!!! key up - kk13!!!');
						$('#flashCurrent').focusout(function () {
							let that = parseInt(document.getElementById('xusccl_s1').innerHTML);
							if (this.value <= that && this.value >= 1) {
								gloop.flashwareLight.par.offset = gloop.flashwareLight.par.limit * (this.value - 1);
								if (parseInt(gloop.flashwareLight.par.offset) + parseInt(gloop.flashwareLight.par.limit) > max) {
									gloop.flashwareLight.par.offset = max - parseInt(gloop.flashwareLight.par.limit);
								}
							} else {
								popup.alert('pageNotFound');
							}
							gloop.flashwareLight.stop();
							gloop.flashwareLight.start();
						});
						let that = parseInt(document.getElementById('xusccl_s1').innerHTML);
						if (this.value <= that && this.value >= 1) {
							gloop.flashwareLight.par.offset = gloop.flashwareLight.par.limit * (this.value - 1);
							if (parseInt(gloop.flashwareLight.par.offset) + parseInt(gloop.flashwareLight.par.limit) > max) {
								gloop.flashwareLight.par.offset = max - parseInt(gloop.flashwareLight.par.limit);
							}
						} else {
							popup.alert('pageNotFound');
						}
						gloop.flashwareLight.stop();
						gloop.flashwareLight.start();
					}
				});
				$('#flashCurrent').val(
					Math.ceil(gloop.flashwareLight.par.offset / gloop.flashwareLight.par.limit) + 1
				);

				gloop.flashwareLight.interval = 1000;
			},
			error: function (jqXHR, textStatus, errorThrown) {
				'use strict';
				console.info("FLASHWARE DATA - API problem!\n", textStatus + " :", errorThrown);
				console.info("FLASHWARE DATA - automated retry in 30 seconds");
				gloop.flashwareLight.interval = 30000;
			},
			complete: function () {
				'use strict';
				looping = setTimeout(function () {
					gloop.flashwareLight.start();
				}, gloop.flashwareLight.interval);
			},
			url: function () {
				'use strict';
				let offset = gloop.flashwareLight.par.offset;
				let limit = gloop.flashwareLight.par.limit;
				let search = gloop.flashwareLight.par.search;
				let sort = gloop.flashwareLight.par.sort;
				return api.be8012 + '/xentry/api/v' + api.version + '/flashwareData?offset=' + offset + '&limit=' + limit + '&search=' + search + '&sort=' + sort;
			},
			par: {
				offset: 0,
				limit: 10,
				search: '',
				sort: ''
			},
			context: $('#flashware')[0],
			interval: 1000
		},
		/**
		 * Devices
		 */
		devices: {
			start: function () {
				'use strict';
				gloop.monitoring.stop();
				gloop.monitoringLight.stop();
				gloop.releases.stop();
				gloop.releaseLight.stop();
				gloop.flashware.stop();
				$.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					context: gloop.devices.context,
					url: gloop.devices.url(),
					 //url: 'http://localhost:8100/dummy/api/v1/deviceData',
					success: gloop.devices.success,
					error: gloop.devices.error,
					complete: gloop.devices.complete
				});
			},
			stop: function () {
				'use strict';
				clearTimeout(looping);
			},
			success: function (data) {
				'use strict';
				var rows = [];
				var j = -1;
				var x;
				if (data.Devices.length > 0) {
					for (var i = 0; i < data.Devices.length; i++) {
						rows[++j] = '<tr class="rowDevice" class="clickable" data-toggle="collapse"><td class="colName">';
						rows[++j] = data.Devices[i].ProductName;
						rows[++j] = '</td><td class="colVer">';
						rows[++j] = data.Devices[i].Version;
						rows[++j] = '</td><td class="colSysId">';
						rows[++j] = data.Devices[i].SystemID;
						rows[++j] = '</td><td class="colState">';
						if(data.Devices[i].State === "active")
							rows[++j] = i18next.t('xuscc_td1');
						else
							rows[++j] = i18next.t('xuscc_td0');
						rows[++j] = '</td><td class="colTime">';
						rows[++j] = dateFromUnixtime(data.Devices[i].LastConnected);

						if (data.Devices[i].ProductName == "OpenShell") {
							x = i;
						} else {
							x = data.Devices[i].SystemID;
						}
						if (getCookie(x) == "set") {
							rows[++j] = '</td><td><a data-toggle="collapse" href="#';
							rows[++j] = 'Content' + i;
							rows[++j] = '" aria-expanded="true" aria-controls="colname" onclick="cookieKiller(' + x + ')" class="btn btn-primary"><i class="fas fa-minus-circle fa-1x"></i></a></td></tr>';
							rows[++j] = '<tr class="collapse show" id="';
						} else {
							rows[++j] = '</td><td><a data-toggle="collapse" href="#';
							rows[++j] = 'Content' + i;
							rows[++j] = '" aria-expanded="false" aria-controls="colname" onclick="cookieMaker(' + x + ')" class="btn btn-primary"><i class="fas fa-plus-circle fa-1x"></i></a></td></tr>';
							rows[++j] = '<tr class="collapse" id="';
						}
						rows[++j] = 'Content' + i;
						rows[++j] = '"><td colspan="5"><div class="rds-devicedetails" style="text-align:left; "><p id="xuscc_p21" data="i18n">'+i18next.t('xuscc_p21')+':';
						rows[++j] = data.Devices[i].WindowsVersion;
						rows[++j] = '</p><p>App Type: ';
						rows[++j] = data.Devices[i].ProductType;
						rows[++j] = '</p>';
						rows[++j] = '</div></td></tr> ';
					}

					$('#deviceTable tbody').html(rows.join(''));


					//Sortierung der Tabelle
					//-------------------------------------------------------
					$('.table thead').find('.colSort').each(function () {
						const s = $(this).attr("data-sort");
						let d = "";
						$(this).unbind().click(function () {
							if ($(this).hasClass('active')) {
								$(this).toggleClass('reverse');
							} else {
								$(this).addClass('active');
								$(this).siblings().removeClass('active');
							}

							if ($(this).hasClass('reverse')) {
								d = "DESC";
							} else {
								d = "ASC";
							}
							gloop.devices.par.sort = s + ":" + d;
							var id = $(this).parent().parent().parent().attr('id');
							gloop.devices.stop();
							gloop.devices.start();
						});
					});
				}

				gloop.devices.interval = 10000;
			},
			error: function (jqXHR, textStatus, errorThrown) {
				'use strict';
				console.info("DEVICES DATA - API problem!\n", textStatus + " :", errorThrown);
				console.info("DEVICES DATA - automated retry in 30 seconds");
				gloop.devices.interval = 30000;
			},
			complete: function () {
				'use strict';
				looping = setTimeout(function () {
					gloop.devices.start();
				}, gloop.devices.interval);
			},
			url: function () {
				'use strict';
				let sort = gloop.devices.par.sort;
				return api.be8012 + '/xentry/api/v' + api.version + '/deviceData?sort=' + sort;
				// return 'http://localhost:8100/dummy/api/v1/deviceData?sort=' + sort;
			},
			par: {
				sort: ''
			},
			context: $('#devices')[0],
			interval: 10000
		},

		stop: function () {
			'use strict';
			clearTimeout(looping);
		},
	};

}

/**
 * Activation
 * @type {{backend: {change: {activationState: {to_activate: activation.backend.change.activationState.to_activate}}, check: {keys: activation.backend.check.keys, activationState: activation.backend.check.activationState}}}}
 */
var activation = {
	backend:{
		check:{
			keys: function(k){
				'use strict';
				$.ajax({
					method: 'POST',
					contentType: 'application/json',
					url: api.be8002 +'/activation/api/v'+ api.version +'/activationKey',
					data: JSON.stringify({
						"Key": k
					}),
					success:function(){},
					statusCode:{
						403:function(){
							console.log('ERROR ==>');
							popup.alert('KeyNotCorrect');
						}
					},
					complete:function(){
						setTimeout(function(){
							activation.backend.check.activationState();
						},1000);
					}
				});
			},
			activationState: function(){
				'use strict';
				
				$('#mode_A').hide();
						$('#mode_B').hide();
						$('#mode_C').hide();
						$('#mode_D').hide();
				$.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					url: api.be8002 +'/activation/api/v'+ api.version +'/activationState',
					success:function(data){
						$('.form-control').val('');
						//$('.container').hide();
						
						if(data.Status.toLowerCase() === 'connected'){
							$('#mode_A').show();
						}
						else if(data.Status.toLowerCase() === 'disconnected'){
							$('#mode_B').show();
						}
						else if(data.Status.toLowerCase() === 'pending'){
							$('#mode_C').show();
						}
						else if(data.Status.toLowerCase() === 'due'){
							$('#mode_D').show();
						}
						else if(data.Status.toLowerCase() === 'initialize'){
							document.location.replace("sticker.html");
						}
						else{
							// $('#mode_A').show();
						}
					},
					error:function(jqXHR, textStatus){
						console.log("### no API connection ###");

					},
					complete:function(){
						setTimeout(function(){
							activation.backend.check.activationState();
						}, 600000);
					},
				});
			},
			activationStartSite: function(){
				'use strict';
				$.ajax({
				accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					url: api.be8002 +'/activation/api/v'+ api.version +'/activationState',
					success:function(data){
						if(data.Status.toLowerCase() != 'initialize'){
							document.location.replace("index.html");
						}
					},
					error:function(jqXHR, textStatus){
						console.log("### no API connection ###");

					},
					complete:function(){
						setTimeout(function(){
							activation.backend.check.activationStartSite();
						}, 600000);
					},	
				});
			}

		}
	}
};
		

