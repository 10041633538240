console.info('variables loadet');
var app = {
	name: 'Retail Data Storage',
	screen: {
		w: Math.max(document.documentElement.offsetWidth, document.documentElement.clientWidth, window.innerWidth, 0),
		h: Math.max(document.documentElement.offsetHeight, document.documentElement.clientHeight, 0),
		wh: Math.max(document.documentElement.clientHeight, window.innerHeight, 0),
		y: Math.min($(document).scrollTop(), document.documentElement.scrollTop)
	},
	body: {
		w: Math.max(document.body.offsetWidth, document.body.clientWidth, window.innerWidth, 0),
		h: Math.max(document.body.offsetHeight, document.body.clientHeight, 0)
	},
	timer: []
};
var setup = {
	Company: {},
	Contact: {},
	Network: {},
	Proxy: {},
	Connection: {}
};
var setupEval = {// Evaluierung für die Setup Seite
	checkTab:function(){
		'use strict';
		var classTab0 = document.getElementById('initSetup').getAttribute('class');
		var classTab1 = document.getElementById('statusSetup').getAttribute('class');

		if(classTab0 === 'tab-pane fade show active'){currentTab = 'initSetup';}
		if(classTab1 === 'tab-pane fade show active'){currentTab = 'statusSetup';}

		function gotToTab(thisTab){
			if(thisTab === 'initSetup'){setupEval.startseiteSetup();}
			else if(thisTab === 'statusSetup'){setupEval.statusseiteSetup();}
			else{console.log('no status or initialisation possible');}
		}
		gotToTab(currentTab);
	},
	startseiteSetup:function(){
		'use strict';
		var bottomNew = '<div style="width: 50%;">'+
											'<button type="button" class="btn" id="showStatus" style="width: 200px; float: right;">'+
												'<i class="fas fa-info-circle fa-3x"></i>'+
												'<div id="rdsw_statusShow" style="font-size: 1rem;" data="i18n">Show status</div>'+
											'</button>'+
										'</div>'+
										'<div style="width: 50%">'+
											'<button type="button" class="btn" id="startEinrichtung" style="width: 200px; float: left;">'+
												'<i class="fas fa-caret-right fa-3x"></i>'+
												'<div id="rdsw_start" style="font-size: 1rem;" data="i18n">Start setup</div>'+
											'</button>'+
										'</div>';
		document.getElementById('topbar').innerHTML = '';
		document.getElementById('bottombar').innerHTML = bottomNew;
		document.body.style.backgroundColor = 'black';

		$('#rdsw_statusShow').html(i18next.t('rdsw_statusShow'));
		$('#rdsw_start').html(i18next.t('rdsw_start'));
		
	},
	statusseiteSetup:function(){
		'use strict';
		var bottomNew = '<div style="width: 50%;">'+
											'<button type="button" class="btn btn2" id="hideStatus" style="width: 200px; float: right;">'+
												'<i class="fas fa-info-circle fa-3x"></i>'+
												'<div id="rdsw_statusHide" style="font-size: 1rem;" data="i18n">Hide status</div>'+
											'</button>'+
										'</div>'+
										'<div style="width: 50%">'+
											'<button type="button" class="btn" id="startEinrichtung" style="width: 200px; float: left;">'+
												'<i class="fas fa-caret-right fa-3x"></i>'+
												'<div id="rdsw_start" style="font-size: 1rem;" data="i18n">Start setup</div>'+
											'</button>'+
										'</div>';
		document.getElementById('topbar').innerHTML = topPark;
		document.getElementById('bottombar').innerHTML = bottomNew;
		document.body.style.backgroundColor = '#343a40';

		$('#rdsw_statusHide').html(i18next.t('rdsw_statusHide'));
		$('#rdsw_start').html(i18next.t('rdsw_start'));

		setupEval.data.get.setupData();
		setupEval.data.get.systemData();
		setupEval.data.get.activationStateData();
		setupEval.data.get.upstreamCheck();
				
	},
	par:{
		downloadSliderPosition:{
			cDRString:'',
			dateRangePosition:''
		}
	},
	val:{
		checkStart:function(){
			'use strict';
			let inputCheck_0 = $('#privacyCheck').is(':checked');
			if(!inputCheck_0){
				//alert('Datenschutz muss akzeptiert werden');
				popup.alert("Datenschutz");
				$(document).ready(function(){
					$('.nav a[href="#start"]').tab('show');
					$('#contact').hide();
					$('#network').hide();
					$('#proxy').hide();
					$('#download').hide();
					$('#summary').hide();
					$('#reboot').hide();
				});
			}
			else{
				$('.nav a[href="#network"]').attr('class', 'nav-link');
				$('#contact').show();
				$('#start').hide();
				$('#network').hide();
				$('#proxy').hide();
				$('#download').hide();
				$('#summary').hide();
				$('#reboot').hide();
				$('#wid1').attr('class', 'far fa-check-circle fa-2x d-block wCheck');
			}
		},
		checkContact:function(){
			'use strict';
			let inputCheck_0 = $('#country').val();
			let inputCheck_1 = $('#email').val();
			if(inputCheck_0 === '' || inputCheck_0 === null || !valMail(inputCheck_1)){
				popup.alert("MailundLand");
				$(document).ready(function(){
					$('.nav a[href="#contact"]').tab('show');
					$('#start').hide();
					$('#network').hide();
					$('#proxy').hide();
					$('#download').hide();
					$('#summary').hide();
					$('#reboot').hide();

				});
			}
			else{
				$('.nav a[href="#proxy"]').attr('class', 'nav-link');
				$('#network').show();
				$('#start').hide();
				$('#contact').hide();
				$('#proxy').hide();
				$('#download').hide();
				$('#summary').hide();
				$('#reboot').hide();
				$('#wid2').attr('class', 'far fa-check-circle fa-2x d-block wCheck');
			}
		},
		checkNetwork:function(){
			'use strict';
			let inputCheck_0 = $('#hostname').val();
			let inputCheck_1 = $('#dhcpSwitch').is(':checked');
			if(!inputCheck_1){
				let inputCheck_2 = $('#hostIp').val();
				let inputCheck_3 = $('#subnet').val();
				let inputCheck_4 = $('#gateway').val();
				let inputCheck_5 = $('#dns').val();
				let altdns = true;
				for (var i=1; i < dnsCount; i++) {
					if (!valIP($('#dns'+i).val())) {
						altdns = false;
						console.log("wrong")
					}
				}

				if(valHost(inputCheck_0) && valIP(inputCheck_2) && valNetmask(inputCheck_3) && valIP(inputCheck_4) && valIP(inputCheck_5) && altdns){
					$('.nav a[href="#download"]').attr('class', 'nav-link');
					$('#proxy').show();
					$('#start').hide();
					$('#contact').hide();
					$('#network').hide();
					$('#download').hide();
					$('#summary').hide();
					$('#reboot').hide();
					$('#wid3').attr('class', 'far fa-check-circle fa-2x d-block wCheck');
				}
				else{
					//alert('Hostname, IP-Adresse, Netzmaske, Gateway bitte korrekt angeben');
					popup.alert("Setup_EingabeError");
					$(document).ready(function(){
						$('.nav a[href="#network"]').tab('show');
						$('#start').hide();
						$('#contact').hide();
						$('#proxy').hide();
						$('#download').hide();
						$('#summary').hide();
						$('#reboot').hide();
					});
				}
			}
			else{
				if(!valHost(inputCheck_0)){
					//alert('Hostname, IP-Adresse, Netzmaske, Gateway bitte korrekt angeben');
					popup.alert("Setup_EingabeError");
					$(document).ready(function(){
						$('.nav a[href="#network"]').tab('show');
						$('#start').hide();
						$('#contact').hide();
						$('#proxy').hide();
						$('#download').hide();
						$('#summary').hide();
						$('#reboot').hide();
					});
				}
				else{
					$('.nav a[href="#download"]').attr('class', 'nav-link');
					$('#proxy').show();
					$('#start').hide();
					$('#contact').hide();
					$('#network').hide();
					$('#download').hide();
					$('#summary').hide();
					$('#reboot').hide();
					$('#wid3').attr('class', 'far fa-check-circle fa-2x d-block wCheck');
				}
			}
		},
		checkProxy:function(){
			'use strict';
			let inputCheck_0 = $('#proxySwitch').is(':checked');
			if(inputCheck_0){
				let inputCheck_1 = $('#proxyIp').val();
				let txtIC1Length = String(document.getElementById('proxyIp').value).length;
				let inputCheck_2 = $('#port').val();
				let inputCheck_3 = $('#authSwitch').is(':checked');
				if(inputCheck_3){
					let inputCheck_4 = $('#username').val();
					let inputCheck_5 = $('#password').val();
					let inputCheck_6 = $('#repeat').val();
					if((valIP(inputCheck_1)||valHost(inputCheck_1)) && txtIC1Length<=255 && valPort(inputCheck_2) && inputCheck_4 !== '' && inputCheck_5 !== '' && inputCheck_6 !== '' && inputCheck_5 === inputCheck_6){
						$('.nav a[href="#summary"]').attr('class', 'nav-link');
						$('#download').show();
						$('#start').hide();
						$('#contact').hide();
						$('#network').hide();
						$('#proxy').hide();
						$('#summary').hide();
						$('#reboot').hide();
						$('#wid4').attr('class', 'far fa-check-circle fa-2x d-block wCheck');
					}
					else{
						//alert('Proxy: IP-Adresse und Port bitte richtig angeben \n\n Benutzername, Passwort, Wiederholung bitte auch');
						popup.alert("ProxyLogin")
						$(document).ready(function(){
							$('.nav a[href="#proxy"]').tab('show');
							$('#start').hide();
							$('#contact').hide();
							$('#network').hide();
							$('#download').hide();
							$('#summary').hide();
							$('#reboot').hide();
						});
					}
				}
				else if(!inputCheck_3 && (valIP(inputCheck_1)||valHost(inputCheck_1)) && valPort(inputCheck_2) && txtIC1Length<=255){
					$('.nav a[href="#summary"]').attr('class', 'nav-link');
					$('#download').show();
					$('#start').hide();
					$('#contact').hide();
					$('#network').hide();
					$('#proxy').hide();
					$('#summary').hide();
					$('#reboot').hide();
					$('#wid4').attr('class', 'far fa-check-circle fa-2x d-block wCheck');
				}
				else{
					//alert('Proxy: IP-Adresse und Port bitte richtig angeben');
					popup.alert("ProxyIpPort");
					$(document).ready(function(){
						$('.nav a[href="#proxy"]').tab('show');
						$('#start').hide();
						$('#contact').hide();
						$('#network').hide();
						$('#download').hide();
						$('#summary').hide();
						$('#reboot').hide();
					});
				}
			}
			else{
				$('.nav a[href="#summary"]').attr('class', 'nav-link');
				$('#download').show();
				$('#start').hide();
				$('#contact').hide();
				$('#network').hide();
				$('#proxy').hide();
				$('#summary').hide();
				$('#reboot').hide();
				$('#wid4').attr('class', 'far fa-check-circle fa-2x d-block wCheck');
			}
		},
		checkDownload:function(){
			'use strict';
			if(typeof document.getElementById('timezone').options[document.getElementById('timezone').selectedIndex] !== 'undefined' ){
				let inputCheck_0 = document.getElementById('timezone').options[document.getElementById('timezone').selectedIndex].innerHTML;
				if(inputCheck_0 === ''){
					//alert('Zeitzone angeben');
					popup.alert("Zeitzone");
					$(document).ready(function(){
						$('.nav a[href="#download"]').tab('show');
						$('#start').hide();
						$('#contact').hide();
						$('#network').hide();
						$('#proxy').hide();
						$('#summary').hide();
						$('#reboot').hide();
					});
				}
				else{
				$('.nav a[href="#reboot"]').attr('class', 'nav-link');
				$('#summary').show();
				$('#start').hide();
				$('#contact').hide();
				$('#network').hide();
				$('#proxy').hide();
				$('#download').hide();
				$('#reboot').hide();
				$('#wid5').attr('class', 'far fa-check-circle fa-2x d-block wCheck');
			}
			}
			else {
				document.getElementById('timezone').options[document.getElementById('timezone').selectedIndex] = {};
				document.getElementById('timezone').options[document.getElementById('timezone').selectedIndex].innerHTML = 'Keine Zeitzone auswählbar';
				setupEval.val.checkDownload();
			}
		}
	},
	data:{
		get:{
			firstPage:function(){
				'use strict';
				var firstPage = $.ajax({
					// method: 'GET',
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					url: api.be8001 +'/setup/api/v'+ api.version +'/setupData',
					success: function(data){
						// Anfordern der Zeitzonen Elemente
						setupEval.data.get.timeZones();
						// Setzen der Länder
						setupEval.data.get.countries();
						// Ausfüllen aller Felder
						setupEval.data.set.setupElements(data);
						if(firstLoading_Wizard){
							firstLoading_Wizard = false;
							setupEval.data.set.setupFirstPage(data);
						}
						console.log("SETUP DATA (firstPage) - API response received");
					},
					error: function(){
						console.log("SETUP DATA (firstPage) - No response from API!");
					},
					complete: function(){}
				})
			},
			setupData:function(){
				'use strict';
				var setupData = $.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					url: api.be8001 +'/setup/api/v'+ api.version +'/setupData',
					success: function(data){
						console.log("SETUP DATA - API response recieved");
						var roh = setupData.responseJSON;

						var wiz_Host = document.getElementById('wiz_Host');
						var wiz_IP = document.getElementById('wiz_IP');
						var wiz_Gate = document.getElementById('wiz_Gate');
						var wiz_DNS = document.getElementById('wiz_DNS');
						var wiz_Proxy = document.getElementById('wiz_Proxy');
						var wiz_ProxIP = document.getElementById('wiz_ProxIP');
						var wiz_Port = document.getElementById('wiz_Port');
						var wiz_Auth = document.getElementById('wiz_Auth');
						// var wiz_User = document.getElementById('wiz_User');
						// var wiz_PW = document.getElementById('wiz_PW');

						// unter 'System'
						wiz_Host.innerHTML = roh.Network.Hostname;
						if(roh.Network.DHCP) {
							if(roh.Network.Eth0IP !=null)
							{
							wiz_IP.innerHTML = roh.Network.Eth0IP;
							wiz_Gate.innerHTML = "DHCP";
							wiz_DNS.innerHTML = "DHCP";
							}
							else{
							wiz_IP.innerHTML ="";
							wiz_Gate.innerHTML = "";
							wiz_DNS.innerHTML = "";		
							}
						} else {
							wiz_IP.innerHTML = roh.Network.IP;
							wiz_Gate.innerHTML = roh.Network.Gateway;
							wiz_DNS.innerHTML = roh.Network.DNS;
						}

						// unter 'Proxyserver'
						if(roh.Proxy.Enable){
							wiz_Proxy.innerHTML = roh.Proxy.Enable ;
							wiz_ProxIP.innerHTML = roh.Proxy.IP ;
							wiz_Port.innerHTML = roh.Proxy.Port ;
							if(roh.Proxy.AuthEnable){
								wiz_Auth.innerHTML = roh.Proxy.AuthEnable ;
								// wiz_User.innerHTML = roh.Proxy.Username ;
								// wiz_PW.innerHTML = roh.Proxy.Password ;
							}
							else{
								wiz_Auth.innerHTML = '<span class="rdsw_s-proxy_nay" data="i18n">not in use</span>' ;
								// wiz_User.innerHTML = '<span class="rdsw_s-proxy_nay" data="i18n">not in use</span>' ;
								// wiz_PW.innerHTML = '<span class="rdsw_s-proxy_nay" data="i18n">not in use</span>' ;
							}
						}
						else{
							wiz_Proxy.innerHTML = '<span class="rdsw_s-proxy_nay" data="i18n">not in use</span>' ;
							wiz_ProxIP.innerHTML = '<span class="rdsw_s-proxy_nay" data="i18n">not in use</span>' ;
							wiz_Port.innerHTML = '<span class="rdsw_s-proxy_nay" data="i18n">not in use</span>';
							wiz_Auth.innerHTML = '<span class="rdsw_s-proxy_nay" data="i18n">not in use</span>' ;
							// wiz_User.innerHTML = '<span class="rdsw_s-proxy_nay" data="i18n">not in use</span>';
							// wiz_PW.innerHTML = '<span class="rdsw_s-proxy_nay" data="i18n">not in use</span>' ;
						}
					},
					error: function(){
						console.log("SETUP DATA - No response from API!");
					},
					complete: function(){}
				});
			},
			systemData:function(){
				'use strict';
				var systemData = $.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					url: api.be8001 +'/setup/api/v'+ api.version +'/systemData',
					success: function(data){
						console.log("SYSTEM DATA - API response recieved:", data);
						document.getElementById('wiz_SystemID').innerHTML = data.Device.SystemID ;
						document.getElementById('wiz_MAC').innerHTML = data.Device.MAC;
					},
					error: function(){
						console.log("SYSTEM DATA - No response from API!");
					},
					complete: function(){}
				});
			},
			activationStateData:function(){
			var ACTIVATIONData = $.ajax({
				accepts: 'application/json; charset=UTF-8',
				dataType: 'json',
				url: api.be8002 +'/activation/api/v'+ api.version +'/activationState',
				success: function(data){
					if(data.Status.toLowerCase() === 'activated'){
						document.getElementById('wiz_Status').innerHTML = '<span id="rdsw_s-x1" data="i18n"> Aktivierung durchgeführt</span>';
					}
					else {
						document.getElementById('wiz_Status').innerHTML = '<i class="fas fa-exclamation-triangle fa-1x" style="color: rgba(230,210,20,0.75)"></i><span id="rdsw_s-x0" data="i18n"> Warte auf Aktivierung</span>';
					}
					console.log("ACTIVATION DATA - API response recieved:", data);
											
				},
				error: function(){
					console.log("SYSTEM DATA - No response from API!");
				},
				complete: function(){}
			});
			},
			upstreamCheck:function(){
				'use strict';
				var upstreamCheck = $.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					url: api.be8001 +'/setup/api/v'+ api.version +'/upstreamState',
					success: function(data){
						var vpn = document.getElementById('wiz_VPN') ;
						var xen = document.getElementById('wiz_XENTRY') ;
						var xeni = document.getElementById('wiz_XENTRY-INT') ;
						var cdn = document.getElementById('wiz_CDN') ;
						if(data.UpstreamState === "Error: Could not connect to watcher service."){
							console.log("UPSTREAMSTATE CHECK - API response recieved:", data);
							vpn.innerHTML = '<i class="text-danger fas fa-times-circle fa-md"></i>'+
															'<span class="rdsw_s-svr_con_nay" data="i18n"> not connected</span>';
							xen.innerHTML = '<i class="text-danger fas fa-times-circle fa-md"></i>'+
															'<span class="rdsw_s-svr_con_nay" data="i18n"> not connected</span>';
							xeni.innerHTML = '<i class="text-danger fas fa-times-circle fa-md"></i>'+
															'<span class="rdsw_s-svr_con_nay" data="i18n"> not connected</span>';
							cdn.innerHTML = '<i class="text-danger fas fa-times-circle fa-md"></i>'+
															'<span class="rdsw_s-svr_con_nay" data="i18n"> not connected</span>';
						}
						else{
							var vpnTested = false;
							for (var [url, state] of Object.entries(data.UpstreamState)) {
								if (!vpnTested && (url === '10.7.0.1:80' || url === '10.200.0.1:80')) {
									if(state){
										vpn.innerHTML = '<i class="text-success fas fa-check-circle fa-md"></i>'+
																		 '<span class="rdsw_s-svr_con_yay" data="i18n"> connected</span>';
										vpnTested = true;
									}
									else{
										vpn.innerHTML = '<i class="text-danger fas fa-times-circle fa-md"></i>'+
																		 '<span class="rdsw_s-svr_con_nay" data="i18n"> not connected</span>';
									}
								}
								else if (url === 'api-em1.emea.corpinter.net:443'){
									if(state){
										xen.innerHTML = '<i class="text-success fas fa-check-circle fa-md"></i>'+
																		 '<span class="rdsw_s-svr_con_yay" data="i18n"> connected</span>';
									}
									else{
										xen.innerHTML = '<i class="text-danger fas fa-times-circle fa-md"></i>'+
																		 '<span class="rdsw_s-svr_con_nay" data="i18n"> not connected</span>';
									}
								}
								else if (url === 'api-em1-int.emea.corpinter.net:443'){
									if(state){
										xeni.innerHTML = '<i class="text-success fas fa-check-circle fa-md"></i>'+
																		 '<span class="rdsw_s-svr_con_yay" data="i18n"> connected</span>';
									}
									else{
										xeni.innerHTML = '<i class="text-danger fas fa-times-circle fa-md"></i>'+
																		 '<span class="rdsw_s-svr_con_nay" data="i18n"> not connected</span>';
									}
								}
								else if (url === 'daimlerdownloads-a.akamaihd.net:443'){
									if(state){
										cdn.innerHTML = '<i class="text-success fas fa-check-circle fa-md"></i>'+
																		 '<span class="rdsw_s-svr_con_yay" data="i18n"> connected</span>';
									}
									else{
										cdn.innerHTML = '<i class="text-danger fas fa-times-circle fa-md"></i>'+
																		 '<span class="rdsw_s-svr_con_nay" data="i18n"> not connected</span>';
									}
								}
							}
						}
						updateContent();
					},
					error: function(){
						console.log("UPSTREAM STATE CHECK - No response from API!");
					},
					complete: function(){}
				});
			},
			timeZones:function(){
				'use strict';
				var timeRequest = $.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					url: api.be8001 +'/setup/api/v'+ api.version +'/timeZones',
					success:function(data){
						var zone = data.TimeZones;
						$('#timezone').empty();
						Object.keys(zone).forEach(function (name) {
							if(zone[name]>= 1) {
								$('#timezone').append('<option value="'+ zone[name] +'">'+ name +' UTC +'+ zone[name] + '</option>');
							} else {
								$('#timezone').append('<option value="' + zone[name] + '">' + name + ' UTC ' + zone[name] + '</option>');
							}
						});

						console.log("TIME ZONES - API response received:", timeRequest.responseJSON);
					},
					error:function(){
						console.log("TIMEZONES - No response from API!");
					},
					complete:function(){

					}
				})
			},
			countries:function(){
				var countries = getCountries();
				let language = getCookie("lng");
				if(language){
					var names = countries[language].translation;
				} else {
					var names = countries["en"].translation;
				}
				$('#country').empty();
				Object.keys(names).forEach(function (name) {
					$('#country').append('<option class="'+ name +'" data="i18n" value="'+ name +'">'+ names[name] +'</option>');
				});
				sortCountries()
			},
			downloadSliderPosition:function(){
				'use strict';
				var rawdata = {};
				var currentDateRate = '';

				$.ajax({
					accepts: 'application/json; charset=UTF-8',
					dataType: 'json',
					url: api.be8001 +'/setup/api/v'+ api.version +'/setupData',
					success: function(data){
						rawdata = data;
						currentDateRate = rawdata.Connection.TransferRate;
						console.log("SETUP DATA (Slider Position) - API response received");
						setupEval.data.set.sliderPosition(currentDateRate, rawdata);
					},
					error: function(){
						rawdata = {Connection:{TransferRate:8192}};
						currentDateRate = rawdata.Connection.TransferRate;
						console.log("SETUP DATA (Slider Position) - No response from API!");
						setupEval.data.set.sliderPosition(currentDateRate, rawdata);
					}
				});
			}
		},
		post:{
			setupData:function(setup){
				'use strict';
				$.ajax({
					method: 'POST',
					contentType: 'application/json',
					url: api.be8001 +'/setup/api/v'+ api.version +'/setupData',
					data: JSON.stringify(setup),
					success:function(msg){
						$(this).removeClass('was-validated');
						$(this).find('.form-control').val('');
						popup.alert('rebootAlert');
						$('#rebootMessage').html(i18next.t('rebootMessage'));
						console.info('Setup sent!', msg);

						let t = 120;
						let progress = 0;
						let step = 100/t;
						clearInterval(rebootCountdown);
						var rebootCountdown = setInterval(function () {
							progress = progress+step;
							t--;
							if (t > 0) {
								$('#rebootProgress').css('width', progress+'%');
								$('#rebootProgress').attr('aria-valuenow', progress);
							} else {
								clearInterval(rebootCountdown);
								// redirect zu startseite nach neustart
								setTimeout(function(){
									// window.location = window.location.protocol+"//172.31.255.101:8002"+window.location.pathname;
									// window.location = window.location.protocol+"//"+window.location.hostname+":8002"+window.location.pathname;
									// window.location.reload(true);
									// window.location.assign("https://localhost/activation");
									window.location.reload();
								}, 3000);
							}

						}, 1000);
					},
					error:function(jqXHR, textStatus){
						popup.alert('rebootAlertFail');
						$('#rebootMessage').html(i18next.t('rebootMessageFail'));
						console.log('SETUP DATA - API Request failed:', textStatus);
					},
					complete:function(){}
				})
			}
		},
		set:{
			setupElements:function(data){
				'use strict';
				// Set contact data
				$('#company').val(data.Company.CompanyName);
				$('#companyId').val(data.Company.CompanyNumber);
				$('#address').val(data.Company.Address);
				$('#zipcode').val(data.Company.PostalCode);
				$('#place').val(data.Company.City);
				$('#country').val(data.Company.CountryISO);
				$('#salutation').val(data.Contact.Salutation);
				$('#name').val(data.Contact.FirstName);
				$('#surname').val(data.Contact.LastName);
				$('#email').val(data.Contact.EMail);
				$('#phone').val(data.Contact.Phone);

				// Set network data
				$('#hostname').val(data.Network.Hostname);
				$('#dhcpSwitch').prop('checked', data.Network.DHCP).trigger("change");
				// TODO add altDns fields
				if (data.Network.DHCP) {
					$('#hostIp').val('');
					$('#subnet').val('');
					$('#gateway').val('');
					$('#dns').val('');
				} else if (!data.Network.DHCP && !dnsListPrinted) {
					$('#hostIp').val(data.Network.IP);
					$('#subnet').val(data.Network.NetMask);
					$('#gateway').val(data.Network.Gateway);
					$('#dns').val(data.Network.DNS);
					let i = 0, $clone;
					while(i < data.Network.AltDNS.length){
						$clone = $('#dnsCopy').clone(true).removeAttr('id').appendTo('#dnsList');
						$clone.find('input').attr('id', 'dns'+ i).addClass('altDns');
						$clone.find('input').val(data.Network.AltDNS[i]);
						$clone.find('label').attr('for', 'dns'+ (i+1));
						$clone.find('label').html('alt. DNS');
						$clone.focus();
						i++;
						dnsCount = i+1;
						if(dnsCount>=4){$('#addDns').css('display','none');}
					}
				}
				dnsListPrinted = true;

				// Set proxy data
				$('#proxySwitch').prop('checked', data.Proxy.Enable).trigger("change");
				$('#proxyIp').val(data.Proxy.IP);
				$('#port').val(data.Proxy.Port);
				$('#authSwitch').prop('checked', data.Proxy.AuthEnable).trigger("change");
				$('#username').val(data.Proxy.Username);
				$('#password').val(data.Proxy.Password);
				$('#repeat').val(data.Proxy.Password);

				// $('#datarate option[value='+ data.Connection.TransferRate +']').prop('selected', true);
				// $('#dateRateRange').val(data.Connection.TransferRate);
				var times = data.Connection.DownloadTimes;
				// Object.entries(times).forEach(e => console.log(`Tag=${e}  Zeiten=${times[e]}`));
				// Object.keys(times).forEach(e => console.log(`Tag=${e}  Zeiten=${times[e]}`));

				// Set time data
				var tzInDB = data.Connection.TimeZone;
				$('#timezone option:contains('+ tzInDB +')').prop('selected', true);
				Object.keys(times).forEach(function (range) {
						// console.log(range);
						times[range].forEach(function (time) {
							// console.log(time);
							var s = time[0].split(":");
							var start = parseInt(s[0]) * 60 + parseInt(s[1]) + 30;
							var e = time[1].split(":");
							var end = parseInt(e[0]) * 60 + parseInt(e[1]) + 30;
							for (var i = start; i < end; i += 30) {
								$('#'+ range +'Range').find('td[data-time='+ i +']').addClass('timeSet');
							}
						});
					});
			},
			setupFirstPage:function(data){
				'use strict';
				if(data.Contact.EMail !== ''){
					setupEval.statusseiteSetup();
					$('#initSetup').attr('class', 'tab-pane fade');
					$('#initSetup').hide();
					$('#statusSetup').show();
					$('#statusSetup').attr('class', 'tab-pane fade show active');
					setupInit();
				}
				else if(data.Contact.EMail === ''){
					setupEval.startseiteSetup();
					$('#statusSetup').attr('class', 'tab-pane fade');
					$('#statusSetup').hide();
					$('#initSetup').show();
					$('#initSetup').attr('class', 'tab-pane fade show active');
					setupEval.checkTab();
					setupInit();
				}
				else{
					setupEval.startseiteSetup();
					$('#statusSetup').attr('class', 'tab-pane fade');
					$('#statusSetup').hide();
					$('#initSetup').show();
					$('#initSetup').attr('class', 'tab-pane fade show active');
					setupEval.checkTab();
					setupInit();
				}
			},
			summaryElements:function(){//Setzen der Elemente der Zusammenfassung
				'use strict';
				// Set contact data
				$('#summaryBox .companySummary').html($('#company').val());
				$('#summaryBox .companyIdSummary').html($('#companyId').val());
				$('#summaryBox .addressSummary').html($('#address').val());
				$('#summaryBox .zipcodeSummary').html($('#zipcode').val());
				$('#summaryBox .placeSummary').html($('#place').val());
				$('#summaryBox .countrySummary').html($('#country').val());
				$('#summaryBox .salutationSummary').html($('#salutation option:selected').html());
				$('#summaryBox .fullnameSummary').html($('#name').val() +' '+ $('#surname').val());
				$('#summaryBox .emailSummary').html($('#email').val());
				$('#summaryBox .phoneSummary').html($('#phone').val());

				// Set network data
				$('#summaryBox .hostnameSummary').html($('#hostname').val());
				if ($('#dhcpSwitch').is(':checked')) {
					$('#summaryBox .dhcpSummary').html(i18next.t('rdsw_s17'));
					$('#summaryBox .hostIpSummary').html($('#wiz_IP').text());
					$('#summaryBox .subnetSummary').empty();
					$('#summaryBox .gatewaySummary').empty();
					$('#summaryBox .dnsSummary').empty();
				} else {
					$('#summaryBox .dhcpSummary').html(i18next.t('rdsw_s18'));
					$('#summaryBox .hostIpSummary').html($('#hostIp').val());
					$('#summaryBox .subnetSummary').html($('#subnet').val());
					$('#summaryBox .gatewaySummary').html($('#gateway').val());
					// $('#summaryBox .dns').html($('#dns').val());
					let dnss = $('#dns').val();
					$('.altDns').each(function () {
						dnss += ', '+ $(this).val();
					});
					$('#summaryBox .dnsSummary').html(dnss);
				}


				// Set proxy data
				if ($('#proxySwitch').is(':checked')) {
					$('#summaryBox .proxySummary').html(i18next.t('rdsw_s17'));
					$('#summaryBox .proxyIpSummary').html($('#proxyIp').val());
					$('#summaryBox .portSummary').html($('#port').val());
					if ($('#authSwitch').is(':checked')) {
						$('#summaryBox .authSummary').html(i18next.t('rdsw_s17'));
						$('#summaryBox .usernameSummary').html($('#username').val());
						if ($('#password').val()) {
							$('#summaryBox .passwordSummary').html(i18next.t('rdsw_s19'));
						} else {
							$('#summaryBox .passwordSummary').html(i18next.t('rdsw_s20'));
						}
					} else {
						$('#summaryBox .authSummary').html(i18next.t('rdsw_s18'));
						$('#summaryBox .usernameSummary').empty();
						$('#summaryBox .passwordSummary').empty()
					}

				} else {
					$('#summaryBox .proxySummary').html(i18next.t('rdsw_s18'));
					$('#summaryBox .proxyIpSummary').empty();
					$('#summaryBox .portSummary').empty();
					$('#summaryBox .authSummary').empty();
					$('#summaryBox .usernameSummary').empty();
					$('#summaryBox .passwordSummary').empty();
				}


				// Set time data
				$('#summaryBox .timezoneSummary').html($('#timezone option:selected').html());
				$('#summaryBox .datarateSummary').html(document.getElementById('dateRateValue').innerHTML);
			},
			sliderPosition:function(currentDateRate, rawdata){
				'use strict';
				let newDateRateValue = "";
						 if(currentDateRate === 6144){setupEval.par.downloadSliderPosition.cDRString="48 "+i18next.t('rdsw_p15')+" (6 "+i18next.t('rdsw_p16')+")"; setupEval.par.downloadSliderPosition.dateRangePosition="0"; newDateRateValue=6144;}
				else if(currentDateRate === 8192){setupEval.par.downloadSliderPosition.cDRString="64 "+i18next.t('rdsw_p15')+" (8 "+i18next.t('rdsw_p16')+")"; setupEval.par.downloadSliderPosition.dateRangePosition="1"; newDateRateValue=8192;}
				else if(currentDateRate === 10240){setupEval.par.downloadSliderPosition.cDRString="80 "+i18next.t('rdsw_p15')+" (10 "+i18next.t('rdsw_p16')+")"; setupEval.par.downloadSliderPosition.dateRangePosition="2"; newDateRateValue=10240;}
				else if(currentDateRate === 12288){setupEval.par.downloadSliderPosition.cDRString="96 "+i18next.t('rdsw_p15')+" (12 "+i18next.t('rdsw_p16')+")"; setupEval.par.downloadSliderPosition.dateRangePosition="3"; newDateRateValue=12288;}
				else if(currentDateRate === 14336){setupEval.par.downloadSliderPosition.cDRString="112 "+i18next.t('rdsw_p15')+" (14 "+i18next.t('rdsw_p16')+")"; setupEval.par.downloadSliderPosition.dateRangePosition="4"; newDateRateValue=14336;}
				else if(currentDateRate === 16384){setupEval.par.downloadSliderPosition.cDRString="128 "+i18next.t('rdsw_p15')+" (16 "+i18next.t('rdsw_p16')+")"; setupEval.par.downloadSliderPosition.dateRangePosition="5"; newDateRateValue=16384;}
				else if(currentDateRate === 18432){setupEval.par.downloadSliderPosition.cDRString="144 "+i18next.t('rdsw_p15')+" (18 "+i18next.t('rdsw_p16')+")"; setupEval.par.downloadSliderPosition.dateRangePosition="6"; newDateRateValue=18432;}
				else if(currentDateRate === 0){setupEval.par.downloadSliderPosition.cDRString="no limit"; setupEval.par.downloadSliderPosition.dateRangePosition="7"; newDateRateValue=0;}
				document.getElementById('dateRateValue').innerHTML = setupEval.par.downloadSliderPosition.cDRString;
				document.getElementById('dateRateRange').value = setupEval.par.downloadSliderPosition.dateRangePosition;
			}
		}
	}
};
var popup = {
	build:function(state,btns){
		'use strict';
		$('.popup').css('display', 'block');
		//	Umrandung setzen
		if (typeof state !== 'undefined') {
			if (state === 'fail') {//	rot - Fehler
				$('#popupAlert').css('border', '8px solid #ed6d6b');
			} else if (state === 'warn') {//	gelb - Warnung
				$('#popupAlert').css('border', '8px solid #fbe06d');
			} else if (state === 'okay') {//	grün - Erfolg
				$('#popupAlert').css('border', '8px solid #31ad71');
			} else {// blau - undefiniert
				$('#popupAlert').css('border', '8px solid #258cb8');
			}
		} else {// blau - undefiniert
			$('#popupAlert').css('border', '8px solid #258cb8');
		}

		//	Buttons setzen
		if (typeof btns !== 'undefined') {
			if (btns === 1) {
				$('.ida-singleButton').css('display', 'inline-block');
				$('.ida-doubleButton').css('display', 'none');
				$('.ida-tripleButton').css('display', 'none');
			} else if (btns === 2) {
				$('.ida-singleButton').css('display', 'none');
				$('.ida-doubleButton').css('display', 'inline-block');
				$('.ida-tripleButton').css('display', 'none');
			} else if (btns === 3) {
				$('.ida-singleButton').css('display', 'none');
				$('.ida-doubleButton').css('display', 'none');
				$('.ida-tripleButton').css('display', 'inline-block');
			}
		} else {
			$('.ida-singleButton').css('display', 'none');
			$('.ida-doubleButton').css('display', 'none');
			$('.ida-tripleButton').css('display', 'inline-block');
		}
	},
	alert:function(msgID){
		'use strict';
		switch (msgID) {
			case 'rebootAlert':
				popup.build('okay', 1);
				$('#ida_headline').html(i18next.t('rdsw_ida_headline_success'));
				$('#ida_headIcon').attr('class', 'fas fa-check-circle mb-okay');
				$('#ida_text').html(i18next.t('rdsw_ida_text_success'));
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'rebootAlertFail':
				popup.build('fail', 1);
				$('#ida_headline').html(i18next.t('rdsw_ida_headline_failure'));
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html(i18next.t('rdsw_ida_text_failure'));
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'activationWK':
				popup.build('fail', 1);
				$('#ida_headline').html(i18next.t('rdsa_ida_KeyNotMatch'));
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html(i18next.t('rdsa_ida_KeyNotMatchText'));
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'KeyNotCorrect':
				popup.build('fail', 1);
				$('#ida_headline').html(i18next.t('rdsa_ida_WrongKey'));
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html(i18next.t('rdsa_ida_WrgongKeyTest'));
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'StartKeyValid':
				popup.build('fail', 1);
				$('#ida_headline').html(i18next.t('xusccl_ida_KeyInvalid'));
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html('');
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'settingsSaved':
				popup.build('okay', 1);
				$('#ida_headline').html(i18next.t('rdsc_ida_Success'));
				$('#ida_headIcon').attr('class', 'fas fa-check-circle mb-okay');
				$('#ida_text').html(i18next.t('rdsc_ida_SettingsSuccess'));
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'pageNotFound':
				popup.build('fail', 1);
				$('#ida_headline').html(i18next.t('xuscc_ida_PageNotFound'));
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html(i18next.t('xuscc_ida_PageNotFoundText'));
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'Zeitzone':
				popup.build('fail', 1);
				$('#ida_headline').html(i18next.t('rdsw_ida_ZeitZone'));
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html('');
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'ProxyIpPort':
				popup.build('fail', 1);
				$('#ida_headline').html(i18next.t('rdsw_ida_ProxyError'));
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html(i18next.t('rdsw_ida_ProxyIpPortText'));
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'ProxyLogin':
				popup.build('fail', 1);
				$('#ida_headline').html(i18next.t('rdsw_ida_ProxyError'));
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html(i18next.t('rdsw_ida_ProxyBenutzerText'));
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'Hostname':
				popup.build('fail', 1);
				$('#ida_headline').html(i18next.t('rdsw_ida_HostnameErforderlich'));
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html('');
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'Setup_EingabeError':
				popup.build('fail', 1);
				$('#ida_headline').html(i18next.t('rdsw_ida_EingabeFehler'));
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html(i18next.t('rdsw_ida_EingabeFehlerText'));
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'MailundLand':
				popup.build('fail', 1);
				$('#ida_headline').html(i18next.t('rdsw_ida_EingabeFehler'));
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html(i18next.t('rdsw_p6'));
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			case 'Datenschutz':
				popup.build('fail', 1);
				$('#ida_headline').html(i18next.t('rdsw_ida_Datenschutz'));
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html('');
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
				break;
			default:
				popup.build('fail', 1);
				$('#ida_headline').html('The alert message does not exist');
				$('#ida_headIcon').attr('class', 'fas fa-exclamation-triangle mb-fail');
				$('#ida_text').html('This is not the alert message you want to see?');
				$('.ida-singleButton').unbind().click(function () {
					$('.popup').css('display', 'none');
				});
		}
	}
}

var initleft = false;
var initY = app.screen.wh;
var sort = "?sort=";
var newDateRateValue = '';
var searchTerm ,offset, sLimit;
let firstLoading_Wizard = true;
let dnsListPrinted = false;
let dnsCount = 1;
let lng;
let trapLoaded = false;
var topPark;
var bottomPark;
var currentTab = '';


// console.table({	protocol: window.location.protocol,
// 								host: window.location.hostname,
// 								port: window.location.port,
// 								path: window.location.pathname
// 							});
if(typeof variante !== 'undefined'){
	if(variante === 'xentry'){
		//	================================
		//	===== Für Betrieb auf LRDS =====
		//	================================
		var api = {
			version: '1',
			backend: '',
			be8001: '',	// setup
			be8002: '',	// activation
			be8003: '',	// config
			be8012: ''	// xentry
		};
		if(window.location.host === "localhost:3000"){
			var beginURL = "http://127.0.0.1";
			var api = {
				version: '1',
				backend: '',
				be8001: beginURL+':8001',	// setup
				be8002: beginURL+':8002',	// activation
				be8003: beginURL+':8003',	// config
				be8012: beginURL+':8012'		// xentry
			};
		}
		console.info('VARIANTE XENTRY');
	}
	else if(variante === 'light'){
		//	===============================================
		//	===== Für Betrieb auf LOCAL & LIGHT ===========
		//	===============================================
		//	*> LOCAL & LIGHT -> beginURL = 127.0.0.1 <=====
		//	*> LOCAL -> alle ssh aufbauen <================
		//	===============================================
		var beginURL = "http://127.0.0.1";
		var api = {
			version: '1',
			backend: '',
			be8001: beginURL+':8001',	// setup
			be8002: beginURL+':8002',	// activation
			be8003: beginURL+':8003',	// config
			be8012: beginURL+':8012'	// xentry
		};
		console.info('VARIANTE LIGHT');
	}
	else{
		//	====================================
		//	======== Für Betrieb auf VM ========
		//	====================================
		//	*> beginURL = 192.168.180.212 <=====
		//	====================================
		var beginURL = "http://192.168.180.212";
		var api = {
			version: '1',
			backend: '',
			be8001: beginURL,	// setup
			be8002: beginURL,	// activation
			be8003: beginURL,	// config
			be8012: beginURL	// xentry
		};
		console.log('VARIANTE DEFINED - if-else thrown');
	}
}
else{
	// ================================
	// ===== Für Betrieb auf LRDS =====
	// ================================
	var api = {
		version: '1',
		backend: '',
		be8001: '',	// setup
		be8002: '',	// activation
		be8003: '',	// config
		be8012: ''	// xentry
	};
	console.info('VARIANTE UNDEFINED - else thrown');

	if(window.location.host === "localhost:3000"){
		var beginURL = "http://127.0.0.1";
		var api = {
			version: '1',
			backend: '',
			be8001: beginURL+':8001',	// setup
			be8002: beginURL+':8002',	// activation
			be8003: beginURL+':8003',	// config
			be8012: beginURL+':8012'	// xentry
		};
	}
}


/*
var beginURL = "http://192.168.180.212";
var api = {
	version: '1',
	backend: '',
	be8001: beginURL,	// setup
	be8002: beginURL,	// activation
	be8003: beginURL,	// config
	be8012: beginURL		// xentry
}
*/
